import { configureStore } from "@reduxjs/toolkit";
import { propLeadApiSlice } from "../lib/propertyDetailsApi";
import { rtkQueryErrorLogger } from "./errorMiddleware";
import singlePropPageReducer, {
  singlepropertyPageSlice,
} from "./reducers/singlePropPageReducer";
import { leadUnderwritingSlice } from "../leadUnderwriting/leadUnderwritingSlice";
import advancedSearchReducer from "../multiPropertyView/redux-slices/advancedSearchSlice";
import { multiPropViewApiSlice } from "../multiPropertyView/api-slices/multiPropertyViewApiSlice";
import viewsReducer from "../multiPropertyView/redux-slices/viewsSlice";
import leadsReducer from "../multiPropertyView/redux-slices/leadsSlice";
import searchLeadsReducer from "../multiPropertyView/redux-slices/searchLeadsSlice";
import fieldConfigReducer from "../multiPropertyView/redux-slices/fieldsConfigSlice";

export const store = configureStore({
  reducer: {
    [singlepropertyPageSlice.reducerPath]: singlePropPageReducer,
    advancedSearch: advancedSearchReducer,
    view: viewsReducer,
    leads: leadsReducer,
    search_leads: searchLeadsReducer,
    fieldsConfig: fieldConfigReducer,
    [leadUnderwritingSlice.reducerPath]: leadUnderwritingSlice.reducer,
    [propLeadApiSlice.reducerPath]: propLeadApiSlice.reducer,
    [multiPropViewApiSlice.reducerPath]: multiPropViewApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(propLeadApiSlice.middleware)
      .concat(multiPropViewApiSlice.middleware)
      .concat(rtkQueryErrorLogger),

  devTools: process.env.NODE_ENV !== "production",
});
