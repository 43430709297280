import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { socket } from "../socket/socket";
import { EditableSingleLeadTbl } from "./table/EditableSingleLeadTbl";
import { getMultiTblWithSingleRec } from "./table/MultiRecTbl";
import { selectActiveTable } from "./leadUnderwritingSlice";
import { getRelTableKey } from "./table/util";
import { getPropRelRecord } from "./leadUnderwritingApi";
import { TBL_ALIAS } from "../common/utils/constants";

const REL_TABLES = [
  TBL_ALIAS.valuation,
  TBL_ALIAS.hoa,
  TBL_ALIAS.tax,
  TBL_ALIAS.rehab,
];

// TODO: later we move these things somehow to config
export default function RenderTables({ leadWithRelData, ownUser, ctnRef }) {
  const { id, property_id } = leadWithRelData;
  const tableAlias = useSelector(selectActiveTable);
  const fieldsConfig = useSelector((state) => state.fieldsConfig.fieldsConfig);

  const { data: propRelRecordData } = getPropRelRecord.useQuery(
    {
      leadId: id,
      propertyId: property_id,
      tableAlias: tableAlias,
    },
    { skip: tableAlias === "lead" }
  );

  // TODO: Remove this get related records call as it fetches all the records at once
  // for (const tbl of REL_TABLES) {
  //   getPropRelRecord.useQuerySubscription({
  //     leadId: id,
  //     propertyId: property_id,
  //     tableAlias: tbl,
  //   });
  // }

  const MultiTbl = useMemo(
    () => getMultiTblWithSingleRec(tableAlias, fieldsConfig),
    [tableAlias, fieldsConfig]
  );

  const updatePatchRef = useRef({
    [TBL_ALIAS.lead]: { _version: 0 },
    [TBL_ALIAS.valuation]: {},
    [TBL_ALIAS.tax]: {},
    [TBL_ALIAS.hoa]: {},
    [TBL_ALIAS.rehab]: {},
  });
  const snapshotRef = useRef({});

  useEffect(() => {
    function logId() {
      console.log("ws: connected", socket.id);
    }
    socket.connect();
    socket.on("connect", logId);

    return () => {
      socket.off("connect", logId);
      socket.disconnect();
    };
  }, []);

  switch (tableAlias) {
    case TBL_ALIAS.valuation:
    case TBL_ALIAS.hoa:
    case TBL_ALIAS.tax:
    case TBL_ALIAS.rehab:
      return (
        <MultiTbl
          {...{
            id,
            property_id,
            updatePatchRef,
            ctnRef,
            ownUser,
          }}
        />
      );

    case TBL_ALIAS.lead:
    default:
      return (
        <EditableSingleLeadTbl
          {...{ leadWithRelData, updatePatchRef, snapshotRef, ownUser }}
        />
      );
  }
}
