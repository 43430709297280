import { Typography } from "@mui/material";
import React from "react";

const MainTitle = ({ title, sx}) => {
  return (
    <Typography
      variant="h1"
      gutterBottom
      sx={{ ...sx}}>
      {title}
    </Typography>
  );
};

export default MainTitle;
