import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

const SnglAndMltiSelectComponent = ({
  value,
  options,
  sx,
  selectProps,
  multiple = false,
  readonly = false,
  displayEmpty = true,
}) => {
  // const isInvalidValue = multiple ? false : !options.includes(value);

  const {
    label = null,
    labelId = null,
    variant = "outlined",
    size = "small",
  } = selectProps;

  return (
    <FormControl fullWidth variant={variant}>
      {label && (
        <InputLabel id={labelId} size={size}>
          {label}
        </InputLabel>
      )}
      <Select
        multiple={multiple}
        value={value}
        displayEmpty={displayEmpty}
        size={size}
        labelId={labelId}
        sx={{ ...sx }}
        readOnly={readonly}
        {...selectProps}
        renderValue={(selected) =>
          multiple ? (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.slice(0, 5).map((value) => (
                <Chip key={value} label={value} />
              ))}
              {selected.length > 5 && (
                <Chip label={`+${selected.length - 5} more`} />
              )}
            </Box>
          ) : (
            selected
          )
        }
      >
        {!multiple && displayEmpty && (
          <MenuItem value="">
            <em>--</em>
          </MenuItem>
        )}

        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SnglAndMltiSelectComponent;
