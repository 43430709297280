import { Finance } from "financejs";
import _ from "lodash";
import {
  getIsLeadOverwriteExist,
  getLeadField,
} from "../../component/SinglePropertyPage/common/utils";

const finance = new Finance();

export const INPUT_PARAM_MAP = {
  lead: {
    taxRate: "Est_Effective_Tax_Rate",
    sqft_total: "sqft_total",
  },
};

export const INITIAL_CALC_ASSUMPTIONS = {
  // Investment-Related Assumptions
  rentalAppreciation: 3, // Percentage increase per year
  valueAppreciation: 3, // Percentage increase per year
  purchaseDiscount: 10, // Percentage discount on purchase
  closingCost: 1, // Percentage of the purchase price
  costsaleexit: 6, // Percentage of the selling price

  // Debt-Related Assumptions
  debtPercentage: 70, // Percentage of the property's value
  mortgageInterest: 4, // Percentage rate of the mortgage
  mortgagePeriod: 30, // Years over which the loan is amortized
  mortgagefees: 1, // Points charged as a percentage of the loan amount

  // Tenant-Related Assumptions
  tenantStay: 3, // Average stay of a tenant in years
  turnTime: 1, // Time it takes to prepare for new tenant in months
  delinquencyrate: 1, // Percentage of annual rent expected as delinquent

  // Expenses-Related Assumptions
  annualmaintainence: 500, // Annual expenditure on maintenance
  mgmtfeesrate: 6, // Percentage of collected rents for management
  leasecommision: 1, // Commission as a percentage of one month's rent
  insurance: 0.42, // Insurance cost per hundred dollars of property value
  turnCost: 1, // Cost per square foot for turning over a unit

  // Additional Financial Metrics when we need to save it
  capRate: 5, // Initial cap rate percentage
  grossYield: 12, // Gross yield percentage
  operatingExpenseRatio: 35, // Operating expense ratio as a percentage of operating income
  debtCoverageRatio: 1.2, // Minimum debt coverage ratio required
  cashOnCashReturn: 8, // Cash on cash return percentage
};

export const fallbacks = {
  purchasePrice: 100000,
  rehabCost: 3000,
  rent: 2000,
  arv: 100000 + 3000,
  selectedYear: 10,
  taxRate: 0.01,
  annualHoaFee: 0,
  sqft_total: 0,
};

export function cashFlowCalculator(inputParams, assumptions) {
  // TODO handle defaults to input params
  const inp = inputParams;
  const asm = assumptions;

  /** Initial acquisitions */
  const closingConstant = (asm.closingCost / 100) * inp.purchasePrice;
  const initialInvestment = inp.purchasePrice + inp.rehabCost + closingConstant;
  const unleveredCashInvestment = initialInvestment;
  const insuranceAmt = (initialInvestment / 100) * asm.insurance;

  /** loan levered */
  const leveredLoanAmount =
    (asm.debtPercentage / 100) *
    inp.purchasePrice *
    (1 + asm.mortgagefees / 100);
  const leveredCashInvestment = initialInvestment - leveredLoanAmount;
  let loanBalance = leveredLoanAmount;
  const loanPayment = -finance.PMT(
    asm.mortgageInterest / 100,
    asm.mortgagePeriod,
    leveredLoanAmount
  );

  /** Annual Maintainence */
  const sqftTurnCost = (asm.turnCost * inp.sqft_total) / asm.tenantStay || 0;
  const annualMainWithSqft = asm.annualmaintainence + sqftTurnCost;

  /** cash flow */
  const cashFlowYearly = {
    initialInvestment,

    annualRent: [],
    annualVacancy: [],
    annualDelinquency: [],
    grossIncome: [],
    totalExpense: [],
    netIncome: [],
    propValue: [],
    loanBalance: [],

    leveredCF: [-leveredCashInvestment],
    unleveredCF: [-unleveredCashInvestment],
  };
  // NOTE: for these 0th element is the end of year 1 calculation
  for (let i = 1; i <= inp.selectedYear; i++) {
    /** gross income */
    const rentPerMo =
      inp.rent * Math.pow(1 + asm.rentalAppreciation / 100, i - 1);
    const annualRent = rentPerMo * 12;
    const vacancyAmt = (asm.turnTime / (asm.tenantStay * 12)) * annualRent;
    const delinAmt = (asm.delinquencyrate / 100) * annualRent;
    const grossIncome = annualRent - vacancyAmt - delinAmt;
    cashFlowYearly.annualRent.push(annualRent);
    cashFlowYearly.annualVacancy.push(vacancyAmt);
    cashFlowYearly.annualDelinquency.push(delinAmt);
    cashFlowYearly.grossIncome.push(grossIncome);

    /** total expense */
    const propMgmFee = (asm.mgmtfeesrate / 100) * grossIncome;
    const leaseCommissionAmt =
      (asm.leasecommision / asm.tenantStay) * rentPerMo;

    // TODO: purchase discount logic, replace 0 with the discount % below
    const propValueAmt =
      inp.arv * (1 + 0 / 100) * Math.pow(1 + asm.valueAppreciation / 100, i);
    cashFlowYearly.propValue.push(propValueAmt);

    const purchasePriceAppreciated =
      inp.purchasePrice * Math.pow(1 + asm.valueAppreciation / 100, i);

    const totalExpense =
      propMgmFee +
      annualMainWithSqft +
      leaseCommissionAmt +
      insuranceAmt +
      purchasePriceAppreciated * inp.taxRate +
      inp.annualHoaFee;
    cashFlowYearly.totalExpense.push(totalExpense);

    /** Net Income */
    const netIncome = grossIncome - totalExpense;
    cashFlowYearly.netIncome.push(netIncome);

    /** Loan */
    cashFlowYearly.loanBalance.push(loanBalance);
    const loanInterest = loanBalance * (asm.mortgageInterest / 100);
    loanBalance = loanBalance - loanPayment + loanInterest;
  }

  /** levered unlevered cash flow */
  for (let i = 0; i < inp.selectedYear; i++) {
    let cashFlow = cashFlowYearly.netIncome[i];
    let levCashFlow;

    if (i >= inp.selectedYear - 1) {
      cashFlow += cashFlowYearly.propValue[i] * (1 - asm.costsaleexit / 100);
      levCashFlow = cashFlow - cashFlowYearly.loanBalance[i];
    } else {
      levCashFlow = cashFlow - loanPayment;
    }

    cashFlowYearly.unleveredCF.push(cashFlow);
    cashFlowYearly.leveredCF.push(levCashFlow);
  }

  return cashFlowYearly;
}

/** Input params */

export function getCashFlowCalcInputsFromLatestCalcApi(calcApiData) {
  const { valuation, hoa, rehab } = calcApiData || {};

  const inp = {
    purchasePrice: valuation?.["list price"] || fallbacks.purchasePrice,
    rehabCost: rehab?.["_value"] || fallbacks.rehabCost,
    rent: valuation?.["rent"] || fallbacks.rent,
    selectedYear: fallbacks.selectedYear,
    annualHoaFee: hoa?.["_value"] || fallbacks.annualHoaFee,
  };
  inp["arv"] =
    valuation?.["ARV"] ||
    _.toNumber(inp["purchasePrice"]) + _.toNumber(inp["rehabCost"]);

  for (const f in inp) {
    inp[f] = _.toNumber(inp[f]);
  }
  return inp;
}

export function getCashFlowInputsFromLeadApi(leadApiData) {
  leadApiData = leadApiData || {};
  const { orm__join__zip_identity: zip_identity = [] } = leadApiData;
  const isLeadOverwrite = getIsLeadOverwriteExist(leadApiData);

  return {
    taxRate:
      _.toNumber(
        zip_identity.filter(({ Est_Effective_Tax_Rate: tax_rate }) =>
          _.isFinite(parseFloat(tax_rate))
        )[0]
      ) / 100 || fallbacks.taxRate,
    sqft_total:
      _.toNumber(getLeadField(leadApiData, "sqft_total", isLeadOverwrite)) ||
      fallbacks.sqft_total,
  };
}

export function getCFInputsFromPartialLeadRevision(lead) {
  lead = lead || {};
  const paramMap = INPUT_PARAM_MAP["lead"];
  let params = {};
  for (const p in paramMap) {
    if (lead[p]?.["value"] === undefined) continue;
    params[p] = lead[p]["value"];
  }
  return params;
}

/** calculation */

export function calcIRR(cashFlowYearly) {
  // Ensure at least one year of data and a sign change in cash flows
  if (
    cashFlowYearly.length > 1 &&
    cashFlowYearly.some((value) => value > 0) &&
    cashFlowYearly.some((value) => value < 0)
  ) {
    try {
      const irr = finance.IRR(cashFlowYearly[0], ...cashFlowYearly.slice(1));
      return irr / 100;
    } catch (error) {
      console.error("Error calculating Levered IRR: ", error);
      return null;
    }
  } else {
    console.warn("Not enough data to calculate Levered IRR");
    return null;
  }
}

export function calcCapRate(cashFlowYearly) {
  return cashFlowYearly.netIncome[0] / cashFlowYearly.propValue[0];
}

export function calcGrossYield(rentPerMo, purchasePrice) {
  return (rentPerMo * 12) / purchasePrice;
}
