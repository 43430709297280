let ERR_TITLE = "Oops! Something went wrong";
let ERR_MSG = "Unexpected error has occured";

export function composeFuncs(value, functionsArray, extraArgs = {}) {
  return functionsArray.reduce(
    (result, func) => func(result, extraArgs),
    value
  );
}

export function stringToHttpHref(string) {
  let url;
  try {
    url = new URL(string);
    if (["http:", "https:"].includes(url.protocol) && !!url.hostname) {
      return url.href;
    }
    throw new TypeError("invalid url");
  } catch {
    try {
      url = new URL("https://" + string);
      return url.href;
    } catch {
      return "https://" + string;
    }
  }
}

export function reverseMap(array, callback) {
  const result = [];
  for (let i = array.length - 1; i >= 0; i--) {
    result.push(callback(array[i], i, array));
  }
  return result;
}

export function getErrorMsgTitle(error) {
  let title, message;
  if (error.data || error.message) {
    title = error.data?.error
      ? `${error.data.statusCode || ""} ${error.data.error}`
      : ERR_TITLE;
    message = error.data?.message || error.message || error.error || ERR_MSG;

    // NOTE: class validator can generate multiple errors in which casse error.message
    // is an array of them!
    if (Array.isArray(message)) message = message[0] || ERR_MSG;
    return { message, title };
  }
  return ERR_MSG;
}

/** Ref: https://betterprogramming.pub/serializing-error-in-javascript-27c3a048dc3b */
export function jsonErrReplacer(key, value) {
  if (value?.constructor.name === "Error") {
    return {
      name: value.name,
      message: value.message,
      stack: value.stack,
      cause: value.cause,
    };
  }
  return value;
}
