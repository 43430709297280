export const urlParambuilder = (param, path) => {
  const url = new URL(api_base_url() + path);

  if (param) {
    const paramKeys = Object.keys(param);
    for (let i = 0; i < paramKeys.length; i++) {
      url.searchParams.append(paramKeys[i], param[paramKeys[i]]);
    }
  }
  return url.href;
};

export const api_base_url = () => {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_APIBASEURL;
  } else {
    return process.env.REACT_APP_APIBASEURL_PRODUTION;
  }
};
