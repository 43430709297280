import { Typography } from "@mui/material";
import React from "react";

const CountIcon = ({ extraStyles, children }) => {
  return (
    <Typography
      sx={{
        fontSize: "0.820rem",
        position: "absolute",
        top: 1,
        right: 1,
        height: "15px",
        borderRadius: "50%",
        width: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...extraStyles,
      }}
    >
      {children}
    </Typography>
  );
};

export default CountIcon;
