import React, { useEffect } from "react";
import LeadsDisplay from "./PropertyTable/Property/LeadsDisplay";
import { useDispatch, useSelector } from "react-redux";
import SearchLeadsModal from "../../multiPropertyView/LeadsTable/SearchLeadsModal";
import {
  updatePage,
  updatePageSize,
  updatePaginationModelState,
} from "../../multiPropertyView/redux-slices/viewsSlice";
import { useApplyAdvancedSearchMutation } from "../../multiPropertyView/api-slices/multiPropertyViewApiSlice";
import {
  updateLeads,
  updateLoading,
} from "../../multiPropertyView/redux-slices/leadsSlice";
import { getAdvancedSearchPayload } from "../../multiPropertyView/utils";
import {
  selectAllBuyBoxes,
  selectAllFilters,
  selectAllSorts,
} from "../../multiPropertyView/redux-slices/advancedSearchSlice";
import { useGetFieldsConfigQuery } from "../../multiPropertyView/api-slices/authApiSlice";
export default function MainContent() {
  const dispatch = useDispatch();
  const isSidebar = true;
  const { data } = useGetFieldsConfigQuery();

  const currentViewID = useSelector((state) => state.view.currentViewID);

  const appliedFiltersList = useSelector(selectAllFilters);
  const appliedSorts = useSelector(selectAllSorts);
  const appliedBuyBoxList = useSelector(selectAllBuyBoxes);
  const appliedColumns = useSelector((state) => state.leads.appliedColumns);
  const appliedGroupBy = useSelector((state) => state.view.groupBy);
  const groupBySubViewSelected = useSelector(
    (state) => state.view.groupBySubViewSelected
  );

  const isLoading = useSelector((state) => state.leads.loading);
  const leads = useSelector((state) => state.leads.leads);

  const paginationModelState = useSelector(
    (state) => state.view.paginationModelState
  );

  const creatingNewView = useSelector((state) => state.view.creatingNewView);
  const editingExistingView = useSelector(
    (state) => state.view.editingExistingView
  );

  const [
    applyAdvancedSearch,
    {
      isLoading: isApplyingAdvancedSearch,
      isError: isApplyAdvancedSearchError,
      isSuccess: isApplyAdvancedSearchSuccess,
    },
  ] = useApplyAdvancedSearchMutation();

  useEffect(() => {
    const { page: currentLeadsPage, pageSize: currentLeadsPageSize } =
      paginationModelState;

    const getAdvancedSearchData = async () => {
      try {
        dispatch(updateLoading(true));

        const res = await applyAdvancedSearch({
          data: getAdvancedSearchPayload(
            appliedFiltersList,
            appliedSorts,
            appliedBuyBoxList,
            appliedColumns,
            appliedGroupBy
          ),
          queryParams: {
            page: currentLeadsPage + 1,
            parpage: currentLeadsPageSize,
          },
        }).unwrap();

        dispatch(updateLoading(false));

        dispatch(updateLeads(res.data));
      } catch (error) {
        dispatch(updateLoading(false));
        console.error(error);
      }
    };

    if (currentViewID) {
      if (creatingNewView || editingExistingView || groupBySubViewSelected) {
        getAdvancedSearchData();
      }
    }
  }, [currentViewID, paginationModelState]);

  return (
    <div className={`main-content${!isSidebar ? " sidebar-collapse" : ""}`}>
      {/* <MainFilter /> */}

      <SearchLeadsModal />

      <div className="filter-content">
        {/* <TableList /> */}
        <div className="leads-display">
          <LeadsDisplay
            leads={leads}
            loading={isLoading}
            allowPagination={true}
            updatePaginationModelState={updatePaginationModelState}
          />
        </div>
        {/* <Pagination /> */}
      </div>
    </div>
  );
}
