import { Box, useTheme } from "@mui/material";

export function TableToolbar({ leftButtons = [], rightButtons = [] }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: "1rem",
        display: "flex",
        position: "sticky",
        top: 0,
        zIndex: 1000,
        height: "2.5rem",
        alignItems: "center",
        justifyContent: "space-evenly",
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0px 2px 4px -1px ${theme.palette.grey[300]}`,
      }}
    >
      <ToolBarSection sxProps={{ justifyContent: "flex-start" }}>
        {leftButtons}
      </ToolBarSection>
      <ToolBarSection sxProps={{ justifyContent: "flex-end" }}>
        {rightButtons}
      </ToolBarSection>
    </Box>
  );
}

function ToolBarSection({ sxProps, children, ...boxProps }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        height: "100%",
        gap: "10px",
        ...sxProps,
      }}
      {...boxProps}
    >
      {children}
    </Box>
  );
}
