import { Box, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  updateIsModalOpen,
  updateSearchLeadsResult,
  updateSearchVal,
} from "../redux-slices/searchLeadsSlice";
import CloseIcon from "@mui/icons-material/Close";
import {
  updateSearchLeadsPaginationModelState,
  updateModalLeadsLoading,
  updateIsCanceled,
} from "../redux-slices/leadsSlice";
import { useEffect, useState } from "react";
import { useApplyAdvancedSearchMutation } from "../api-slices/multiPropertyViewApiSlice";
import MinimalIconButtonWithToolTip from "../../component/button/MinimalIconButtonWithTooltip";
import { getColsForTbl } from "../../component/SinglePropertyPage/common/muixDataGrid/table/mutiRecordTable/utils";

import LeadsTable from "./LeadsTable";
import { defaultVisibleColumns } from "../utils";

const SearchLeadsModal = () => {
  const fieldsConfig = useSelector((state) => state.fieldsConfig.fieldsConfig);
  const isSearchLeadsModalOpen = useSelector(
    (state) => state.search_leads.isModalOpen
  );
  const searchLeadsResult = useSelector(
    (state) => state.search_leads.searchLeadsResult
  );
  const searchVal = useSelector((state) => state.search_leads.searchVal);

  const isModalOpen = useSelector((state) => state.search_leads.isModalOpen);

  const isLoading = useSelector((state) => state.leads.modalLeadsLoading);
  const searchLeadsPaginationModelState = useSelector(
    (state) => state.leads.searchLeadsPaginationModelState
  );

  const tblFieldsConf = fieldsConfig?.filter(
    (field) => field.table_alias === "lead"
  );
  const dataCols = getColsForTbl(tblFieldsConf);
  const currentSelectedView = useSelector(
    (state) => state.view.currentViewState
  );

  const currentViewColumns =
    currentSelectedView.filters?.columns || defaultVisibleColumns;

  const initialVisibleCols = dataCols.reduce((acc, col) => {
    acc[col.field] = currentViewColumns.includes(col.field); // Set true if included, otherwise false
    return acc;
  }, {});

  const [visibleColumns, setVisibleColumns] = useState(initialVisibleCols);

  const dispatch = useDispatch();

  const [
    applyAdvancedSearch,
    {
      isLoading: isApplyingAdvancedSearch,
      isSuccess: isApplyAdvancedSearchSuccess,
      isError: isApplyAdvancedSearchError,
    },
  ] = useApplyAdvancedSearchMutation();

  const handleClose = () => {
    dispatch(updateIsModalOpen(false));
    dispatch(updateSearchLeadsResult([]));
    dispatch(
      updateSearchLeadsPaginationModelState({
        page: 0,
        pageSize: 100,
      })
    );
    dispatch(updateSearchVal(""));
  };

  useEffect(() => {
    const {
      page: currentSearchLeadsPage,
      pageSize: currentSearchLeadsPageSize,
    } = searchLeadsPaginationModelState;

    const getAdvancedSearchData = async () => {
      try {
        dispatch(updateModalLeadsLoading(true));

        const res = await applyAdvancedSearch({
          data: {
            filters: [
              {
                filterBucket: "filter",
                filters: [
                  {
                    field: "title",
                    query: searchVal,
                    matchType: "includes",
                    status: true,
                    filterType: "match",
                  },
                ],
              },
            ],
          },
          queryParams: {
            page: currentSearchLeadsPage + 1,
            parpage: currentSearchLeadsPageSize,
          },
        }).unwrap();

        dispatch(updateModalLeadsLoading(false));
        dispatch(updateSearchLeadsResult(res.data));
      } catch (error) {
        dispatch(updateModalLeadsLoading(false));
        console.error(error);
      }
    };

    if (isModalOpen) {
      getAdvancedSearchData();
    }
  }, [searchLeadsPaginationModelState]);

  useEffect(() => {
    setVisibleColumns(initialVisibleCols);
  }, [currentViewColumns]);

  return (
    <Modal
      open={isSearchLeadsModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "90%",
          bgcolor: "white",
          boxShadow: 24,
          py: 2,
          overflow: "auto",
          borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            position: "relative",
            mb: "30px",
          }}
        >
          <MinimalIconButtonWithToolTip
            toolTipLabel={"Close Modal"}
            style={{ position: "absolute", right: 0 }}
          >
            <CloseIcon onClick={handleClose} />
          </MinimalIconButtonWithToolTip>
        </Box>
        <Box sx={{ px: 1 }} className="filter-content">
          <div className="leads-display">
            <LeadsTable
              leads={searchLeadsResult}
              loading={isLoading}
              allowPagination={true}
              columnVisibilityModel={visibleColumns}
              onColumnVisibilityModelChange={(newcols) =>
                setVisibleColumns(newcols)
              }
              updatePaginationModelState={updateSearchLeadsPaginationModelState}
            />
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default SearchLeadsModal;
