import React from "react";
import { CircularProgress, IconButton } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const LoaderIconButton = ({
  loading = false,
  error = false,
  onClick,
  color = "primary",
}) => {
  let icon = null;

  if (loading) {
    icon = <CircularProgress color={color} size={24} />;
  } else if (error) {
    icon = <ErrorOutlineIcon fontSize="small" />;
  }

  return (
    <IconButton
      onClick={onClick}
      disabled={loading}
      color={error ? "error" : color}
      sx={{
        width: 48,
        height: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {icon}
    </IconButton>
  );
};

export default LoaderIconButton;
