import { CACHE_TAG, propLeadApiSlice } from "../lib/propertyDetailsApi";

const BASE_URL_SUFFIX = "/prop-lead";

const leadUnderwritingApi = propLeadApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPropRelRecord: builder.query({
      query: (args) => {
        const {
          leadId,
          propertyId,
          tableAlias,
          limit = 100,
          offset = 0,
        } = args;
        return {
          url: `${BASE_URL_SUFFIX}/${leadId}/get-prop-rel-record`,
          params: {
            propertyId,
            tableAlias,
            limit,
            offset,
          },
        };
      },
      providesTags: (result, error, arg) => {
        return [
          {
            type: CACHE_TAG.simpleMultiRecTbl,
            id: `${arg.tableAlias}/${arg.propertyId}`,
          },
        ];
      },
    }),
    recalcCFCalcMetrics: builder.mutation({
      query: ({ leadId, propertyId, calcInputParams }) => ({
        url: `${BASE_URL_SUFFIX}/${leadId}/recalc-cash-flow-calc-metrics`,
        method: "POST",
        params: { propertyId },
        body: { data: calcInputParams },
      }),

      invalidatesTags: (result, error, arg) => [
        {
          type: CACHE_TAG.leadCFMetrics,
          id: arg.leadId,
        },
      ],
    }),
  }),
});
export default leadUnderwritingApi;
export const { getPropRelRecord, recalcCFCalcMetrics } =
  leadUnderwritingApi.endpoints;
