import { propLeadApiSlice } from "../../lib/propertyDetailsApi";

const BASE_URL_SUFFIX = "/email-tracker";

const emailTrackerAPI = propLeadApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmails: builder.query({
      query: (queryParams) => {
        const { id, ...rest } = queryParams;
        return {
          url: `${BASE_URL_SUFFIX}/${id}`,
          params: rest,
        };
      },

      transformResponse(response) {
        return response.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {});
      },

      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.id}`;
      },

      merge: (currentCacheData, newItems, { arg }) => {
        if (arg.lastTime) {
          if (Object.entries(newItems).length > 0) {
            Object.assign(currentCacheData, newItems);
          }
          return currentCacheData;
        }

        const sortedItems = Object.values(newItems).sort(
          (a, b) => parseInt(b.event_time_on) - parseInt(a.event_time_on)
        );

        const oldestItem = sortedItems[sortedItems.length - 1];

        const isOldestItemPresent = currentCacheData.hasOwnProperty(
          oldestItem?.id
        );

        if (isOldestItemPresent) {
          Object.assign(currentCacheData, newItems);

          if (Object.keys(currentCacheData)?.length > 150 * 2) {
            return newItems;
          }
        } else {
          return newItems;
        }
      },

      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useGetEmailsQuery, useLazyGetEmailsQuery } = emailTrackerAPI;
