import { AppBar, Box, Card, Stack, Divider, useTheme } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MainTitle from "./common/MainTitle";
import NestedNavigation from "./NestedNavigation";
import NormalText from "./common/NormalText";
import MinimalIconButtonWithToolTip from "../button/MinimalIconButtonWithTooltip";
import { CopyAllOutlined } from "@mui/icons-material";
import { getIsLeadOverwriteExist, getLeadField } from "./common/utils";
import { useCallback, useMemo } from "react";
import { getCashFlowCalcMetrics } from "../../lib/propertyDetailsApi";
import { percentFormatter } from "../../common/utils/tableFormatters";
import { DefaultOutLinedBtn } from "../button/commonButtons";
import LoaderButton from "../button/LoaderButton";
import { useSendOfferMutation } from "../../leadUnderwriting/lib/sendOffer";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const CF_CALC_METRICS_KEY_MAP = {
  cap_rate_estimate: "Cap Rate: ",
  gross_yield_estimate: "Gross Yield: ",
  levered_irr_estimate: "Lev. IRR: ",
  unlevered_irr_estimate: "Unlev. IRR: ",
};

function Header({ showPropertyInfo, lead }) {
  const theme = useTheme();
  return (
    <AppBar
      position="sticky"
      color="default"
      elevation={0}
      sx={{
        borderBottom: "1px solid",
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.paper,
        boxShadow: 0,
        top: 0,
        "& .MuiToolbar-root": {
          paddingLeft: 0,
        },
      }}
    >
      <NestedNavigation />

      {showPropertyInfo && <LeadInfoHeader {...{ lead }} />}
    </AppBar>
  );
}

function LeadInfoHeader({ lead }) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: 0,
        px: 2,
        width: "100%",
        borderBottom: "1px solid",
        borderTop: "1px solid",
        borderColor: theme.palette.divider,
        py: "6px",
      }}
    >
      <LeftSection lead={lead} />
      <RightStatsSection lead={lead} />
    </Card>
  );
}

function RightStatsSection({ lead }) {
  const payload = {
    lead_id: lead?.id,
    receiverEmail: "dev@fortuneacquisition.com",
    receiverName: "Dev Fortune Acquisition",
    emailTemplateName: "MailTestTemplate",
    emailTemplateData: {
      title: lead?.title,
      owner: "500 SFR 1 Main",
      offering_agent_name: "John Doe",
      offering_agent_phone: "+1 202 555 1234",
    },
    rightSignatureTemplateId: "11247265-ff29-48e4-a0b2-6b7bfd9efcae",
    merge_field_values: [
      {
        name: "lead_id",
        value: lead?.id,
      },
    ],
  };

  const theme = useTheme();
  const [sendOffer, { isLoading, isError }] = useSendOfferMutation();
  const { data: cfCalcMetrics = {} } = getCashFlowCalcMetrics.useQuery({
    leadId: lead.id,
  });
  const isLeadOverwrite = getIsLeadOverwriteExist(lead);
  const infoStackItems = useMemo(
    () => [
      { label: "Beds:", value: getLeadField(lead, "beds", isLeadOverwrite) },
      { label: "Baths:", value: getLeadField(lead, "baths", isLeadOverwrite) },
      {
        label: "Sqft:",
        value: getLeadField(lead, "sqft_total", isLeadOverwrite),
      },
    ],
    [isLeadOverwrite, lead]
  );

  const calcMetricsStackItems = useMemo(
    () => getCfCalcMetricStackItems(cfCalcMetrics),
    [cfCalcMetrics]
  );

  const handleSendOffer = async () => {
    try {
      const res = await sendOffer(payload).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
      <LoaderButton
        loading={isLoading}
        error={isError}
        variant="outlined"
        sx={{
          mt: 0.5,
          textTransform: "capitalize",
          p: "4px",
          backgroundColor: "white",
        }}
        onClick={handleSendOffer}
      >
        Send offer
      </LoaderButton>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        {[...infoStackItems, ...calcMetricsStackItems].map((item) => (
          <Box
            key={item.label}
            sx={{
              flex: 1,
              gap: "4px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <NormalText
              sx={{
                color: theme.palette.grey[600],
                fontSize: "14px",
                width: "max-content",
                maxWidth: "300px",
                wordBreak: "break-all",
              }}
              text={item.label}
            />
            <NormalText
              sx={{
                color: theme.palette.primary.light,
                fontWeight: "bold",
                fontSize: "14px",
                width: "max-content",
                maxWidth: "300px",
                wordBreak: "break-all",
              }}
              text={item.value}
            />
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

function LeftSection({ lead }) {
  const theme = useTheme();
  const handleCopyAddress = () => {
    navigator.clipboard
      .writeText(lead?.title || "")
      .catch((err) => console.error("failed to write to clipboard", err));
  };

  return (
    <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
      <LocationOnIcon
        fontSize="medium"
        sx={{ color: theme.palette.primary.main, marginBottom: 1 }}
      />
      <MainTitle
        sx={{
          fontSize: "22px",
          color: theme.palette.text.primary,
          fontWeight: "bolder",
        }}
        title={lead?.title}
      />
      <Box>
        <MinimalIconButtonWithToolTip
          toolTipLabel={"Copy Address"}
          sx={{
            cursor: "pointer",
            color: theme.palette.primary.main,
            mb: 1,
            fontSize: "0.875rem",
          }}
          onClick={handleCopyAddress}
        >
          <CopyAllOutlined fontSize="14px" />
        </MinimalIconButtonWithToolTip>

        {lead?.podio__app_item_id && (
          <MinimalIconButtonWithToolTip
            toolTipLabel={"Go to podio"}
            sx={{
              cursor: "pointer",
              color: theme.palette.primary.main,
              mb: 1,
              fontSize: "0.875rem",
            }}
            onClick={() => {
              window.open(
                `https://podio.com/conatusrecom/fortune/apps/fortune-leads/items/${lead?.podio__app_item_id}`
              );
            }}
          >
            <OpenInNewIcon fontSize="14px" />
          </MinimalIconButtonWithToolTip>
        )}
      </Box>
    </Box>
  );
}

function getCfCalcMetricStackItems(metricsFromApi) {
  const items = [];
  for (const metricKey in CF_CALC_METRICS_KEY_MAP) {
    if (metricsFromApi?.[metricKey] === undefined) continue;
    items.push({
      label: CF_CALC_METRICS_KEY_MAP[metricKey],
      value: percentFormatter(metricsFromApi[metricKey]),
    });
  }
  return items;
}

export default Header;
