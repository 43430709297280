import { Box, useTheme } from "@mui/material";
import NormalText from "../../component/SinglePropertyPage/common/NormalText";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectAllFilters } from "../redux-slices/advancedSearchSlice";
import CustomPopOverModal from "../CustomPopOverModal";
import ViewSingleFilter from "./ViewFilters";
import ViewAppliedFilters from "./ViewAppliedFilter";

import { DefaultOutLinedBtn } from "../../component/button/commonButtons";
import CountIcon from "../countIcon";
import AdvancedSrchActionBtn from "../common/AdvanceSrchActionBtn";
import { SearchDialog } from "../SearchDialog";

function FilterLists({ applyAdvancedSearch }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentSelectedFilter, setCurrentSelectedFilter] = useState(null);
  const appliedFiltersList = useSelector(selectAllFilters);
  const [searchTerm, setSearchTerm] = useState("");
  const fieldsConfig = useSelector((state) => state.fieldsConfig.fieldsConfig);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentSelectedFilter(null);
  };

  const open = Boolean(anchorEl);
  const filterList = fieldsConfig
    .filter(
      (field) =>
        field.multi_lead_table && field.multi_lead_table?.is_filterable === true
    )
    .sort((a, b) => a.api_key.localeCompare(b.api_key));

  const searchFilterList = filterList
    ? filterList.filter((filter) =>
        filter.display_name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const removeSearchTerm = () => {
    setSearchTerm("");
  };

  return (
    <Box>
      <DefaultOutLinedBtn
        sx={{ textTransform: "none", position: "relative" }}
        onClick={handleClick}
      >
        Applied filters
        <CountIcon
          extraStyles={{
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
          }}
        >
          {appliedFiltersList.length}
        </CountIcon>
      </DefaultOutLinedBtn>

      <CustomPopOverModal
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            width: "700px",
            height: "500px",
            marginTop: "10px",
            boxShadow: 0,
            overflowY: "hidden",
          },
        }}
      >
        <Box
          sx={{
            maxHeight: "500px",
            maxWidth: "700px",
            height: "calc(100% - 50px)",
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            backgroundColor: theme.palette.grey[200],
          }}
        >
          <Box
            sx={{
              flex: 1.5,
              backgroundColor: theme.palette.grey[100],
              borderRight: `1px solid ${theme.palette.divider}`,
              height: "100%",
              overflowY: "auto",
              py: "2px",
            }}
          >
            <SearchDialog
              searchVal={searchTerm}
              handleSearch={handleSearch}
              handleRemove={removeSearchTerm}
              entity={"filters"}
            />

            <Box
              sx={{
                width: "100%",
                background: theme.palette.primary.light,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: 1,
                mb: "4px",
              }}
            >
              <NormalText
                text={"Applied Filter"}
                onClick={() => setCurrentSelectedFilter(null)}
                sx={{
                  color: theme.palette.primary.contrastText,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  p: 1,
                  my: 0,
                  cursor: "pointer",
                  fontSize: "0.875rem",
                }}
              />
              <Box
                sx={{
                  height: 20,
                  width: 20,
                  fontSize: "0.875rem",
                  backgroundColor: theme.palette.grey[100],
                  color: theme.palette.text.primary,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                }}
              >
                {appliedFiltersList.length}
              </Box>
            </Box>
            {searchFilterList?.map((filter) => {
              const isSelected = appliedFiltersList.some(
                (selectedFilter) => selectedFilter.field === filter.api_key
              );
              const isCurrentlySelected =
                currentSelectedFilter?.api_key === filter.api_key;

              return (
                <NormalText
                  key={filter.api_key}
                  text={filter.display_name || filter.api_key}
                  sx={{
                    borderBottom: isSelected
                      ? `1px solid ${theme.palette.primary.contrastText}`
                      : `1px solid ${theme.palette.divider}`,
                    p: 1,
                    my: 0,
                    cursor: "pointer",
                    fontSize: "0.875rem",
                    color: isCurrentlySelected
                      ? theme.palette.primary.contrastText
                      : isSelected
                        ? theme.palette.text.primary
                        : theme.palette.text,
                    backgroundColor: isCurrentlySelected
                      ? theme.palette.primary.light
                      : isSelected
                        ? theme.palette.grey[400]
                        : "transparent",
                  }}
                  onClick={() => setCurrentSelectedFilter(filter)}
                />
              );
            })}
          </Box>

          <Box
            sx={{
              flex: 3,
              backgroundColor: theme.palette.background,
              height: "100%",
              width: "100%",
            }}
          >
            {!currentSelectedFilter && appliedFiltersList.length > 0 ? (
              <ViewAppliedFilters
                filtersList={filterList}
                appliedFiltersList={appliedFiltersList}
              />
            ) : (
              <ViewSingleFilter filter={currentSelectedFilter} />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "50px",
            backgroundColor: theme.palette.grey[200],
            borderTop: `1px solid ${theme.palette.divider}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AdvancedSrchActionBtn
            sx={{
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.contrastText,
              textTransform: "capitalize",
            }}
            applyAdvancedSearchMutation={applyAdvancedSearch}
            key={"apply-filters"}
          >
            Apply Filters
          </AdvancedSrchActionBtn>
        </Box>
      </CustomPopOverModal>
    </Box>
  );
}

export default FilterLists;
