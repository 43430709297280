import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Skeleton,
  useTheme,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import SectionTitle from "./common/SectionTitle";
import { useGetComparisonDataQuery } from "../../lib/propertyDetailsApi";
import { Link } from "react-router-dom";
import { dtypeToFormatters } from "../../common/utils/tableFormatters";
import { composeFuncs } from "../../common/utils/miscUtils";
import NormalText from "./common/NormalText";
import ShowValue from "./common/ShowValue";
import { isMissingLike } from "./common/utils";

const classes = {
  table: { minWidth: 650 },
  headerCell: {
    fontWeight: "bold",
    fontSize: 14,
    padding: "6px 12px",
  },
  tableCell: {
    padding: "6px 12px",
    position: "relative",
  },
  launchIcon: {
    verticalAlign: "middle",
    position: "absolute",
    right: 20,
    top: 20,
    cursor: "pointer",
  },
};

// NOTE: this table only has lead private fields for now, no shared
// if we add shared later, we should need to chagne the API first
const TableColConfig = [
  {
    displayName: "Date",
    key: "created_on",
    formatType: "date",
  },
  {
    displayName: "Event",
    key: "event",
  },
  {
    displayName: "Review Status",
    key: "review_status",
  },
  {
    displayName: "Price",
    key: "value",
    formatType: "moneyUsd",
  },
  {
    displayName: "Record Source",
    key: "record_source",
  },
  {
    displayName: "Lead Source",
    key: "source",
  },
];

function PropertyHistoryTable({ propLeadID }) {
  const { data, isLoading, isError, error, isSuccess, isFetching } =
    useGetComparisonDataQuery({
      id: propLeadID,
    });
  const propertyHistoryDetails = data?.data;

  const theme = useTheme();

  function generateRows() {
    if (isLoading) {
      return Array.from(new Array(TableColConfig.length)).map((_, index) => (
        <TableRow
          key={index}
          sx={{
            "&:nth-of-type(odd)": { backgroundColor: theme.palette.grey[100] },
          }}
        >
          <TableCell colSpan={TableColConfig.length}>
            <Skeleton variant="rectangular" height={40} />
          </TableCell>
        </TableRow>
      ));
    }
    if (isSuccess && propertyHistoryDetails) {
      return propertyHistoryDetails.map((row, index) => (
        <TableRow
          key={row.id || index}
          sx={{
            "&:nth-of-type(odd)": { backgroundColor: theme.palette.grey[100] },
          }}
        >
          {TableColConfig.map(({ key, formatType }, colIndex) => {
            return (
              <TableCell key={key} sx={classes.tableCell}>
                <NormalText
                  text={
                    isMissingLike(row[key]) ? (
                      <ShowValue value={row[key]} />
                    ) : (
                      composeFuncs(
                        row[key],
                        dtypeToFormatters[formatType] || []
                      )
                    )
                  }
                />
                {colIndex >= TableColConfig.length - 1 && row?.id && (
                  <Link
                    to={`/prop-lead/${row.id}/overview`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LaunchIcon
                      sx={{
                        ...classes.launchIcon,
                        color: theme.palette.grey[500],
                      }}
                    />
                  </Link>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      ));
    }
    let singleLineMsg;
    if (isError) {
      singleLineMsg = "Error occured while getting data for the table";
      console.error(error);
    } else if (
      !(
        isSuccess &&
        propertyHistoryDetails &&
        Array.isArray(propertyHistoryDetails)
      )
    ) {
      singleLineMsg = "No data / Invalid data, can't display the table";
    } else {
      singleLineMsg = "Unexpected error occured, can't display the table";
    }
    return (
      <TableRow
        key={singleLineMsg}
        sx={{
          "&:nth-of-type(odd)": { backgroundColor: theme.palette.grey[100] },
        }}
      >
        <TableCell colSpan={TableColConfig.length} sx={{ textAlign: "center" }}>
          {singleLineMsg}
        </TableCell>
      </TableRow>
    );
  }
  return (
    <Box sx={{ borderTop: `1px solid ${theme.palette.divider}`, p: 4 }}>
      <SectionTitle sectionTitle={"Property History: "} />
      <TableContainer component={Paper} sx={{ boxShadow: 0, marginTop: 2 }}>
        <Table
          sx={{ ...classes.table, ...(isFetching && { opacity: 0.6 }) }}
          aria-label="property history table"
        >
          <TableHead sx={{ backgroundColor: theme.palette.grey[300] }}>
            <TableRow>
              {TableColConfig.map((colConfig) => (
                <TableCell key={colConfig.displayName} sx={classes.headerCell}>
                  <NormalText
                    text={colConfig.displayName}
                    sx={{ fontWeight: "bold", fontSize: "14px" }}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{generateRows()}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PropertyHistoryTable;
