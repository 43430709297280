import { Outlet } from "react-router-dom";
import Header from "../../component/multiPropertyPage/Header";
import ViewsSidebar from "../../multiPropertyView/viewsSidebar/ViewsSidebar";

export default function MultiPropLayout() {
  return (
    <>
      <Header />
      <div className="d-flex fa-body-ctn">
        {/* <Sidebar /> */}
        <ViewsSidebar />
        <Outlet />
      </div>
    </>
  );
}
