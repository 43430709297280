import {
  Modal,
  Box,
  useTheme,
  Typography,
  Stack,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRevisionModalState,
  updateRevisionModalState,
} from "../../store/reducers/singlePropPageReducer";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MinimalIconButtonWithToolTip from "../../component/button/MinimalIconButtonWithTooltip";
import { showActivityValue } from "../../component/UserTable/ActivityGroup";

function ActivityRevisionModal({ leadDetailsProp }) {
  const activityRevisionState = useSelector(selectRevisionModalState);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { title } = leadDetailsProp;
  const { field_name, event_time_on, prev_val, cur_val } =
    activityRevisionState;

  const handleClose = () => {
    dispatch(updateRevisionModalState({}));
  };

  return (
    <Modal
      open={Object.entries(activityRevisionState)?.length > 0}
      onClose={handleClose}
      sx={{ border: "none" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "20%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: theme.palette.background.paper,
          width: "60%",
          height: "30%",
          boxShadow: 0,
          overflow: "hidden",
          py: 1,
          border: "none",
          outline: "none",
          borderRadius: "5px",
        }}
      >
        <Box sx={{ position: "relative", p: "30px", height: "100%" }}>
          <MinimalIconButtonWithToolTip
            toolTipLabel={"Close Modal"}
            style={{ position: "absolute", right: "10px", top: "5px" }}
          >
            <CloseIcon onClick={handleClose} />
          </MinimalIconButtonWithToolTip>

          <Box>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.text.primary,
                fontWeight: "semibold",
                mb: "60px",
              }}
            >
              Showing Changes to: {title}
            </Typography>

            <Box>
              <Stack
                direction="row"
                spacing="20px"
                sx={{ mb: "10px" }}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "0.875rem",
                  }}
                >
                  Changes to: <b>{field_name}</b>
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "0.875rem",
                  }}
                >
                  Updated on: <b>{event_time_on}</b>
                </Typography>
              </Stack>

              <Stack
                sx={{
                  py: "10px",
                  px: "10px",
                  borderTop: `1px solid ${theme.palette.divider}`,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  justifyContent: "space-between",
                }}
                direction={"row"}
                divider={<ArrowForwardIosIcon fontSize="small" />}
              >
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "45%",
                  }}
                >
                  {showActivityValue(field_name, prev_val)}
                </Typography>

                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "45%",
                  }}
                >
                  {showActivityValue(field_name, cur_val)}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default ActivityRevisionModal;
