import { useEffect, useState } from "react";
// import { loginCheck } from "./service/authService";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Loader from "./component/Loader";
import { useLazyLoginCheckQuery } from "./multiPropertyView/api-slices/authApiSlice";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loginCheck] = useLazyLoginCheckQuery();

  useEffect(() => {
    loginCheck().then((res) => {
      if (res?.isSuccess) {
        if (location.pathname.includes("/login") || location.pathname === "/") {
          navigate("/prop-lead/explore");
        }
      } else {
        if (!location.pathname.includes("/login")) {
          navigate("/login");
        }
      }
      setLoading(false);
    });
  }, [location.pathname, navigate, loginCheck]);

  if (loading) {
    return <Loader />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="App">
        <Outlet />
      </div>
    </LocalizationProvider>
  );
}

export default App;
