import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import {
  getTblFieldsConf as getSingleRecTblConf,
  getRowsForTbl,
  singleRecTblCols,
} from "../../component/SinglePropertyPage/common/muixDataGrid/table/singleRecordTable/utils";
import { TableToolbar } from "../../component/SinglePropertyPage/common/muixDataGrid/tableToolbar/Toolbar";
import { MinIconCloseBtn } from "../../component/button/commonButtons";
import { PrimaryLoadingBtn } from "../tableToolbar/EditLeadToolbar";
import SaveIcon from "@mui/icons-material/Save";
import {
  getLeadWithRelatedData,
  useCreatePropRelRecMutation,
} from "../../lib/propertyDetailsApi";
import { useDispatch, useSelector } from "react-redux";
import { selectTableById, tableDataUpdated } from "../leadUnderwritingSlice";
import {
  createRelTblEvents,
  useCreateActivityMutation,
} from "../lib/activityTrackerApi";
import { getPropRelRecord, recalcCFCalcMetrics } from "../leadUnderwritingApi";
import { selectCFCalcInputs } from "../../store/reducers/singlePropPageReducer";
import { SingleRecTblBase } from "./SingleRecTbl";
import { TBL_ALIAS } from "../../common/utils/constants";
import _ from "lodash";
import { getLeadField } from "../../component/SinglePropertyPage/common/utils";
import { useGridApiRef } from "@mui/x-data-grid";

const EMPTY_REC_REF = {};
export function SingleRehabTable({
  id,
  property_id,
  tableAlias,
  handleClose,
  updatePatchRef,
  ownUser,
}) {
  const fieldsConfig = useSelector((state) => state.fieldsConfig.fieldsConfig);

  const dispatch = useDispatch();
  const recordId = useSelector(
    (state) => selectTableById(state, tableAlias)["workingEntityId"]
  );
  const isWorking = useSelector(
    (state) => selectTableById(state, tableAlias)["isWorking"]
  );
  const inputParams = useSelector(selectCFCalcInputs);
  const gridApiRef = useGridApiRef();

  // TODO: later normalize the data in api, so we don't need to .find()
  const { recordData } = getPropRelRecord.useQueryState(
    {
      leadId: id,
      propertyId: property_id,
      tableAlias,
    },
    {
      selectFromResult: ({ data: tableData }) => ({
        recordData: !recordId
          ? EMPTY_REC_REF
          : tableData["data"].find((rec) => rec.id === recordId) ||
            EMPTY_REC_REF,
      }),
    }
  );
  const { data: leadWithReldata = {} } = getLeadWithRelatedData.useQueryState({
    id,
  });

  const initialRows = useMemo(
    () =>
      getRowsForTbl(getSingleRecTblConf(tableAlias, fieldsConfig), recordData),
    [tableAlias, recordData]
  );
  const isEmptyRecord = useMemo(() => !recordData.id, [recordData]);
  const [rows, setRows] = useState(initialRows);
  const [createActivity] = useCreateActivityMutation();

  const sendLeadTableUpdate = useCallback(
    (changes) =>
      tableDataUpdated({
        id: tableAlias,
        changes,
      }),
    [tableAlias]
  );

  const [createRec, { isSuccess, isLoading, isError }] =
    useCreatePropRelRecMutation();
  const [triggerRecalcCF] = recalcCFCalcMetrics.useMutation();

  const handleCreate = async () => {
    const tableData = updatePatchRef.current[tableAlias];
    if (Object.keys(tableData).length < 1) return;
    try {
      const { id: resource_id = null } = await createRec({
        id,
        property_id,
        tableAlias,
        data: tableData,
      }).unwrap();
      if (resource_id) {
        // FIXME: the id here is lead id not the resource id
        // this propagates to when we're trying to fetch the activity records as well, needs to be fixed in both places
        await createActivity({
          id: resource_id,
          table_alias: tableAlias === "hoa" ? "hoa_bill" : tableAlias,
          eventType: "create",
          property_id,
          userId: ownUser.userId,
          revData: createRelTblEvents(tableData),
          queryArgs: {
            id: resource_id,
            propertyId: property_id,
            limit: 10,
            table_alias: tableAlias === "hoa" ? "hoa_bill" : tableAlias,
          },
        }).unwrap();
        await triggerRecalcCF({
          leadId: id,
          propertyId: property_id,
          calcInputParams: inputParams,
        }).unwrap();
      }
      handleClose();
    } catch {}
  };

  const processRowUpdate = (newRow, oldRow) => {
    if (oldRow.value === newRow.value) return oldRow;

    updatePatchRef.current[tableAlias][newRow.api_key] = newRow.value;

    const updateRec = updatePatchRef.current[tableAlias];
    const leadUpdateRec = updatePatchRef.current[TBL_ALIAS.lead];
    if (checkIfUpdateCalculatedRehab(newRow) && gridApiRef.current) {
      const newCalcRows = handleCalculatedRehab(
        gridApiRef,
        updateRec,
        leadUpdateRec,
        leadWithReldata
      );
      for (const row of newCalcRows) {
        updatePatchRef.current[tableAlias][row.api_key] = row.value;
        gridApiRef.current.updateRows([row]);
      }
    }
    dispatch(sendLeadTableUpdate({ isUnsavedChanges: true, isWorking: true }));

    return newRow;
  };

  useLayoutEffect(() => {
    if (!isEmptyRecord) return;
    const patchRec = updatePatchRef.current[tableAlias];
    setRows(
      initialRows.map((r) =>
        patchRec[r.api_key] === undefined
          ? r
          : { ...r, value: patchRec[r.api_key] }
      )
    );
  }, [updatePatchRef, tableAlias, initialRows, isEmptyRecord]);

  return (
    <div style={{ height: "100%" }}>
      <TableToolbar
        rightButtons={[
          isWorking && !isSuccess && (
            <PrimaryLoadingBtn
              key="create button"
              onClick={() => handleCreate()}
              loading={isLoading}
              color={isError ? "error" : undefined}
              startIcon={<SaveIcon />}
            >
              Create
            </PrimaryLoadingBtn>
          ),
          <MinIconCloseBtn key="right_close" onClick={() => handleClose()} />,
        ]}
      />
      <SingleRecTblBase
        rows={rows}
        cols={singleRecTblCols}
        tblProps={{
          isCellEditable: ({ row }) =>
            isEmptyRecord && row.table_config?.is_editable === true,
          processRowUpdate,
          apiRef: gridApiRef,
        }}
      />
    </div>
  );
}

/** calc fields */
const CALC_REHAB_DEFAULTS = {
  // flags
  is_rehab_paint: 0,
  is_rehab_flooring: 0,
  is_rehab_foundation: 0,
  is_rehab_roof: 0,
  is_rehab_hvac: 0,
  is_rehab_kitchen: 0,
  is_rehab_bath: 0,
  is_rehab_appliance: 0,
  is_rehab_window: 0,
  is_rehab_landscaping: 0,
  is_rehab_trashout: 0,

  // rehab costs
  rehab_paint_cost: 2,
  rehab_flooring_cost: 3,
  rehab_foundation_cost: 1000,
  rehab_roof_cost: 5000,
  rehab_hvac_cost: 4500,
  rehab_kitchen_cost: 5000,
  rehab_bath_cost: 2500,
  rehab_appliance_cost: 2400,
  rehab_window_cost: 2000,
  rehab_landscaping_cost: 1000,
  rehab_trashout_cost: 1000,

  // other params
  baseCost: 3000,
};

function checkIfUpdateCalculatedRehab(updateRow) {
  return Object.keys(CALC_REHAB_DEFAULTS).includes(updateRow.api_key);
}

function handleCalculatedRehab(gridApiRef, updateRec, leadUpdateRec, leadRec) {
  const inputs = updateRec || {};
  const leadInputs = {
    sqft_total: _.toNumber(
      leadUpdateRec?.["sqft_total"]?.["value"] ||
        getLeadField(leadRec, "sqft_total")
    ),
    baths: _.toNumber(
      leadUpdateRec?.["baths"]?.["value"] || getLeadField(leadRec, "baths")
    ),
  };
  return calcCalculatedRehab(inputs, leadInputs) || [];
}

function calcCalculatedRehab(inputs, leadInputs) {
  // const i = inputs || {};
  const li = leadInputs || {};
  const d = CALC_REHAB_DEFAULTS;

  const i = Object.keys(d).reduce((acc, api_key) => {
    acc[api_key] = _.toNumber(inputs[api_key]) || d[api_key];
    return acc;
  }, {});

  const rehab =
    i.is_rehab_paint * i.rehab_paint_cost * li.sqft_total +
    i.is_rehab_flooring * i.rehab_flooring_cost * li.sqft_total +
    i.is_rehab_foundation * i.rehab_foundation_cost +
    i.is_rehab_roof * i.rehab_roof_cost +
    i.is_rehab_hvac * i.rehab_hvac_cost +
    i.is_rehab_kitchen * i.rehab_kitchen_cost +
    i.is_rehab_bath * i.rehab_bath_cost * li.baths +
    i.is_rehab_appliance * i.rehab_appliance_cost +
    i.is_rehab_window * i.rehab_window_cost +
    i.is_rehab_landscaping * i.rehab_landscaping_cost +
    i.is_rehab_trashout * i.rehab_trashout_cost +
    d.baseCost;

  return [
    {
      field_id: `${TBL_ALIAS.rehab}.calculated_rehab`,
      value: _.toString(rehab) || null,
      api_key: "calculated_rehab",
    },
  ];
}

/**
 *
 * paint = @Paint == "Yes" ?1:0;
 * flooring = @Flooring == "Yes" ?1:0;
 * foundation = @Foundation == "Yes"?1:0;
 * roof = @Roof == "Yes"?1:0;
 * hvac = @HVAC == "Yes"?1:0;
 * kitchen = @Kitchen == "Yes"?1:0;
 * bath = @Bath == "Yes"?1:0;
 * appliances = @Appliances == "Yes"?1:0;
 * windows = @Windows == "Yes"?1:0;
 * landscaping = @Landscaping == "Yes"?1:0;
 * trashout = @Trashout == "Yes"?1:0;
 *
 * (paint * @SQFT * 2) + ( flooring * @SQFT * 3) + ( foundation * 10000 ) + ( roof * 5000 ) + ( hvac * 4500 ) + ( kitchen * 5000 ) + ( bath * @Baths * 2500 ) + 3000 + ( appliances * 2400 ) + ( windows * 2000 ) + ( landscaping * 1000 ) + ( trashout * 1000 )
 */
