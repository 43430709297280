import NormalText from "../../component/SinglePropertyPage/common/NormalText";

export default function EmptyUIComponent({ message }) {
  return (
    <NormalText
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        overflow: "hidden",
      }}
      text={message}
    />
  );
}
