import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  leads: null || [],
  loading: false,
  modalLeadsLoading: false,
  searchLeadsPaginationModelState: {
    page: 0,
    pageSize: 100,
  },
  appliedColumns: [],
  isCanceled: false,
};

const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    updateLeads: (state, action) => {
      state.leads = action.payload;
    },
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateModalLeadsLoading: (state, action) => {
      state.modalLeadsLoading = action.payload;
    },
    updateSearchLeadsPaginationModelState: (state, action) => {
      state.searchLeadsPaginationModelState = action.payload;
    },
    updateAppliedColumns: (state, action) => {
      state.appliedColumns = action.payload;
    },
    updateIsCanceled: (state, action) => {
      state.isCanceled = action.payload;
    },
  },
});

export const {
  updateLeads,
  updateLoading,
  updateModalLeadsLoading,
  updateSearchLeadsPaginationModelState,
  updateAppliedColumns,
  updateIsCanceled,
} = leadsSlice.actions;

export default leadsSlice.reducer;
