import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import {
  getTblFieldsConf as getSingleRecTblConf,
  getRowsForTbl,
  singleRecTblCols,
} from "../../component/SinglePropertyPage/common/muixDataGrid/table/singleRecordTable/utils";
import { MinimalTbl } from "../../component/SinglePropertyPage/common/muixDataGrid/table";
import { TableToolbar } from "../../component/SinglePropertyPage/common/muixDataGrid/tableToolbar/Toolbar";
import { MinIconCloseBtn } from "../../component/button/commonButtons";
import { PrimaryLoadingBtn } from "../tableToolbar/EditLeadToolbar";
import SaveIcon from "@mui/icons-material/Save";
import { useCreatePropRelRecMutation } from "../../lib/propertyDetailsApi";
import { useDispatch, useSelector } from "react-redux";
import { selectTableById, tableDataUpdated } from "../leadUnderwritingSlice";
import {
  createRelTblEvents,
  useCreateActivityMutation,
} from "../lib/activityTrackerApi";
import { getPropRelRecord, recalcCFCalcMetrics } from "../leadUnderwritingApi";
import { selectCFCalcInputs } from "../../store/reducers/singlePropPageReducer";

const EMPTY_REC_REF = {};
export function SingleRelDataTbl({
  id,
  property_id,
  tableAlias,
  handleClose,
  updatePatchRef,
  ownUser,
}) {
  const dispatch = useDispatch();
  const fieldsConfig = useSelector((state) => state.fieldsConfig.fieldsConfig);

  const recordId = useSelector(
    (state) => selectTableById(state, tableAlias)["workingEntityId"]
  );
  const isWorking = useSelector(
    (state) => selectTableById(state, tableAlias)["isWorking"]
  );
  const inputParams = useSelector(selectCFCalcInputs);

  // TODO: later normalize the data in api, so we don't need to .find()
  const { recordData } = getPropRelRecord.useQueryState(
    {
      leadId: id,
      propertyId: property_id,
      tableAlias,
    },
    {
      selectFromResult: ({ data: tableData }) => ({
        recordData: !recordId
          ? EMPTY_REC_REF
          : tableData["data"].find((rec) => rec.id === recordId) ||
            EMPTY_REC_REF,
      }),
    }
  );

  const initialRows = useMemo(
    () =>
      getRowsForTbl(getSingleRecTblConf(tableAlias, fieldsConfig), recordData),
    [tableAlias, recordData]
  );
  const isEmptyRecord = useMemo(() => !recordData.id, [recordData]);
  const [rows, setRows] = useState(initialRows);
  const [createActivity] = useCreateActivityMutation();

  // const [rows, setRows] = useState(
  //   getRowsForTbl(getSingleRecTblConf(tableAlias, fieldsConfig), recordData)
  // );

  // const [showCreate, setShowCreate] = useState(false);

  const sendLeadTableUpdate = useCallback(
    (changes) =>
      tableDataUpdated({
        id: tableAlias,
        changes,
      }),
    [tableAlias]
  );
  // const [updatedRows, setUpdatedRows] = useState(getUpdatedRows(rows));

  // const hasUpdates = Object.values(updatedRows).some(
  //   (value) => value !== undefined
  // );

  const [createRec, { isSuccess, isLoading, isError }] =
    useCreatePropRelRecMutation();
  const [triggerRecalcCF] = recalcCFCalcMetrics.useMutation();

  const handleCreate = async () => {
    const tableData = updatePatchRef.current[tableAlias];
    if (Object.keys(tableData).length < 1) return;
    try {
      const { id: resource_id = null } = await createRec({
        id,
        property_id,
        tableAlias,
        data: tableData,
      }).unwrap();
      if (resource_id) {
        // FIXME: the id here is lead id not the resource id
        // this propagates to when we're trying to fetch the activity records as well, needs to be fixed in both places
        await createActivity({
          id: resource_id,
          table_alias: tableAlias === "hoa" ? "hoa_bill" : tableAlias,
          eventType: "create",
          property_id,
          userId: ownUser.userId,
          revData: createRelTblEvents(tableData),
          queryArgs: {
            id: resource_id,
            propertyId: property_id,
            limit: 10,
            table_alias: tableAlias === "hoa" ? "hoa_bill" : tableAlias,
          },
        }).unwrap();
        await triggerRecalcCF({
          leadId: id,
          propertyId: property_id,
          calcInputParams: inputParams,
        }).unwrap();
      }
      handleClose();
    } catch {}
  };

  const processRowUpdate = (newRow, oldRow) => {
    if (oldRow.value === newRow.value) return oldRow;
    updatePatchRef.current[tableAlias][newRow.api_key] = newRow.value;
    dispatch(sendLeadTableUpdate({ isUnsavedChanges: true, isWorking: true }));

    return newRow;
  };

  useLayoutEffect(() => {
    if (!isEmptyRecord) return;
    const patchRec = updatePatchRef.current[tableAlias];
    setRows(
      initialRows.map((r) =>
        patchRec[r.api_key] === undefined
          ? r
          : { ...r, value: patchRec[r.api_key] }
      )
    );
  }, [updatePatchRef, tableAlias, initialRows, isEmptyRecord]);
  // useEffect(() => {
  //   if (isWorking) {
  //     dispatch(updateShouldUpdateTableAlias(false));
  //   } else {
  //     dispatch(updateShouldUpdateTableAlias(true));
  //   }
  // }, [isWorking, dispatch]);

  return (
    <div style={{ height: "100%" }}>
      <TableToolbar
        rightButtons={[
          isWorking && !isSuccess && (
            <PrimaryLoadingBtn
              key="create button"
              onClick={() => handleCreate()}
              loading={isLoading}
              color={isError ? "error" : undefined}
              startIcon={<SaveIcon />}
            >
              Create
            </PrimaryLoadingBtn>
          ),
          <MinIconCloseBtn key="right_close" onClick={() => handleClose()} />,
        ]}
      />
      <SingleRecTblBase
        rows={rows}
        cols={singleRecTblCols}
        tblProps={{
          isCellEditable: () => isEmptyRecord,
          processRowUpdate,
        }}
      />
    </div>
  );
}

export function SingleRecTblBase({ rows, cols, tblProps }) {
  const theme = useTheme();

  const singleRecTblSx = useMemo(
    () => ({
      "& .MuiDataGrid-cell": {
        py: 2,
      },
      "& .key-cell": {
        borderRight: "1px solid",
        borderColor: theme.palette.divider,
      },
    }),
    [theme]
  );
  return (
    <MinimalTbl
      rows={rows}
      columns={Array.isArray(cols) ? cols : []}
      getRowId={(row) => row.field_id}
      isCellEditable={(params) => params.row.table_config?.is_editable === true}
      columnHeaderHeight={24}
      sx={singleRecTblSx}
      {...tblProps}
    />
  );
}

function getUpdatedRows(rows) {
  if (!Array.isArray(rows)) return {};
  return rows.reduce((data, row) => {
    if (row?.api_key) data[row.api_key] = row?.value;
    return data;
  }, {});
}
