import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function MinimalTbl({
  rows,
  columns,
  allowPagination = false,
  ...dataGridProps
}) {
  const dispatch = useDispatch();

  const currentViewId = useSelector((state) => state.view.currentViewID);
  const paginationModelState = useSelector(
    (state) => state.view.paginationModelState
  );

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  useEffect(() => {
    if (
      allowPagination &&
      paginationModelState.page === 0 &&
      paginationModelState.pageSize === 100
    ) {
      setPaginationModel({
        page: 0,
        pageSize: 100,
      });
    }
  }, [currentViewId, paginationModelState]);

  let paginationProps = {};

  if (allowPagination) {
    const { updatePaginationModelState } = dataGridProps;

    const handlePaginationModelChange = (newPaginationModel) => {
      setPaginationModel(newPaginationModel);
      dispatch(updatePaginationModelState(newPaginationModel));
    };

    paginationProps = {
      pagination: allowPagination,
      paginationMode: "server",
      paginationModel,
      pageSizeOptions: [],
      rowCount:
        rows.length === 100 ? -1 : paginationModel.page * 100 + rows.length,
      onPaginationModelChange: handlePaginationModelChange,
    };
  }

  return (
    <DataGrid
      // autoHeight
      hideFooter={!allowPagination}
      {...paginationProps}
      rows={rows}
      columns={columns}
      getRowHeight={() => "auto"}
      disableColumnMenu
      disableColumnSorting
      disableColumnFilter
      disableRowSelectionOnClick
      onProcessRowUpdateError={(e) => console.error(e)}
      {...dataGridProps}
    />
  );
}
