import React, { useMemo } from "react";
import { MultiRecTbl } from "../../leadUnderwriting/table/MultiRecTbl";
import { getColsForTbl } from "../../component/SinglePropertyPage/common/muixDataGrid/table/mutiRecordTable/utils";
import { Box, useTheme } from "@mui/material";
import LeadsTableToolbar from "./LeadsTableToolbar";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const LeadsTable = ({ leads, loading, ...rest }) => {
  const fieldsConfig = useSelector((state) => state.fieldsConfig.fieldsConfig);
  const tblFieldsConf = fieldsConfig?.filter(
    (field) => field.table_alias === "lead"
  );
  const dataCols = getColsForTbl(tblFieldsConf);
  const theme = useTheme();

  const tblSx = useMemo(
    () => ({
      "& .MuiDataGrid-cell": {
        pb: 2,
      },
      "& .key-cell": {
        borderRight: "1px solid",
        borderColor: theme.palette.divider,
      },
    }),
    [theme]
  );

  const customizedCols = dataCols.map((col) => {
    if (col.field === "title") {
      return {
        ...col,
        renderCell: (params) => (
          <Link
            to={`/prop-lead/${params.row.id}/overview`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: theme.palette.text.primary,
              textDecoration: "none",
            }}
          >
            {params.value}
          </Link>
        ),
        width: 250,
      };
    }
    return col;
  });

  return (
    <Box
      sx={{
        height: Array.isArray(leads) && leads.length > 0 ? "100%" : "600px",
        width: "100%",
      }}
    >
      <MultiRecTbl
        records={leads}
        cols={customizedCols}
        loading={loading}
        slots={{
          toolbar: LeadsTableToolbar,
        }}
        // slotProps={{
        //   loadingOverlay: {
        //     variant: "linear-progress",
        //     noRowsVariant: "skeleton",
        //   },
        // }}
        {...rest}
        sx={tblSx}
        getRowHeight={() => "50px"}
      />
    </Box>
  );
};

export default LeadsTable;
