import { Box } from "@mui/material";
import {
  removeFilter,
  selectFilterById,
  upsertFilter,
} from "../../redux-slices/advancedSearchSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import SnglAndMltiSelectComponent from "../../../component/input/SingleSelect";
import TextInput from "../../../component/SinglePropertyPage/common/TextInput";
import { stringStartsWith, parseRelativeDate, getRange } from "../utils";

// Previous period -> min: `-1${period}r`, max: `-1${period}e` DONE
// This period -> min: `+0${period}r`, max: `+0${period}e` DONE
// Period to date -> min: `+0${period}r`, max: `+0d` DONE
// Last N period -> min: `-N${period}r`, max: `-1${period}e`, where N > 1, because if N belongs to [0, 1], it will be covered by "Previous period" DONE
// Next N period -> min: `+1${period}r,` max: `+N${period}e`, where N > 0, because there is no existing case where min is `+1${period}r` DONE

const periodMap = {
  y: "Year",
  m: "Month",
  w: "Week",
  d: "Day",
};

const periodMapReverse = {
  Year: "y",
  Month: "m",
  Week: "w",
  Day: "d",
};

const periodOptions = ["Year", "Month", "Week", "Day"];

const RelativeDateTimeFilter = ({ filter, readonly = false }) => {
  const filterState =
    useSelector((state) => selectFilterById(state, filter["api_key"])) || {};

  const dispatch = useDispatch();

  let minParsedOperator,
    minParsedNValue,
    minParsedPeriod,
    minParsedRoundOff,
    maxParsedOperator,
    maxParsedNValue,
    maxParsedPeriod,
    maxParsedRoundOff;

  if (
    Object.keys(filterState).length !== 0 &&
    filterState.filterType === "relative_datetime"
  ) {
    try {
      const minParsed = parseRelativeDate(filterState.min);
      const maxParsed = parseRelativeDate(filterState.max);

      minParsedOperator = minParsed.operator;
      minParsedNValue = minParsed.nValue;
      minParsedPeriod = minParsed.period;
      minParsedRoundOff = minParsed.roundOff;

      maxParsedOperator = maxParsed.operator;
      maxParsedNValue = maxParsed.nValue;
      maxParsedPeriod = maxParsed.period;
      maxParsedRoundOff = maxParsed.roundOff;
    } catch (error) {
      console.error("Error parsing relative date: ", error);
    }
  }

  const [period, setPeriod] = useState(
    Object.keys(filterState).length !== 0 ? periodMap[`${minParsedPeriod}`] : ""
  );
  const [rangeOptions, setRangeOptions] = useState(
    Object.keys(filterState).length !== 0 &&
      filterState.filterType === "relative_datetime" &&
      period !== ""
      ? [
          `Previous ${period}`,
          `This ${period}`,
          `${period} to date`,
          `Last N ${period}s`,
          `Next N ${period}s`,
        ]
      : []
  );
  const [range, setRange] = useState(
    Object.keys(filterState).length !== 0 &&
      filterState.filterType === "relative_datetime"
      ? rangeOptions[getRange(filterState.min, filterState.max)]
      : ""
  );
  const [nValue, setNValue] = useState(
    Object.keys(filterState).length !== 0 &&
      filterState.filterType === "relative_datetime"
      ? minParsedNValue > 1
        ? minParsedNValue
        : maxParsedNValue
      : 0
  );

  const handlePeriodChange = (event) => {
    const value = event.target.value;

    setPeriod(value);

    let newRangeOptions = [
      `Previous ${value}`,
      `This ${value}`,
      `${value} to date`,
      `Last N ${value}s`,
      `Next N ${value}s`,
    ];

    if (event.target.value === "") {
      newRangeOptions = [];
    }

    dispatch(removeFilter({ field: filter["api_key"] }));

    setRangeOptions(newRangeOptions);
    setRange("");
    setNValue(0);
  };

  const handleRangeChange = (event) => {
    const value = event.target.value;

    setRange(value);

    if (
      [`Previous ${period}`, `This ${period}`, `${period} to date`].includes(
        value
      )
    ) {
      let min, max;

      if (value === `Previous ${period}`) {
        min = `-1${periodMapReverse[period]}r`;
        max = `-1${periodMapReverse[period]}e`;
      } else if (value === `This ${period}`) {
        min = `+0${periodMapReverse[period]}r`;
        max = `+0${periodMapReverse[period]}e`;
      } else if (value === `${period} to date`) {
        min = `+0${periodMapReverse[period]}r`;
        max = `+0${periodMapReverse[period]}`;
      }

      dispatch(
        upsertFilter({
          field: filter["api_key"],
          filterType: "relative_datetime",
          min,
          max,
        })
      );
    } else {
      dispatch(removeFilter({ field: filter["api_key"] }));
    }

    setNValue(0);
  };

  const handleNValueChange = (event) => {
    const value = event.target.value;

    setNValue(value);

    if (value > 0) {
      let min, max;

      const startingPart = stringStartsWith(range);

      if (startingPart) {
        if (startingPart === "Last") {
          min = `-${value}${periodMapReverse[period]}r`;
          max = `-1${periodMapReverse[period]}e`;
        } else {
          min = `+1${periodMapReverse[period]}r`;
          max = `+${value}${periodMapReverse[period]}e`;
        }
      } else {
        if (range === `Previous ${period}`) {
          min = `-1${periodMapReverse[period]}r`;
          max = `-1${periodMapReverse[period]}e`;
        } else if (range === `This ${period}`) {
          min = `+0${periodMapReverse[period]}r`;
          max = `+0${periodMapReverse[period]}e`;
        } else if (range === `${period} to date`) {
          min = `+0${periodMapReverse[period]}r`;
          max = `+0${periodMapReverse[period]}`;
        }
      }

      dispatch(
        upsertFilter({
          field: filter["api_key"],
          filterType: "relative_datetime",
          min,
          max,
        })
      );
    } else {
      dispatch(removeFilter({ field: filter["api_key"] }));
    }
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: "10px",
          px: "10px",
          mt: "20px",
        }}
      >
        <Box
          sx={{
            width: "35%",
          }}
        >
          <SnglAndMltiSelectComponent
            key={`relative_period_${filter["api_key"]}`}
            value={period}
            multiple={false}
            options={periodOptions}
            readyonly={readonly}
            selectProps={{
              variant: "outlined",
              onChange: (event) => handlePeriodChange(event),
              label: "Select Period",
              labelId: `select_period_label`,
              disabled: readonly,
            }}
          />
        </Box>
        {period !== "" && (
          <Box
            sx={{
              width: "35%",
            }}
          >
            <SnglAndMltiSelectComponent
              key={`relative_range_${filter["api_key"]}`}
              value={range}
              multiple={false}
              options={rangeOptions}
              readyonly={readonly}
              selectProps={{
                variant: "outlined",
                onChange: (event) => handleRangeChange(event),
                label: "Select Range",
                labelId: `select_range_label`,
                disabled: readonly,
              }}
            />
          </Box>
        )}
        {period !== "" &&
          range !== "" &&
          ![
            `Previous ${period}`,
            `This ${period}`,
            `${period} to date`,
          ].includes(range) && (
            <Box
              sx={{
                width: "20%",
              }}
            >
              <TextInput
                inputValue={nValue}
                onChangeHandler={(event) => handleNValueChange(event)}
                textFieldProps={{
                  placeholder: "Select n value",
                  disabled: readonly,
                  type: "number",
                  min: 0,
                }}
                inputProps={{
                  style: {
                    padding: "8px",
                    width: "100px",
                  },
                }}
              />
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default RelativeDateTimeFilter;
