import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";

function MinimalIconButtonWithToolTip({
  children,
  toolTipLabel,
  style = {},
  ...buttonProps
}) {
  const buttonStyles = {
    backgroundColor: "transparent",
    color: "#49454F",
    borderRadius: 0,
    padding: "1px",
    boxShadow: 0,
    "& svg": {
      fontSize: 18,
    },
  };
  return (
    <IconButton sx={{ ...buttonStyles, ...style }} {...buttonProps}>
      <Tooltip
        title={toolTipLabel}
        enterNextDelay={500}
        enterDelay={500}
        placement="top"
      >
        {children}
      </Tooltip>
    </IconButton>
  );
}

function MinimalIconBtnToolTip({ children, toolTipTitle, ...otherProps }) {
  return (
    <Tooltip title={toolTipTitle} {...otherProps}>
      {children}
    </Tooltip>
  );
}

export default MinimalIconButtonWithToolTip;
