import React from "react";
import { useGetUserAuthByIdQuery } from "../../lib/userTableApi";
import { Box, Typography, useTheme } from "@mui/material";
import timeAgo from "../../service/calculateTimeAgo";
import ActivityGroupSkeleton from "../../leadUnderwriting/Skeleton/ActivityGroupSkeleton";
import { UserAvatarDefault } from "./Avatar";
import { useDispatch, useSelector } from "react-redux";
import { updateRevisionModalState } from "../../store/reducers/singlePropPageReducer";
import { FileTracker } from "../../leadUnderwriting/ActivityTracker/FileTracker";

function ActivityGroup({
  isActivity,
  isComment = false,
  activityGroup,
  tableAlias,
  id,
  limit,
}) {
  const dispatch = useDispatch();
  const {
    event_body,
    created_by_user_id,
    event_time_on: event_time,
  } = activityGroup;
  const { events_list, event_time_on, content } = event_body || {};
  const theme = useTheme();
  const {
    data: userData,
    isLoading,
    isFetching,
  } = useGetUserAuthByIdQuery(created_by_user_id);

  return (
    <>
      {isLoading || isFetching ? (
        <ActivityGroupSkeleton />
      ) : (
        <Box
          sx={{
            maxWidth: "100%",
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            gap: 1,
          }}
        >
          <Box sx={{ flex: "0 0 32px" }}>
            <UserAvatarDefault displayName={userData?.displayName} />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <KeyValueRow
              keyName={userData?.displayName}
              value={`(${timeAgo(event_time)})`}
              theme={theme}
            />
            <Box
              sx={{
                height: "100%",
                overflowY: "hidden",
                pl: 1,
                borderLeft: `3px solid ${theme.palette.divider}`,
              }}
            >
              {isComment && (
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {content}
                </Typography>
              )}

              {isActivity &&
                events_list?.map((edit, idx) => {
                  return (
                    <KeyValueRow
                      onClick={() => dispatch(updateRevisionModalState(edit))}
                      key={idx}
                      theme={theme}
                      keyName={`${edit.field_name}`}
                      value={edit.cur_val}
                    />
                  );
                })}

              {!isActivity && !isComment && (
                <FileTracker
                  file={activityGroup}
                  id={id}
                  tableAlias={tableAlias}
                  limit={limit}
                />
              )}

              {/* events_list?.map((edit, idx) => {
                  return (
                    <KeyValueRow
                      onClick={() => dispatch(updateRevisionModalState(edit))}
                      key={idx}
                      theme={theme}
                      keyName={`${edit.field_name}`}
                      value={edit.cur_val}
                    />
                } */}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export const KeyValueRow = ({ keyName, value, theme, ...rest }) => {
  const fieldsConfig = useSelector((state) => state.fieldsConfig.fieldsConfig);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        gap: 0.5,
        my: 0.4,
        alignItems: "flex-start",
        flexWrap: "wrap",
        width: "100%",
        cursor: "pointer",
      }}
      {...rest}
    >
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.text.primary,
          width: "max-content",
        }}
      >
        {keyName}:
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.text.secondary,
          maxHeight: "500px",
          wordBreak: "break-all",
        }}
      >
        {showActivityValue(keyName, value, fieldsConfig)}
      </Typography>
    </Box>
  );
};

export default ActivityGroup;

export const showActivityValue = (keyName, value, fieldsConfig) => {
  const field = fieldsConfig?.find((field) => field.api_key === keyName);

  if (value === null) {
    return "NULL";
  }

  let displayValue;
  if (field?.dtype === "boolean") {
    displayValue = value === 1 ? "Yes" : value === 0 ? "No" : value;
  } else {
    displayValue = value;
  }

  return displayValue;
};
