import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import TextInput from "../../component/SinglePropertyPage/common/TextInput";
import LoaderButton from "../../component/button/LoaderButton";
import {
  useCreateCommentMutation,
  useUploadFilesMutation,
} from "../lib/activityTrackerApi";

export function ActivityComment({
  selectedTab,
  setSelectedTab,
  tableAlias,
  id,
}) {
  const theme = useTheme();
  const [comment, setComment] = useState("");
  const [createComment, { data, isLoading, isError }] =
    useCreateCommentMutation();
  const [
    uploadFile,
    { data: fileData, isLoading: isFileUploading, isError: isFileError },
  ] = useUploadFilesMutation();

  const handleCreateComment = async () => {
    if (comment.trim().length < 3 || comment.trim().length > 500) {
      toast.error(
        "Add a valid comment with more than 3 and less than 500 charecters"
      );
      return;
    }
    if (selectedTab !== "comment") {
      setSelectedTab("comment");
    }
    const res = await createComment({
      id: id,
      content: comment,
    });
    setComment("");
  };

  const handleFileUpload = async (event) => {
    const files = [...event.target.files];
    const formData = new FormData();

    // Append files to FormData
    files.forEach((file) => {
      formData.append("files", file);
    });

    formData.append("resource_id", id);
    formData.append("table_alias", tableAlias);

    try {
      const res = await uploadFile(formData).unwrap();
      setSelectedTab("files");
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  return (
    <Box
      sx={{
        mt: "10px",
        width: "100%",
        py: "10px",
        position: "relative",
        px: "10px",
        backgroundColor: theme.palette.grey[50],
        boxShadow: 1,
        border: `1px solid ${theme.palette.divider}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
      }}
    >
      <TextInput
        styles={{ width: "90%" }}
        type="text"
        inputValue={comment}
        onChangeHandler={(e) => setComment(e.target.value)}
        inputProps={{
          style: {
            padding: "15px",
            backgroundColor: "white",
          },
        }}
        placeholder="Add comment"
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          px: 2,
          mr: "40px",
        }}
      >
        <LoaderButton
          variant="outlined"
          key={"add file"}
          sx={{
            mt: 1,
            textTransform: "capitalize",
            borderRadius: "20px",
            backgroundColor: "white",
          }}
          loading={isFileUploading}
          error={isFileError}
        >
          <input
            type="file"
            name="uploadfile"
            id="file-upload"
            style={{ display: "none" }}
            onChange={handleFileUpload}
            multiple
          />
          <label
            htmlFor="file-upload"
            style={{
              fontSize: "0.875rem",
              fontWeight: "normal",
              cursor: "pointer",
            }}
          >
            upload file
          </label>
        </LoaderButton>

        <LoaderButton
          variant="outlined"
          key={"add comment"}
          sx={{
            mt: 1,
            textTransform: "capitalize",
            px: 4,
            borderRadius: "20px",
            backgroundColor: "white",
          }}
          onClick={handleCreateComment}
          loading={isLoading}
          error={isError}
        >
          Add
        </LoaderButton>
      </Box>
    </Box>
  );
}
