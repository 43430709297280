import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Notification from "../../assets/svgjs/Notification";
import Logo from "../../assets/image/fa-logo.png";
import { Link, useTheme } from "@mui/material";
import { UserAvatarWithSettings } from "../common/UserAvatar";

export default function Header() {
  const theme = useTheme();

  return (
    <AppBar
      position="static"
      sx={{
        borderBottom: "1px solid",
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.paper,
        boxShadow: 0,
        top: 0,
        "& .MuiToolbar-root": {
          paddingX: 2,
        },
      }}
    >
      <Toolbar
        disableGutters
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Link to="/" sx={{ display: { xs: "none", md: "flex" } }}>
          <img
            src={Logo}
            alt="Logo"
            style={{ height: "40px", width: "300px" }}
          />
        </Link>

        <Box sx={{ flexGrow: 0, display: "flex", gap: "15px" }}>
          <IconButton sx={{ p: 0 }}>
            <Notification />
          </IconButton>
          <UserAvatarWithSettings />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
