import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./AuthRoutes";
import PageRoutes from "./PageRoutes";
import ShowErrorComponent from "../component/SinglePropertyPage/common/ShowErrorComponent";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ShowErrorComponent />,
    children: [MainRoutes, LoginRoutes, PageRoutes],
  },
]);

export default router;
