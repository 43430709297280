import { useState } from "react";
import {
  Box,
  Card,
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import MainTitle from "./common/MainTitle";
import H3 from "./common/H3";
import SectionTitle from "./common/SectionTitle";
import NormalText from "./common/NormalText";
import SliderComponent from "./common/SliderComponent";
import TextButton from "./common/TextButton";
import SolidRoundedButton from "./common/SolidRoundedButton";
import { styled } from "@mui/system";
import {
  cashFlowCalculator,
  calcIRR,
  calcGrossYield,
  calcCapRate,
} from "../../common/utils/cashflowCalculation";
import {
  selectCashFlowAssumptions,
  selectCFCalcInputs,
} from "../../store/reducers/singlePropPageReducer";
import { percentFormatter } from "../../common/utils/tableFormatters";
import { getLatestCalcData } from "../../lib/propertyDetailsApi";
import { CashFlowCalcSkel } from "./Overview/skeleton/CashFlowCalcSkel";
import SanitizeAndScrollText from "../../multiPropertyView/SanitizeAndScrollText";

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderRadius: "20px",
  border: `2px solid ${theme.palette.primary.main}`,
  marginBottom: "10px",
}));

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  textTransform: "capitalize",
  borderRadius: "20px",
  padding: "10px 15px",
  fontSize: "14px",
  color: theme.palette.primary.main,
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const classes = {
  root1: {
    boxShadow: 0,
    width: "46%",
    paddingTop: "10px",
  },
  root2: {
    boxShadow: 0,
    width: "46%",
  },
  statRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 1,
    marginTop: 1,
  },
};

export function CashFlowCalc({ setIsModalOpen, leadWithRelData }) {
  // FIXME: add loading skeleton and fetch here only
  const { isSuccess: isCalcDataSuccess, isLoading: isCalcDataLoading } =
    getLatestCalcData.useQuery({
      leadId: leadWithRelData.id,
      propertyId: leadWithRelData.property_id,
    });

  return (
    <Container sx={{ width: "50%" }}>
      {isCalcDataLoading ? (
        <CashFlowCalcSkel />
      ) : isCalcDataSuccess ? (
        <ListPrice {...{ setIsModalOpen, leadWithRelData: leadWithRelData }} />
      ) : null}
    </Container>
  );
}

function ListPrice({ setIsModalOpen, leadWithRelData }) {
  const theme = useTheme();
  const [showSaveButton, setShowSaveButton] = useState(false);
  const savebtnStyles = {
    borderRadius: "5px",
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "4px 10px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  };
  const calcAssumptions = useSelector(selectCashFlowAssumptions);

  //
  // const initialCashFlowInputs = {
  //   purchasePrice:
  //     leadWithRelData?.calc__?.avg_list_price_valuation ||
  //     fallbacks.purchasePrice,
  //   rehabCost: fallbacks.rehabCost,
  //   rent: leadWithRelData?.calc__?.avg_rent_valuation || fallbacks.rent,
  //   arv:
  //     (leadWithRelData?.calc__?.avg_list_price_valuation ||
  //       fallbacks.purchasePrice) + fallbacks.rehabCost,
  //   selectedYear: fallbacks.selectedYear,
  //   taxRate: leadWithRelData?.calc__?.avg_eff_tax_rate
  //     ? leadWithRelData?.calc__?.avg_eff_tax_rate / 100
  //     : fallbacks.taxRate,
  //   annualHoaFee:
  //     leadWithRelData?.calc__?.avg_hoa_fee || fallbacks.annualHoaFee,
  //   sqft_total: leadWithRelData?.sqft_total || fallbacks.sqft_total,
  // };

  const initialCashFlowInputs = useSelector(selectCFCalcInputs);

  const [purchasePrice, setPurchasePrice] = useState(
    initialCashFlowInputs.purchasePrice
  );
  const [rehabCost, setRehabCost] = useState(initialCashFlowInputs.rehabCost);
  const [rent, setRent] = useState(initialCashFlowInputs.rent);
  const [arv, setArv] = useState(initialCashFlowInputs.arv);
  const [selectedYear, setSelectedYear] = useState(
    initialCashFlowInputs.selectedYear
  );

  const cashFlowYearly = cashFlowCalculator(
    {
      purchasePrice,
      rehabCost,
      rent,
      arv,
      selectedYear,
      taxRate: initialCashFlowInputs.taxRate,
      annualHoaFee: initialCashFlowInputs.annualHoaFee,
      sqft_total: initialCashFlowInputs.sqft_total,
    },
    calcAssumptions
  );
  const { initialInvestment } = cashFlowYearly;

  /** Calculated outputs */
  const leveredIRR = { percentage: calcIRR(cashFlowYearly.leveredCF) };
  const unLeveredIRR = { percentage: calcIRR(cashFlowYearly.unleveredCF) };
  const capRate = {
    percentage: calcCapRate(cashFlowYearly),
  };
  const grossYield = {
    percentage: calcGrossYield(rent, purchasePrice),
  };

  const handleYearChange = (event, newYear) => {
    if (newYear !== null) {
      setSelectedYear(newYear);
    }
  };

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleResetDefault = () => {
    setPurchasePrice(initialCashFlowInputs.purchasePrice);
    setRehabCost(initialCashFlowInputs.rehabCost);
    setRent(initialCashFlowInputs.rent);
    setArv(initialCashFlowInputs.arv);
    setSelectedYear(initialCashFlowInputs.selectedYear);
    setShowSaveButton(false);
  };

  const handleSaveButton = () => {
    setShowSaveButton(false);
  };

  return (
    <>
      <Box sx={{ py: 5, px: 2, boxShadow: 1, borderRadius: "5px", mb: 4 }}>
        <Card
          sx={{
            boxShadow: 0,
            marginBottom: 2,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <H3 title={"List Price"} color={theme.palette.text.primary} />
          <MainTitle
            sx={{ fontSize: "32px" }}
            title={`$${initialCashFlowInputs?.purchasePrice?.toLocaleString()}`}
          />
          <H3
            title={`Initial Investment $${initialInvestment?.toLocaleString()}`}
            color={theme.palette.text.secondary}
          />
        </Card>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
          <Card sx={classes.root1}>
            <SliderComponent
              label="Purchase Price"
              value={purchasePrice}
              setValue={setPurchasePrice}
              min={initialCashFlowInputs.purchasePrice * 0.5}
              max={initialCashFlowInputs.purchasePrice * 2}
              step={1000}
              format={(value) => `$${value.toLocaleString()}`}
              setShowSaveButton={setShowSaveButton}
              showLabelAtEnd={false}
            />
            <SliderComponent
              label="Rehab Cost"
              value={rehabCost}
              setValue={setRehabCost}
              min={0}
              max={initialCashFlowInputs.purchasePrice * 0.5}
              step={500}
              format={(value) => `$${value.toLocaleString()}`}
              setShowSaveButton={setShowSaveButton}
              showLabelAtEnd={false}
            />
            <SliderComponent
              label="Rent"
              value={rent}
              setValue={setRent}
              min={0}
              max={initialCashFlowInputs.purchasePrice * 0.2}
              step={100}
              format={(value) => `$${value.toLocaleString()}`}
              setShowSaveButton={setShowSaveButton}
              showLabelAtEnd={false}
            />
            <SliderComponent
              label="ARV"
              value={arv}
              setValue={setArv}
              min={initialCashFlowInputs.arv * 0.5}
              max={initialCashFlowInputs.arv * 2}
              step={1000}
              format={(value) => `$${value.toLocaleString()}`}
              setShowSaveButton={setShowSaveButton}
              showLabelAtEnd={false}
            />
          </Card>
          <Card sx={classes.root2}>
            <ToggleButtonGroupStyled
              value={selectedYear}
              exclusive
              onChange={handleYearChange}
              aria-label="year selection"
              sx={classes.toggleGroup}
            >
              <ToggleButtonStyled value={1} aria-label="1 years">
                1 Yr
              </ToggleButtonStyled>
              <ToggleButtonStyled value={5} aria-label="5 years">
                5 Yrs
              </ToggleButtonStyled>
              <ToggleButtonStyled value={10} aria-label="10 years">
                10 Yrs
              </ToggleButtonStyled>
            </ToggleButtonGroupStyled>
            <Box sx={classes.statRow}>
              <NormalText text={"Cap Rate"} />
              <H3
                title={`${percentFormatter(capRate.percentage)}%`}
                color={theme.palette.text.primary}
              />
            </Box>
            <Box sx={classes.statRow}>
              <NormalText text={"Gross Yield"} />
              <H3
                title={`${percentFormatter(grossYield.percentage)}%`}
                color={theme.palette.text.primary}
              />
            </Box>
            <Box sx={classes.statRow}>
              <NormalText text={"Levered IRR"} />
              <H3
                title={
                  leveredIRR.percentage !== null
                    ? `${percentFormatter(leveredIRR.percentage)}%`
                    : "N/A"
                }
                color={theme.palette.text.primary}
              />
            </Box>
            <Box sx={classes.statRow}>
              <NormalText text={"Un-Levered IRR"} />
              <H3
                title={
                  unLeveredIRR.percentage !== null
                    ? `${percentFormatter(unLeveredIRR.percentage)}%`
                    : "N/A"
                }
                color={theme.palette.text.primary}
              />
            </Box>
          </Card>
        </Box>
        <Card
          sx={{
            boxShadow: 0,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <TextButton onClick={handleResetDefault}>
              <H3
                color={
                  showSaveButton
                    ? theme.palette.text.secondary
                    : theme.palette.text.disabled
                }
                title={"Reset to Default"}
              />
            </TextButton>
          </Box>
          {showSaveButton && (
            <SolidRoundedButton
              onClick={handleSaveButton}
              styles={savebtnStyles}
            >
              <NormalText
                text={"Save"}
                sx={{ marginTop: 0, marginBottom: 0 }}
              />
            </SolidRoundedButton>
          )}
          <TextButton onClick={handleButtonClick}>
            <H3
              color={theme.palette.text.secondary}
              title={"Edit Assumptions"}
            />
          </TextButton>
        </Card>
      </Box>
      <Box sx={{ mt: 1, pt: 2 }}>
        <SectionTitle sectionTitle={"About this Home:"} />

        <SanitizeAndScrollText text={leadWithRelData?.listing_description} />
      </Box>
    </>
  );
}
