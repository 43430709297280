import { Box, Menu, MenuItem, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { UserAvatarDefault } from "../UserTable/Avatar";
import { useGetOwnUserAuthQuery } from "../../lib/userTableApi";
import { useLogoutMutation } from "../../multiPropertyView/api-slices/authApiSlice";

export function UserAvatarWithSettings() {
  const settings = ["Logout"];
  const [logOut] = useLogoutMutation();
  const { data: userData, isLoading } = useGetOwnUserAuthQuery();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const Logout = async () => {
    try {
      const res = await logOut();
      if (res) {
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return isLoading ? (
    <Box sx={{ width: "30px" }}>
      <Skeleton variant="rounded" width={30} height={30} />
    </Box>
  ) : (
    <>
      <UserAvatarDefault
        avatarProps={{
          onClick: handleOpenUserMenu,
        }}
        displayName={userData?.displayName}
        sx={{
          cusror: "pointer",
        }}
      />

      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={handleCloseUserMenu}>
            {setting === "Logout" ? (
              <Typography textAlign="center" onClick={Logout}>
                {setting}
              </Typography>
            ) : (
              <Typography textAlign="center">{setting}</Typography>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
