import { useEffect, useRef, useState } from "react";
import { useGridApiContext } from "@mui/x-data-grid";
import { InputBase, Link, Stack, Box, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  reverseMap,
  stringToHttpHref,
} from "../../../../common/utils/miscUtils";
import MinimalIconButtonWithToolTip from "../../../button/MinimalIconButtonWithTooltip";

export function EditLinkArrayRenderer(props) {
  const { id, value, row, field, hasFocus, error } = props;
  const { formatting } = row;

  const isSingleLink = formatting?.type === "single_link";
  const linkArr = isSingleLink
    ? value
      ? [value]
      : []
    : !value
      ? []
      : JSON.parse(value);

  const apiRef = useGridApiContext();
  const ref = useRef();
  const numLinks = linkArr.length;

  useEffect(() => {
    if (hasFocus && ref.current && numLinks) {
      ref.current.focus();
    }
  }, [hasFocus, numLinks]);

  const updateCellValue = (newVal) => {
    if (apiRef.current?.setEditCellValue && newVal) {
      apiRef.current.setEditCellValue({
        id,
        field,
        value: isSingleLink
          ? newVal?.length === 0
            ? null
            : newVal[0]
          : JSON.stringify(newVal),
      });
    }
  };

  const handleDelete = (linkIdx) => {
    if (isSingleLink) {
      updateCellValue([]);
    } else {
      updateCellValue([
        ...linkArr.slice(0, linkIdx),
        ...linkArr.slice(linkIdx + 1),
      ]);
    }
  };

  const handleInsert = (newLink) => {
    if (!!newLink) {
      if (isSingleLink) {
        updateCellValue([stringToHttpHref(newLink)]);
      } else {
        updateCellValue([...linkArr, stringToHttpHref(newLink)]);
      }
    }
  };

  return (
    <LinkArrayCtn>
      {isSingleLink && linkArr.length === 0 ? (
        <AddLink inputRef={ref} handleInsert={handleInsert} />
      ) : null}
      {!isSingleLink && <AddLink inputRef={ref} handleInsert={handleInsert} />}
      {reverseMap(linkArr.filter(Boolean), (link, i) => {
        return (
          <LinkWrapperCtn key={`${i}//${link}`}>
            <NumberLabel n={i + 1} />
            <LinkInput value={link || ""} error={!!error} />
            <MinimalIconButtonWithToolTip
              toolTipLabel={"Remove"}
              onClick={() => handleDelete(i)}
            >
              <DeleteIcon />
            </MinimalIconButtonWithToolTip>
          </LinkWrapperCtn>
        );
      })}
    </LinkArrayCtn>
  );
}

export function ReadLinkArrayRenderer(props) {
  const { value, row } = props;
  const { formatting } = row;

  const isSingleLink = formatting?.type === "single_link";
  const linkArr = isSingleLink
    ? value
      ? [value]
      : []
    : !value
      ? []
      : JSON.parse(value || []);

  return (
    <LinkArrayCtn>
      {reverseMap(linkArr, (link, i) => {
        return (
          <LinkWrapperCtn key={`${i}//${link}`}>
            <NumberLabel n={i + 1} />
            <Link
              key={`${i}//${link}`}
              href={link || ""}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                cursor: "pointer",
              }}
              tabIndex={-1}
            >
              <MinimalIconButtonWithToolTip toolTipLabel={"Open"} tabIndex={-1}>
                <OpenInNewIcon />
              </MinimalIconButtonWithToolTip>
            </Link>
            <LinkInput value={link || ""} />
          </LinkWrapperCtn>
        );
      })}
    </LinkArrayCtn>
  );
}

function LinkInput({ value, inputRef, ...props }) {
  return (
    <InputBase
      value={value}
      fullWidth
      slotProps={{
        root: { style: { flexGrow: 1 } },
        input: { style: { textOverflow: "ellipsis" } },
      }}
      readOnly
      inputRef={inputRef}
      {...props}
    />
  );
}

function LinkWrapperCtn({ children }) {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      useFlexGap
      spacing={1}
      sx={{
        width: "100%",
        backgroundColor: theme.palette.grey["50"],
        padding: "4px",
        border: "1px solid",
        borderColor: theme.palette.divider,
        alignItems: "center",
      }}
      divider={<Divider orientation="vertical" flexItem />}
    >
      {children}
    </Stack>
  );
}

function LinkArrayCtn({ children }) {
  return (
    <Stack
      spacing={1}
      useFlexGap
      direction="column-reverse"
      sx={{
        width: "100%",
        padding: "0 16px",
        height: "100%",
        maxHeight: "300px",
        overflowY: "scroll",
        marginY: "auto",
        justifyContent: "space-evenly",
      }}
    >
      {children}
    </Stack>
  );
}

function NumberLabel({ n }) {
  return (
    <Box
      sx={{
        width: "1rem",
        fontFamily: "monospace",
        padding: "0 2px",
        marginRight: "0.875rem",
      }}
    >
      {n}
    </Box>
  );
}

function AddLink({ inputRef, handleInsert }) {
  const [value, setValue] = useState("");
  return (
    <LinkWrapperCtn>
      <MinimalIconButtonWithToolTip
        toolTipLabel={"Add new +"}
        onClick={() => {
          handleInsert(value);
          setValue("");
        }}
      >
        <AddIcon />
      </MinimalIconButtonWithToolTip>
      <LinkInput
        value={value}
        inputRef={inputRef}
        readOnly={false}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.stopPropagation();
            handleInsert(value);
            setValue("");
          }
        }}
      />
    </LinkWrapperCtn>
  );
}
