import React from "react";
import NormalText from "./NormalText";

const SliderLabel = ({ label, width }) => {
  return (
    <NormalText
      text={label}
      // isLabel={true}
      width={width}
      overflow={"hidden"}
      styles={{
        whiteSpace: "no-wrap",
        textOverflow: "ellipsis",
      }}
    />
  );
};

export default SliderLabel;
