import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Box,
  Switch,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import SolidFlatButton from "../component/SinglePropertyPage/common/SolidFlatButton";
import SolidRoundedButton from "../component/SinglePropertyPage/common/SolidRoundedButton";
import H3 from "../component/SinglePropertyPage/common/H3";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#378D9A",
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  "&:last-child": {
    borderRight: "none",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F5F5F5",
  },
}));

// Function to render cell content conditionally
const renderCellContent = (value, rowValues, cellIndex) => {
  if (value === "N/A" || value === "EMPTY") {
    return (
      <Typography
        variant="body2"
        bgcolor="#8B8B8B"
        sx={{
          paddingRight: "10px",
          paddingLeft: "10px",
          width: "fit-content",
          borderRadius: "2px",
          textTransform: "capitalize",
          textAlign: "center",
          color: "white",
        }}
      >
        {value}
      </Typography>
    );
  }

  const isDifferent = cellIndex !== 1 && value !== rowValues[1];

  return (
    <Typography
      variant="body2"
      sx={{
        color: isDifferent ? "red" : "inherit",
      }}
    >
      {value}
    </Typography>
  );
};

const columns = [
  {
    id: "property_record",
    label: "Property Record",
  },
  {
    id: "MLS",
    label: "08/06/2023, 5:45 PM | MLS | 10302236",
  },
  {
    id: "offerpad",
    label: "08/14/2023, 7:30 AM | Offerpad...",
  },
  { id: "opendoor", label: "03/24/2021, 12:30 PM | Opendoor..." },
];

const rows = [
  {
    name: "Address",
    values: [
      "601 1st St E, Keosauqua, IA 52565, US",
      "601 1st St, Keosauqua, IA 52565, US",
      "601 1st Street, Keosauqua, IA 52565, US",
      "601 1st St., Keosauqua, IA 52565",
    ],
  },
  {
    name: "Street",
    values: ["601 1st Street E", "601 1st St", "601 1st Street", "601 1st St."],
  },
  {
    name: "City",
    values: ["Keosauqua", "Keosauqua", "Keosauqua", "Keosauqua"],
  },
  { name: "State", values: ["Idaho", "Idaho", "Idaho", "Idaho"] },
  { name: "Zip", values: ["52565", "52565", "52565", "52565"] },
  {
    name: "County",
    values: [
      "United States",
      "United States",
      "United States",
      "United States",
    ],
  },
  { name: "Latitude", values: ["33.45", "33.4174", "33.4174", "33.4174"] },
  {
    name: "Longitude",
    values: ["-84.28", "-84.2874", "-84.2874", "-84.2874"],
  },
  {
    name: "Subdivision",
    values: ["Rockhampton", "VILLAGES @ HAMPTON", "EMPTY", "EMPTY"],
  },
  { name: "Near Highway", values: ["N/A", "Yes", "Yes", "Yes"] },
  { name: "Near Train", values: ["N/A", "Yes", "Yes", "Yes"] },
  { name: "Near HTW", values: ["N/A", "Yes", "Yes", "Yes"] },
  {
    name: "Flood",
    values: ["N/A", "Minimal Flood", "1% Annual", "Minimal Flood"],
  },
  { name: "Flood zone rating", values: ["N/A", "X", "A99", "A99"] },
  {
    name: "Flood zone description",
    values: [
      "N/A",
      "AREA OF MINIMAL FLOOD HAZARD",
      "Areas with a 1% annual chance of flooding",
      "Areas with a 1% annual chance of flooding",
    ],
  },
  { name: "Neighborhood rating", values: ["N/A", "B", "B", "B"] },
  { name: "Crime score", values: ["N/A", "4.5", "4.5", "4.5"] },
  {
    name: "Nearby school ratings",
    values: ["N/A", "5,4,4", "6,3,5", "5,4,5"],
  },
];

const LeadsComparisonTable = ({
  setShowLeadComparison,
  setShowPropertyInfo,
}) => {
  const [checked, setChecked] = useState(false);
  const theme = useTheme();

  const isRowConflicting = (values) =>
    values.some((value, index) => index !== 1 && value !== values[1]);

  const filteredRows = checked
    ? rows
    : rows.filter((row) => isRowConflicting(row.values));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <IconButton
            onClick={() => {
              setShowLeadComparison(false);
              setShowPropertyInfo(true);
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ color: "#666", fontSize: 14 }}>
            Show Non Conflicting Rows
          </Box>
          <CustomSwitch
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
          <SolidFlatButton onClickHandler={""}>
            <H3 color={"#666"} title={"Add Overwrites"} />
          </SolidFlatButton>
        </Box>
        <SolidRoundedButton
          styles={{
            borderRadius: "5px",
            textTransform: "none",
            backgroundColor: theme.palette.primary.main,
            color: "white",
            padding: "1px 10px",
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          onClickHandler={""}
        >
          <H3 color={"white"} title={"Commit Overwrites"} />
        </SolidRoundedButton>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ borderTop: "1px solid #c2c2c2", marginTop: "10px" }}
      >
        <Table stickyHeader aria-label="property details table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell></StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell key={column.id} align="center">
                  <Typography variant="body1">{column.label}</Typography>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map((row, index) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                {row.values.map((value, cellIndex) => (
                  <StyledTableCell key={row.name} align="left">
                    {renderCellContent(value, row.values, cellIndex)}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LeadsComparisonTable;
