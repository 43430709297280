import { Typography } from "@mui/material";
import React from "react";

const H3 = ({ title, extralabel, color }) => {
  return (
    <Typography
      sx={{ color: color, fontWeight: 400, my: 1 }}
      variant="body1"
      gutterBottom
    >
      {title} {extralabel}
    </Typography>
  );
};

export default H3;
