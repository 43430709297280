import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    isModalOpen: false,
    searchVal: '',
    searchLeadsResult: [],
};


const searchLeadsSlice = createSlice({
    name: 'search_leads',
    initialState,
    reducers: {
        updateIsModalOpen: (state, action) => {
            state.isModalOpen = action.payload;
        },
        updateSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        updateSearchLeadsResult: (state, action) => {
            state.searchLeadsResult = action.payload;
        },
    },
});


export const {
    updateIsModalOpen,
    updateSearchVal,
    updateSearchLeadsResult,
} = searchLeadsSlice.actions;


export default searchLeadsSlice.reducer;