import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { TBL_ALIAS } from "../common/utils/constants";

export const TBL_SCOPE = {
  singleRec: "singleRecord",
  multiRec: "multiRecord",
};
const INITIAL_TABLE_DATA = [
  {
    tableAlias: TBL_ALIAS.lead,
    tableScope: TBL_SCOPE.singleRec,
    isWorking: false,
    workingEntityId: null,
    isUnsavedChanges: false,
    savedVersion: 0,
    localVersion: 0,
  },
  {
    tableAlias: TBL_ALIAS.valuation,
    tableScope: TBL_SCOPE.multiRec,
    isWorking: false,
    workingEntityId: null,
    isUnsavedChanges: false,
    savedVersion: 0,
    localVersion: 0,
  },
  {
    tableAlias: TBL_ALIAS.hoa,
    tableScope: TBL_SCOPE.multiRec,
    isWorking: false,
    workingEntityId: null,
    isUnsavedChanges: false,
    savedVersion: 0,
    localVersion: 0,
  },
  {
    tableAlias: TBL_ALIAS.tax,
    tableScope: TBL_SCOPE.multiRec,
    isWorking: false,
    workingEntityId: null,
    isUnsavedChanges: false,
    savedVersion: 0,
    localVersion: 0,
  },
  {
    tableAlias: TBL_ALIAS.rehab,
    tableScope: TBL_SCOPE.multiRec,
    isWorking: false,
    workingEntityId: null,
    isUnsavedChanges: false,
    savedVersion: 0,
    localVersion: 0,
  },
];

const leadWithRelTblAdapter = createEntityAdapter({
  selectId: (tableData) => tableData.tableAlias,
});
const initialTableState = leadWithRelTblAdapter.getInitialState(
  { activeTableAlias: TBL_ALIAS.lead },
  INITIAL_TABLE_DATA
);

export const leadUnderwritingSlice = createSlice({
  name: "leadUnderwritingEntity",
  initialState: initialTableState,
  reducers: {
    tableDataUpdated: (state, action) => {
      leadWithRelTblAdapter.updateOne(state, action.payload);
    },
    tableDataResetToInit(state, action) {
      const { id: tableAlias, changes = {} } = action.payload;

      // TODO: for reducer check the state and react
      // FIXME !!
      if (state.activeTableAlias === tableAlias || true) {
        leadWithRelTblAdapter.updateOne(state, {
          id: tableAlias,
          changes: { ...initialTableState.entities[tableAlias], ...changes },
        });
      }
    },
    leadUwFullReset(state, action) {
      return initialTableState;
    },

    activeTableAliasUpdated: (state, action) => {
      state.activeTableAlias = action.payload;
    },
  },
});

export default leadUnderwritingSlice.reducer;

export const {
  tableDataUpdated,
  tableDataResetToInit,
  leadUwFullReset,
  activeTableAliasUpdated,
} = leadUnderwritingSlice.actions;

export const { selectById: selectTableById } =
  leadWithRelTblAdapter.getSelectors(
    (state) => state[leadUnderwritingSlice.reducerPath]
  );
export const selectActiveTable = (state) =>
  state[leadUnderwritingSlice.reducerPath].activeTableAlias;
