import React from "react";
import Logo from "../assets/image/fa-logo.png";

export default function Loader() {
  return (
    <div className="fa-scd-loader">
      <div className="loader-img">
        <img src={Logo} alt="Logo" className="w-100" />
        <div className="loader-inner"></div>
      </div>
    </div>
  );
}
