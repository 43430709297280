import { Box, useTheme } from "@mui/material";
import NormalText from "../../component/SinglePropertyPage/common/NormalText";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSort } from "../redux-slices/advancedSearchSlice";
import { selectAllSorts } from "../redux-slices/advancedSearchSlice";
import SelectedSortList from "./SelectedSortList";
import CustomPopOverModal from "../CustomPopOverModal";

import { DefaultOutLinedBtn } from "../../component/button/commonButtons";
import CountIcon from "../countIcon";
import AdvancedSrchActionBtn from "../common/AdvanceSrchActionBtn";
import { SearchDialog } from "../SearchDialog";

function SortLists({ applyAdvancedSearch }) {
  const fieldsConfig = useSelector((state) => state.fieldsConfig.fieldsConfig);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const appliedSortsList = useSelector(selectAllSorts);
  const [searchTerm, setSearchTerm] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectSort = (sort) => {
    dispatch(addSort({ field: sort["api_key"], order: 1 }));
  };

  const open = Boolean(anchorEl);
  const sortList = fieldsConfig
    .filter(
      (field) =>
        field.multi_lead_table && field.multi_lead_table?.is_sortable === true
    )
    .sort((a, b) => a.api_key.localeCompare(b.api_key));

  const searchedSortList = sortList
    ? sortList.filter((sort) =>
        sort.display_name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const removeSearchTerm = () => {
    setSearchTerm("");
  };

  return (
    <div>
      <DefaultOutLinedBtn
        variant="outlined"
        sx={{ textTransform: "none", position: "relative" }}
        onClick={handleClick}
      >
        Applied sort{" "}
        <CountIcon
          extraStyles={{
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
          }}
        >
          {appliedSortsList.length}
        </CountIcon>
      </DefaultOutLinedBtn>

      <CustomPopOverModal
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            width: "550px",
            height: "500px",
            marginTop: "10px",
            boxShadow: 0,
            overflowY: "hidden",
          },
        }}
      >
        <Box
          sx={{
            maxHeight: "400px",
            maxWidth: "550px",
            height: "calc(100% - 50px)",
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            backgroundColor: theme.palette.grey[200],
          }}
        >
          <Box
            sx={{
              flex: 2,
              backgroundColor: theme.palette.grey[100],
              borderRight: `1px solid ${theme.palette.divider}`,
              height: "100%",
              overflowY: "auto",
              py: "2px",
            }}
          >
            <SearchDialog
              searchVal={searchTerm}
              handleSearch={handleSearch}
              handleRemove={removeSearchTerm}
              entity={"sorts"}
            />

            <Box
              sx={{
                width: "100%",
                background: theme.palette.primary.light,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: 1,
              }}
            >
              <NormalText
                text={"Applied Sort"}
                sx={{
                  color: theme.palette.primary.contrastText,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  p: 1,
                  my: 0,
                  cursor: "pointer",
                  fontSize: "0.875rem",
                }}
              />
              <Box
                sx={{
                  height: 20,
                  width: 20,
                  fontSize: "0.875rem",
                  backgroundColor: theme.palette.grey[100],
                  color: theme.palette.text.primary,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "50%",
                  justifyContent: "center",
                }}
              >
                {appliedSortsList.length}
              </Box>
            </Box>

            {searchedSortList?.map((sortField) => {
              const isSelected = appliedSortsList.some(
                (selectedSort) => selectedSort.field === sortField.api_key
              );
              return (
                <NormalText
                  key={sortField.api_key}
                  text={sortField.display_name || sortField.api_key}
                  sx={{
                    borderBottom: isSelected
                      ? `1px solid ${theme.palette.primary.contrastText}`
                      : `1px solid ${theme.palette.divider}`,
                    p: 1,
                    my: 0,
                    cursor: "pointer",
                    fontSize: "0.875rem",
                    color: isSelected
                      ? theme.palette.text.primary
                      : theme.palette.text,
                    backgroundColor: isSelected
                      ? theme.palette.grey[400]
                      : "transparent",
                  }}
                  onClick={() => handleSelectSort(sortField)}
                />
              );
            })}
          </Box>

          <Box
            sx={{
              flex: 3,
              width: "100%",
              backgroundColor: theme.palette.background,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <SelectedSortList sortsList={sortList} />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "50px",
            backgroundColor: theme.palette.grey[200],
            borderTop: `1px solid ${theme.palette.divider}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AdvancedSrchActionBtn
            sx={{
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.contrastText,
              textTransform: "capitalize",
            }}
            applyAdvancedSearchMutation={applyAdvancedSearch}
          >
            Apply Sort
          </AdvancedSrchActionBtn>
        </Box>
      </CustomPopOverModal>
    </div>
  );
}

export default SortLists;
