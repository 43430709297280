import { Box, useTheme } from "@mui/material";
import { DefaultOutLinedBtn } from "../../component/button/commonButtons";
import { useState } from "react";
import AdvancedSrchActionBtn from "../common/AdvanceSrchActionBtn";
import CustomPopOverModal from "../CustomPopOverModal";
import NormalText from "../../component/SinglePropertyPage/common/NormalText";
import { useDispatch, useSelector } from "react-redux";
import { updateGroupBy } from "../redux-slices/viewsSlice";

export default function GroupByLists({ applyAdvancedSearch }) {
  const fieldsConfig = useSelector((state) => state.fieldsConfig.fieldsConfig);

  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const groupBy = useSelector((state) => state.view.groupBy);
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleGroupBy = (currentGroupBy, field) => {
    const isCurrentlyActive = currentGroupBy?.field === field.api_key;
    dispatch(
      updateGroupBy(
        isCurrentlyActive
          ? null
          : {
              field: field.api_key,
              filterType: field.multi_lead_table.filter_type,
            }
      )
    );
  };

  const groupByList = fieldsConfig
    .filter(
      (field) =>
        field.multi_lead_table &&
        field.multi_lead_table?.filter_type === "category"
    )
    .sort((a, b) => a.display_name.localeCompare(b.display_name));

  return (
    <Box>
      <DefaultOutLinedBtn
        sx={{ textTransform: "none", position: "relative" }}
        onClick={handleClick}
      >
        Group by
      </DefaultOutLinedBtn>
      <CustomPopOverModal
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            width: "300px",
            height: "500px",
            marginTop: "10px",
            boxShadow: 0,
            overflowY: "hidden",
          },
        }}
      >
        <Box
          sx={{
            maxHeight: "500px",
            maxWidth: "300px",
            height: "calc(100% - 50px)",
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            backgroundColor: theme.palette.grey[200],
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.grey[100],
              borderRight: `1px solid ${theme.palette.divider}`,
              height: "100%",
              width: "100%",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                width: "100%",
                background: theme.palette.primary.light,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: 1,
              }}
            >
              <NormalText
                text={"Group by"}
                sx={{
                  color: theme.palette.primary.contrastText,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  p: 1,
                  my: 0,
                  cursor: "pointer",
                  fontSize: "0.875rem",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                p: 1,
              }}
            >
              {groupByList?.map((field) => {
                const isSelected = groupBy?.field === field.api_key;

                return (
                  <Box key={field.api_key}>
                    <NormalText
                      text={field.display_name}
                      sx={{
                        border: isSelected
                          ? `1px solid ${theme.palette.primary.contrastText}`
                          : `1px solid ${theme.palette.divider}`,
                        borderRadius: "5px",
                        p: 1,
                        my: 0,
                        width: "100%",
                        cursor: "pointer",
                        fontSize: "0.875rem",
                        color: isSelected
                          ? theme.palette.text.primary
                          : theme.palette.text,
                        backgroundColor: isSelected
                          ? theme.palette.grey[400]
                          : "transparent",
                      }}
                      onClick={() => handleToggleGroupBy(groupBy, field)}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "50px",
            backgroundColor: theme.palette.grey[200],
            borderTop: `1px solid ${theme.palette.divider}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
          }}
        >
          <AdvancedSrchActionBtn
            applyAdvancedSearchMutation={applyAdvancedSearch}
            sx={{
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.contrastText,
              textTransform: "capitalize",
            }}
            key={"select group by"}
          >
            Apply group by
          </AdvancedSrchActionBtn>
        </Box>
      </CustomPopOverModal>
    </Box>
  );
}
