import { tooltipClasses } from "@mui/material/Tooltip";

export const marginOffsetSx = (offset = 0) => {
  offset = `${parseInt(offset)}px`;

  return {
    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
      {
        marginTop: offset,
      },
    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
      {
        marginBottom: offset,
      },
    [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
      {
        marginLeft: offset,
      },
    [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
      {
        marginRight: offset,
      },
  };
};

export const zeroBorderRadius = {
  [`&.${tooltipClasses.popper} .${tooltipClasses.tooltip}`]: {
    borderRadius: 0,
  },
};
