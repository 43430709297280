import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import _ from "lodash";
import { socket } from "../socket/socket";
import { api_base_url } from "../service/constants";
import { jsonErrReplacer } from "../common/utils/miscUtils";

const REQ_TIMEOUT_MS = {
  low: 3000,
  mid: 6000,
  high: 10000,
};

export const CACHE_TAG = {
  lead: "LEAD",
  leadImg: "LEAD_IMAGES",
  leadLatestCFCalcRelData: "LEAD_LATEST_CF_CALC_REL_DATA",
  leadCFMetrics: "LEAD_CF_METRICS",
  simpleMultiRecTbl: "SIMPLE_MULTI_REC_TBL",
  comments: "COMMENTS_TRACKER",
  activity: "ACTIVITY_TRACKER",
  files: "FILE_TRACKER",
};

const BASE_URL_SUFFIX = "/prop-lead";

// expects well-formed no-error response from get-lead-with-related-data
function addCalcFields(response) {
  const calcFuncs = [
    ({
      orm__rel_property: rel_prop,
      id,
      orm__join__zip_identity: zip_identity,
    }) => {
      const {
        orm__rel_valuation: rel_valuations,
        orm__rel_hoa_bill: rel_hoa_bill,
      } = rel_prop;

      // NOTE the values obtained are decimals (strings) therefore
      // the default !!value check won't filter out "0" values
      // will only filter out "missing like" values - null / NaN
      return {
        calc__: {
          avg_list_price_valuation: Array.isArray(rel_valuations)
            ? _.toNumber(
                rel_valuations
                  .filter(
                    ({ value, type, prop_lead_id }) =>
                      type === "list price" && id === prop_lead_id && !!value
                  )
                  .sort(sortRecsOnDateStringCompareFn("created_on"))[0]?.[
                  "value"
                ]
              )
            : undefined,
          avg_rent_valuation: Array.isArray(rel_valuations)
            ? _.toNumber(
                rel_valuations
                  .filter(({ value, type }) => type === "rent" && !!value)
                  .sort(sortRecsOnDateStringCompareFn("created_on"))[0]?.[
                  "value"
                ]
              )
            : undefined,
          avg_eff_tax_rate: Array.isArray(zip_identity)
            ? _.mean(
                zip_identity
                  .filter(({ Est_Effective_Tax_Rate: tax_rate }) =>
                    _.isFinite(parseFloat(tax_rate))
                  )
                  .map(({ Est_Effective_Tax_Rate: tax_rate }) =>
                    _.toNumber(tax_rate)
                  )
              )
            : undefined,
          avg_hoa_fee: Array.isArray(rel_hoa_bill)
            ? getAnnualHoaAmount(
                rel_hoa_bill
                  .filter(({ hoa_fee, payment_frequency: hoa_freq }) =>
                    _.isFinite(parseFloat(hoa_fee))
                  )
                  .sort(sortRecsOnDateStringCompareFn("created_on"))[0]
              )
            : undefined,
        },
      };
    },
  ];

  calcFuncs.forEach((fn) => Object.assign(response, fn(response)));
  return response;
}

function parseRevData(revData) {
  const out = [];
  for (const field in revData) {
    const { value, previousValue, updatedOn } = revData[field];
    out.push({ field: field, value, previousValue, updatedOn });
  }
  return out;
}
export function parseCreateRevPayload(revData) {
  const edits = [];
  for (const field in revData) {
    const { value, previousValue, updatedOn } = revData[field];
    edits.push({
      field_name: field,
      prev_val: previousValue,
      cur_val: value,
      event_time_on: updatedOn,
    });
  }
  return edits;
}

export const propLeadApiSlice = createApi({
  reducerPath: "prop-lead",
  baseQuery: fetchBaseQuery({ baseUrl: api_base_url() }),
  tagTypes: Object.values(CACHE_TAG),
  endpoints: (builder) => ({
    getLeadWithRelatedData: builder.query({
      query: (queryParams) => {
        const {
          id,
          includeFull = [
            "lead_overwrite",
            "valuation",
            "tax",
            "hoa",
            "zip_identity",
          ],
          includeOnlyId = ["prop"],
        } = queryParams;
        const inclFullStr = includeFull.join("|");
        const inclOnlyId = includeOnlyId.join("|");
        return (
          `${BASE_URL_SUFFIX}/get-lead-with-related-data?id=${id}` +
          (inclFullStr ? `&includeFull=${inclFullStr}` : "") +
          (inclOnlyId ? `&includeOnlyId=${inclOnlyId}` : "")
        );
      },
      providesTags: (result, error, arg) => [
        { type: CACHE_TAG.lead, id: arg.id },
      ],
      transformResponse: (response) => {
        return addCalcFields(response);
      },
    }),

    getImagesFromSource: builder.query({
      query: ({ id, data }) => ({
        url: `${BASE_URL_SUFFIX}/${id}/images-from-source`,
        method: "POST",
        body: data,
        headers: {
          "content-type": "application/json",
        },
      }),
    }),

    getImages: builder.query({
      query: ({ id }) => ({
        url: `${BASE_URL_SUFFIX}/${id}/images`,
      }),
      providesTags: (result, error, arg) => [
        { type: CACHE_TAG.leadImg, id: arg.id },
      ],
    }),

    getComparisonData: builder.query({
      query: ({ id }) => ({
        url: `${BASE_URL_SUFFIX}/comparison/${id}`,
      }),
      providesTags: (result, error, arg) => [
        { type: CACHE_TAG.lead, id: arg.id },
      ],
    }),

    updateLeadById: builder.mutation({
      query: ({ id, patchData }) => ({
        url: `${BASE_URL_SUFFIX}/${id}`,
        method: "PATCH",
        body: {
          data: patchData,
        },
      }),
    }),

    updateImagesFromSource: builder.mutation({
      query: ({ id, data }) => ({
        url: `${BASE_URL_SUFFIX}/${id}/images`,
        method: "PATCH",
        body: data,
        headers: {
          "content-type": "application/json",
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "LEAD_IMAGES", id: arg.id },
      ],
    }),

    reviseLead: builder.mutation({
      query: ({ id, property_id, revData }) => {
        return {
          url: `${BASE_URL_SUFFIX}/${id}/apply-lead-revision`,
          method: "POST",
          body: {
            property_id,
            data: parseRevData(revData),
          },
          headers: {
            "content-type": "application/json",
          },
        };
      },
    }),

    // lockLeadUpdate: builder.mutation({
    //   query: ({ property_id }) => ({
    //     url: `${BASE_URL_SUFFIX}/${property_id}/acquire-update-lock`,
    //     method: "POST",
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //   }),
    // }),

    lockLeadUpdateWs: builder.mutation({
      queryFn: async ({ property_id }) => {
        try {
          socket.connect();
          const { data, error } = await socket
            .timeout(REQ_TIMEOUT_MS.high)
            .emitWithAck("lock-lead-update", { id: property_id });

          return error ? { error } : { data };
        } catch (error) {
          return { error: jsonErrReplacer(error) };
        }
      },
    }),

    unlockLeadUpdateWs: builder.mutation({
      queryFn: async ({ id, property_id }) => {
        try {
          socket.connect();
          const { data, error } = await socket
            .timeout(REQ_TIMEOUT_MS.high)
            .emitWithAck("unlock-lead-update", { id: property_id });
          return error ? { error } : { data };
        } catch (error) {
          return { error: jsonErrReplacer(error) };
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: "LEAD", id: arg.id }],
    }),

    createPropRelRec: builder.mutation({
      query: ({ id, property_id, tableAlias, data }) => {
        if (tableAlias === "valuation") data["prop_lead_id"] = id;
        return {
          url: `${BASE_URL_SUFFIX}/${property_id}/create-prop-rel-record`,
          method: "POST",
          body: {
            data: {
              data,
              tableAlias,
              leadId: id,
            },
          },
          headers: {
            "content-type": "application/json",
          },
        };
      },
      invalidatesTags: (result, error, arg) => [
        {
          type: CACHE_TAG.simpleMultiRecTbl,
          id: `${arg.tableAlias}/${arg.property_id}`,
        },
        {
          type: CACHE_TAG.leadLatestCFCalcRelData,
          id: arg.property_id,
        },
      ],
    }),

    getLatestCalcData: builder.query({
      query: ({ leadId, propertyId }) => ({
        url: `${BASE_URL_SUFFIX}/${leadId}/get-latest-calc-data`,
        params: { propertyId },
      }),
      providesTags: (result, error, arg) => [
        { type: CACHE_TAG.leadLatestCFCalcRelData, id: arg.propertyId },
      ],
    }),

    getCashFlowCalcMetrics: builder.query({
      query: ({ leadId }) => ({
        url: `${BASE_URL_SUFFIX}/${leadId}/get-cash-flow-calc-metrics`,
      }),
      providesTags: (result, error, arg) => [
        { type: CACHE_TAG.leadCFMetrics, id: arg.leadId },
      ],
    }),

    refetchGetLeadWithRelData: builder.mutation({
      queryFn: ({ id }) => ({ data: null }),
      invalidatesTags: (result, error, arg) => [{ type: "LEAD", id: arg.id }],
    }),
  }),
});

export const {
  useGetLeadWithRelatedDataQuery,
  useLazyGetLeadWithRelatedDataQuery,
  useGetImagesQuery,
  useLazyGetImagesQuery,
  useGetComparisonDataQuery,
  useUpdateLeadByIdMutation,
  useUpdateImagesFromSourceMutation,
  useLockLeadUpdateMutation,
  useLockLeadUpdateWsMutation,
  useUnlockLeadUpdateWsMutation,
  useReviseLeadMutation,
  useRefetchGetLeadWithRelDataMutation,
  useCreatePropRelRecMutation,
} = propLeadApiSlice;

export const {
  getLatestCalcData,
  getLeadWithRelatedData,
  getCashFlowCalcMetrics,
} = propLeadApiSlice.endpoints;

function sortRecsOnDateStringCompareFn(fieldName = "created_on") {
  return (a, b) =>
    a["created_on"] < b["created_on"]
      ? 1
      : a["created_on"] > b["created_on"]
        ? -1
        : 0;
}

function getAnnualHoaAmount(hoaBillAmount) {
  if (!hoaBillAmount) return;

  const { hoa_fee, payment_frequency } = hoaBillAmount;

  const freq = payment_frequency ? payment_frequency?.toLowerCase() : undefined;
  switch (freq) {
    case "annual": {
      return _.toNumber(hoa_fee);
    }
    case "semi-annually": {
      return _.toNumber(hoa_fee) * 2;
    }
    case "quarterly": {
      return _.toNumber(hoa_fee) * 4;
    }
    case "monthly": {
      return _.toNumber(hoa_fee) * 12;
    }
    default: {
      // fallback to annual hoa assumption
      return _.toNumber(hoa_fee);
    }
  }
}
