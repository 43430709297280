import SnglAndMltiSelectComponent from "../../component/input/SingleSelect";
import { Box, Typography, useTheme } from "@mui/material";
import {
  removeFilter,
  selectFilterById,
  upsertFilter,
} from "../redux-slices/advancedSearchSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import MinimalIconButtonWithToolTip from "../../component/button/MinimalIconButtonWithTooltip";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ToggleSwitch } from "./CategoryFilter";

function BooleanFilter({ filter, readonly = false }) {
  const filterState =
    useSelector((state) => selectFilterById(state, filter["api_key"])) || {};
  const dispatch = useDispatch();

  const theme = useTheme();
  const options = ["Yes", "No"];

  const value =
    filterState.min !== undefined && filterState.min !== null
      ? filterState.min
      : null;

  const handleChange = (event) => {
    const newVal = event.target.value;
    const valToApply = newVal === "Yes" ? 1 : newVal === "No" ? 0 : null;
    dispatch(
      upsertFilter({
        filterType: "boolean",
        min: valToApply,
        max: valToApply,
        field: filter["api_key"],
        includeUnset: filterState?.includeUnset
          ? filterState.includeUnset
          : false,
        negate: filterState?.negate ? filterState.negate : false,
      })
    );
  };

  // TODO: Need to see alternative way of doing this
  useEffect(() => {
    if (
      value === null &&
      filterState.includeUnset === false &&
      filterState.negate === false
    ) {
      dispatch(removeFilter({ field: filter["api_key"] }));
    }
  }, [filter, dispatch, filterState]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: `1px solid ${theme.palette.divider}`,
          borderBottom: `1px solid ${theme.palette.divider}`,
          px: 1,
          py: "10px",
        }}
      >
        <Typography variant="body2">
          {filter.display_name || filter.api_key}
        </Typography>

        {readonly && (
          <MinimalIconButtonWithToolTip
            toolTipLabel={"Remove filter"}
            onClick={() => dispatch(removeFilter({ field: filter["api_key"] }))}
          >
            <DeleteOutlineIcon />
          </MinimalIconButtonWithToolTip>
        )}
      </Box>

      <Box sx={{ mt: "20px", mx: "5px" }}>
        <SnglAndMltiSelectComponent
          key={filter["api_key"]}
          value={value === 1 ? "Yes" : value === 0 ? "No" : ""}
          options={options}
          readonly={readonly}
          multiple={false} // Ensures single-select mode is off
          sx={{ width: "100%" }} // Optional: custom style
          selectProps={{
            variant: "outlined",
            onChange: handleChange,
            disabled: readonly,
          }} // Optional: additional props for Select
        />
      </Box>
      <ToggleSwitch
        dispatch={dispatch}
        filterState={filterState}
        filter={filter}
        readonly={readonly}
        valuesToApply={{ min: value, max: value }}
      />
    </Box>
  );
}

export default BooleanFilter;
