import React from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const StyledSlider = styled(Slider)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  width: "95%",
  "& .MuiSlider-thumb": {
    color: theme.palette.primary.main,
    height: 15,
    width: 15,
  },
  "& .MuiSlider-track": {
    color: theme.palette.primary.main,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.grey[400],
  },
}));

const SliderInput = ({
  value,
  setValue,
  setInputValue,
  min,
  max,
  step,
  setShowSaveButton,
}) => {
  const handleSliderChange = (_, newValue) => {
    setValue(newValue);
    setInputValue(newValue);
    setShowSaveButton(true);
  };

  return (
    <StyledSlider
      value={value}
      onChange={handleSliderChange}
      min={min}
      max={max}
      step={step}
    />
  );
};

export default SliderInput;
