import { Box } from "@mui/material";
import _ from "lodash";
import ShowValue from "../ShowValue";
import {
  GoogleMap,
  StreetViewPanorama,
  useJsApiLoader,
} from "@react-google-maps/api";

const mapContainerStyle = {
  height: "300px",
  width: "500px",
};

export default function GMapsStreetViewRenderer({ longitude, lattitude }) {
  const isInvalidLatLong = !(
    _.isFinite(parseFloat(longitude)) && _.isFinite(parseFloat(lattitude))
  );
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  if (isInvalidLatLong || !isLoaded) {
    return <ShowValue value={null} />;
  }
  const center = {
    lat: parseFloat(lattitude),
    lng: parseFloat(longitude),
  };
  return (
    <Box sx={{ ...mapContainerStyle, bgcolor: "grey.200" }}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={14}
        options={{ streetViewControl: true }}
      >
        <StreetViewPanorama
          position={center}
          visible={true}
          options={{
            pov: { heading: 100, pitch: 0 },
            zoom: 1,
          }}
        />
      </GoogleMap>
    </Box>
  );
}
