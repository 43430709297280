import { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import RenderTables from "./RenderTables";
import ActivityTracker from "./ActivityTracker/ActivityTracker";
import { useDispatch } from "react-redux";
import { leadUwFullReset } from "./leadUnderwritingSlice";
import ActivityRevisionModal from "./ActivityTracker/ActivityRevisionModal";

dayjs.extend(timezone);
dayjs.extend(utc);

const LeadDetails = ({ leadDetails, ownUser }) => {
  const tableCtnRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(leadUwFullReset({}));
  }, [dispatch]);
  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        display: "flex", // FIXME: why is this flex ? only 1 child
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", gap: 1, height: "100%" }}>
        <Box
          sx={{
            flexGrow: 1,
            width: "calc(100% - 400px)",
            height: "100%",
            overflow: "auto",
          }}
          ref={tableCtnRef}
        >
          <RenderTables
            leadWithRelData={leadDetails}
            ownUser={ownUser}
            ctnRef={tableCtnRef}
          />
        </Box>
        <Box sx={{ width: "450px", maxWidth: "450px" }}>
          <ActivityTracker leadDetailsProp={leadDetails} ownUser={ownUser} />
        </Box>
      </Box>
      <ActivityRevisionModal leadDetailsProp={leadDetails} />
    </Box>
  );
};

export default LeadDetails;
