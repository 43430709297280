import { Box, useTheme } from "@mui/material";
import React from "react";
import DOMPurify from "dompurify";

const SanitizeAndScrollText = ({ text: HTMLtext }) => {
  const theme = useTheme();
  return (
    <Box>
      {HTMLtext ? (
        <Box
          sx={{
            maxHeight: "15rem", // Approx 4 lines of text
            overflowY: "auto",
            padding: "4px",
            my: "4px",
            borderRadius: "4px",
            backgroundColor: theme.palette.grey[50],
            fontSize: "0.875rem",
          }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(HTMLtext),
          }}
        />
      ) : (
        "Nothing to show"
      )}
    </Box>
  );
};

export default SanitizeAndScrollText;
