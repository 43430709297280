import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "./store";
import { ToastContainer } from "react-toastify";
import "./assets/style/index.css";
import "react-toastify/dist/ReactToastify.css";
import router from "./routes/router";
import App from "./App";
import theme from "./theme/theme";
import { ThemeProvider } from "@mui/material/styles";
import { DEF_TOAST_CTN_PROPS } from "./common/utils/constants";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ToastContainer {...DEF_TOAST_CTN_PROPS} />
        <RouterProvider router={router}>
          <App />
        </RouterProvider>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
);
