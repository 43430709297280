import React from "react";
import TextInput from "./TextInput";

const EditableTextField = ({
  inputValue,
  setInputValue,
  setValue,
  setIsEditing,
  setShowSaveButton,
}) => {
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setShowSaveButton(true);
  };

  const handleBlur = () => {
    const numericValue = parseFloat(inputValue);
    if (!isNaN(numericValue)) {
      setValue(numericValue);
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const numericValue = parseFloat(inputValue);
      if (!isNaN(numericValue)) {
        setValue(numericValue);
      }
      setIsEditing(false);
    }
  };

  return (
    <TextInput
      inputValue={inputValue}
      onChangeHandler={handleInputChange}
      onBlurHandler={handleBlur}
      keyDownHandler={handleKeyDown}
      styles={{
        width: "100px",
        marginLeft: 2,
      }}
    />
  );
};

export default EditableTextField;
