import { Typography, useTheme } from "@mui/material";
import React from "react";

const SectionTitle = ({ sectionTitle, extraText, labelColor }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="h2"
      sx={{
        color: theme.palette.primary.main,
        fontWeight: "bold",
        fontSize: "24px",
        letterSpacing: "1px",
        display: "flex",
        alignItems: "center",
        gap: 1,
        justifyContent: extraText ? "center" : "start",
      }}
    >
      {sectionTitle}
      <span style={{ color: labelColor, fontSize: "14px", fontWeight: 400 }}>
        {extraText}
      </span>
    </Typography>
  );
};

export default SectionTitle;
