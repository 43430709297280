import { Box, Modal } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import LeadImages from "./LeadImages";
import {
  useGetImagesQuery,
  useUpdateImagesFromSourceMutation,
} from "../../../lib/propertyDetailsApi";
import LeadImagesSkeleton from "./skeleton/LeadImagesSkeleton";
import { useState, useRef } from "react";
import ErrorTriangle from "../../../assets/SinglePropertyPage/errorTriangle.svg";
import MinimalIconButtonWithToolTip from "../../button/MinimalIconButtonWithTooltip";

function LeadImagesContainer({ lead }) {
  const [updateImages, { isLoading: isUpdateLoading, isError: isUpdateError }] =
    useUpdateImagesFromSourceMutation();
  const {
    data: imagesData,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useGetImagesQuery({ id: lead.id });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mainImgIndex, setMainImgIndex] = useState(0);
  const imgListRefSmall = useRef(null);
  const imgListRefBig = useRef(null);
  const firstIndex = useRef(null);

  const isDataValid =
    isSuccess &&
    imagesData &&
    Array.isArray(imagesData.data) &&
    imagesData.data.length > 0;

  if (isError || isUpdateError) {
    console.error(error);
  }

  const handleRefresh = () => {
    if (isDataValid) {
      updateImages({
        id: lead.id,
        data: { seller_id: lead.seller_id, source: lead.source },
      });
    }
  };
  const handleThumbImgClick = (index) => {
    setMainImgIndex(index);
  };

  const closeModal = () => {
    firstIndex.current = null;
    setIsModalOpen(false);
    if (imgListRefSmall?.current) {
      imgListRefSmall.current.scrollToItem(mainImgIndex, "auto");
    }
  };
  const openModal = () => {
    firstIndex.current = mainImgIndex;
    setIsModalOpen(true);
  };
  const toggleModal = () => {
    if (isModalOpen) {
      closeModal();
    } else {
      openModal();
    }
  };

  const CalcLeadImages = ({ ctnStyle, imgListRef, firstIndex, ...listProps }) =>
    isLoading ? (
      <LeadImagesSkeleton />
    ) : isDataValid ? (
      <LeadImages
        {...{
          mainImgIndex,
          ctnStyle,
          handleThumbImgClick,
          firstIndex,
          imgListRef,
          listProps,
          setMainImgIndex,
        }}
        images={imagesData.data}
      />
    ) : isError ? (
      <ErrorFallback />
    ) : (
      <ErrorFallback />
    );

  return (
    <>
      <Box sx={{ height: "600px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            {isDataValid &&
              `Image ${mainImgIndex + 1}/${imagesData?.data?.length}`}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "end",
              gap: "5px",
            }}
          >
            <MinimalIconButtonWithToolTip
              disabled={isUpdateLoading || isFetching}
              onClick={handleRefresh}
              toolTipLabel={"Load Images"}
            >
              <AutorenewIcon color={isError ? "error" : undefined} />
            </MinimalIconButtonWithToolTip>

            <MinimalIconButtonWithToolTip
              toolTipLabel={"Expand Image"}
              onClick={toggleModal}
            >
              <FullscreenIcon />
            </MinimalIconButtonWithToolTip>
          </Box>
        </Box>

        {CalcLeadImages({
          ctnStyle: {},
          imgListRef: imgListRefSmall,
          firstIndex: null,
        })}
      </Box>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        sx={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
      >
        <Box
          sx={{
            position: "relative",
            p: 4,
            borderRadius: 2,
            boxShadow: 24,
            height: "100%",
            overflow: "auto",
          }}
        >
          <MinimalIconButtonWithToolTip
            sx={{ position: "absolute", right: 8, top: 8, color: "white" }}
            onClick={toggleModal}
            toolTipLabel={"Close modal"}
          >
            <CloseIcon />
          </MinimalIconButtonWithToolTip>
          {CalcLeadImages({
            ctnStyle: {},
            imgListRef: imgListRefBig,
            firstIndex,
          })}
        </Box>
      </Modal>
    </>
  );
}

export default LeadImagesContainer;

function ErrorFallback() {
  return (
    <img
      src={ErrorTriangle}
      alt="unexpected error on loading"
      style={{
        display: "block",
        margin: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
        height: "100%",
        width: "100%",
        objectFit: "contain",
      }}
    />
  );
}
