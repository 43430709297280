import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

const AlertDialog = (props) => {
  const { open, handleDialogDiscard, handleDialogSave, title, message } = props;

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleDialogDiscard}>
          Discard Changes
        </Button>
        <Button color="primary" onClick={handleDialogSave}>
          Resume Editing
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
