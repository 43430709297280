import { TextField } from "@mui/material";
import React from "react";

const TextInput = ({
  inputValue,
  onChangeHandler,
  onBlurHandler,
  keyDownHandler,
  styles,
  slotProps,
  textFieldProps,
  ...rest
}) => {
  return (
    <TextField
      value={inputValue}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      // autoFocus
      sx={{ ...styles }}
      onKeyDown={keyDownHandler}
      {...textFieldProps}
      {...rest}
    />
  );
};

export default TextInput;
