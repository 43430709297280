import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MinimalIconButtonWithToolTip from "../../component/button/MinimalIconButtonWithTooltip";
import TextInput from "../../component/SinglePropertyPage/common/TextInput";
import { Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCreatingNewView,
  updateEditingExistingView,
  updateGroupBy,
  updateGroupBySubViewSelected,
  updatePaginationModelState,
  updateSelectedTab,
  updateUnSavedViewState,
} from "../redux-slices/viewsSlice";
import {
  selectAllBuyBoxes,
  selectAllFilters,
  selectAllSorts,
  setBuyBoxes,
  setFilters,
  setSorts,
} from "../redux-slices/advancedSearchSlice";

import {
  useCopyToPrivateMutation,
  useCreateViewMutation,
  useDeleteViewMutation,
  useDuplicateViewMutation,
  useGetSingleViewByIdQuery,
  useGetViewsListQuery,
  useUpdateViewMutation,
  useLazyGroupByAndCountQuery,
} from "../api-slices/multiPropertyViewApiSlice";
import ViewsList from "./ViewsList";
import UnsavedForm from "./UnsavedForm";
import {
  updateAppliedColumns,
  updateIsCanceled,
  updateLeads,
} from "../redux-slices/leadsSlice";

import { defaultVisibleColumns, getAdvancedSearchPayload } from "../utils";

export default function ViewsSidebar() {
  const theme = useTheme();
  const [currentSubGroupby, setCurrentSubView] = useState({});
  const unsavedViewState = useSelector((state) => state.view.unSavedViewState);
  const appliedFiltersList = useSelector(selectAllFilters);
  const appliedSorts = useSelector(selectAllSorts);
  const appliedBuyBoxList = useSelector(selectAllBuyBoxes);
  const appliedColumns = useSelector((state) => state.leads.appliedColumns);
  const appliedGroupBy = useSelector((state) => state.view.groupBy);
  const creatingNewView = useSelector((state) => state.view.creatingNewView);
  const editingExistingView = useSelector(
    (state) => state.view.editingExistingView
  );
  const [fetchGroupByAndCount, { data, isFetching, isLoading }] =
    useLazyGroupByAndCountQuery();

  const selectedTab = useSelector((state) => state.view.selectedTab);
  const currentViewState = useSelector((state) => state.view.currentViewState);
  const currentViewID = useSelector((state) => state.view.currentViewID);
  const groupBySubViewSelected = useSelector(
    (state) => state.view.groupBySubViewSelected
  );

  const paginationModelState = useSelector(
    (state) => state.view.paginationModelState
  );

  const dispatch = useDispatch();

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [viewName, setViewName] = useState(unsavedViewState?.name || "");

  const {
    data: selectedViewData,
    isLoading: isSingleViewLoading,
    isFetching: isSingleViewFetching,
    isError: isSingleViewError,
  } = useGetSingleViewByIdQuery(
    {
      viewId: currentViewID,
      queryParams: {
        page: paginationModelState.page + 1,
        parpage: paginationModelState.pageSize,
        getCount: "0",
      },
    },
    {
      skip:
        !currentViewID ||
        editingExistingView ||
        creatingNewView ||
        groupBySubViewSelected,
    }
  );

  const [
    createView,
    {
      isLoading: isCreatingView,
      isError: isCreatingViewError,
      error: creatingViewError,
    },
  ] = useCreateViewMutation();

  const [
    duplicateView,
    {
      isLoading: isDuplicatingView,
      isError: isDuplicatingViewError,
      error: duplicatingViewError,
    },
  ] = useDuplicateViewMutation();

  const [
    copyToPrivate,
    {
      isLoading: isCopyingToPrivate,
      isError: isCopyingToPrivateError,
      error: copyingToPrivateError,
    },
  ] = useCopyToPrivateMutation();

  const [
    deleteView,
    {
      isLoading: isDeletingView,
      isError: isDeletingViewError,
      error: deletingViewError,
    },
  ] = useDeleteViewMutation();

  const [
    updateView,
    {
      isLoading: isUpdateViewLoading,
      isError: isUpdateViewError,
      isSuccess: isUpdateViewSucess,
    },
  ] = useUpdateViewMutation();

  useEffect(() => {
    if (creatingNewView && editingExistingView) {
      setViewName("");
    } else if (!creatingNewView && editingExistingView) {
      setViewName(unsavedViewState?.name || "");
    }
  }, [creatingNewView, editingExistingView, unsavedViewState]);

  // Effect which get's fire and fetch the groupBy And count for each view
  useEffect(() => {
    if (selectedViewData) {
      if (selectedViewData?.view?.filters.groupBy) {
        const res = fetchGroupByAndCount(
          { data: selectedViewData?.view?.filters },
          { preferCacheValue: true }
        );
      }
    }
  }, [selectedViewData, fetchGroupByAndCount]);

  // const toggleDrawer = () => {
  //   setSidebarOpen(!sidebarOpen);
  // };

  const handleTabChange = (event, newValue) => {
    setCurrentSubView({});
    dispatch(updateGroupBySubViewSelected(false));
    dispatch(updateSelectedTab(newValue));
  };

  const handleSearchViews = (e) => {
    setSearchVal(e.target.value);
  };

  // Creating a new view
  const handleSaveAs = async () => {
    try {
      const res = await createView({
        space: selectedTab,
        data: {
          name: viewName,
          filters: unsavedViewState.filters,
        },
      });

      handleCancel();
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const handleDuplicateView = async () => {
    try {
      const res = await duplicateView({
        space: selectedTab,
        data: {
          id: currentViewState.id,
          name: viewName !== "" ? viewName : null,
          space: selectedTab,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCopyToPrivate = async () => {
    try {
      const res = await copyToPrivate({
        data: {
          id: currentViewState.id,
          name: viewName !== "" ? viewName : null,
        },
      });
      dispatch(updateSelectedTab("private"));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteView = async () => {
    try {
      const res = await deleteView({
        space: selectedTab,
        viewId: currentViewState.id,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditView = async () => {
    try {
      await updateView({
        space: selectedTab,
        data: {
          name: viewName,
          id: currentViewState.id,
          filters: unsavedViewState.filters,
        },
      });
      handleCancel();
    } catch (error) {
      console.error(error);
      handleCancel();
    }
  };

  const handleCreateViewSelected = () => {
    dispatch(updateCreatingNewView(true));
    dispatch(
      updateUnSavedViewState({
        name: "",
        id: 1,
        filters: getAdvancedSearchPayload(
          appliedFiltersList,
          appliedSorts,
          appliedBuyBoxList,
          appliedColumns,
          appliedGroupBy
        ),
      })
    );
  };

  const handleCancel = async () => {
    dispatch(updateIsCanceled(true));
    if (selectedViewData) {
      dispatch(updateLeads(selectedViewData.data));
    }
    setViewName("");
    dispatch(updateCreatingNewView(false));
    dispatch(updateEditingExistingView(false));
    dispatch(updateUnSavedViewState({}));
    dispatch(
      setSorts(
        currentViewState.filters?.sort ? currentViewState.filters?.sort : []
      )
    );
    dispatch(
      setFilters(
        currentViewState.filters?.filters[0]?.filters
          ? currentViewState.filters?.filters[0].filters
          : []
      )
    );
    dispatch(
      setBuyBoxes(
        currentViewState.filters?.filters[1]?.buyboxes !== undefined
          ? currentViewState.filters?.filters[1].buyboxes
          : []
      )
    );
    dispatch(
      updateAppliedColumns(
        currentViewState.filters?.columns
          ? currentViewState.filters?.columns
          : defaultVisibleColumns
      )
    );
    dispatch(
      updateGroupBy(
        currentViewState.filters?.groupBy
          ? currentViewState.filters?.groupBy
          : null
      )
    );
    dispatch(
      updatePaginationModelState({
        page: 0,
        pageSize: 100,
      })
    );
  };

  return (
    <Box sx={{ mr: 2, position: "relative" }}>
      {/* <MinimalIconButtonWithToolTip
        toolTipLabel={sidebarOpen ? "close sidebar" : "open sidebar"}
        onClick={toggleDrawer}
        sx={{ position: "fixed", left: -5, top: 10, zIndex: 1000 }}
      >
        {sidebarOpen ? <ChevronLeft /> : <ChevronRight />}
      </MinimalIconButtonWithToolTip> */}

      <Box
        sx={{
          width: sidebarOpen ? "320px" : 0,
          display: sidebarOpen ? "block" : "none",
          height: "100%",
          flexShrink: 0,
          overflowY: "scroll",
          py: 1,
          border: `1px solid ${theme.palette.divider}`,
          transition: "width 0.3s ease",
          "& .MuiDrawer-paper": {
            width: sidebarOpen ? "320px" : 0,
            boxSizing: "border-box",
            transition: "width 0.3s ease",
            height: "auto",
            border: `1px solid ${theme.palette.divider}`,
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: 1,
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            centered
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 1,

              "& .MuiTab-root.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderRadius: "10px",
                fontWeight: "bold",
                fontSize: "0.875rem",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: theme.palette.primary.light,
                display: "none",
              },
            }}
          >
            <Tab
              sx={{ width: "130px", fontSize: "0.875rem" }}
              label="Team"
              value={"team"}
            />
            <Tab
              sx={{ width: "130px", fontSize: "0.875rem" }}
              label="Private"
              value={"private"}
            />
          </Tabs>

          {Object.entries(unsavedViewState).length === 0 && (
            <MinimalIconButtonWithToolTip
              toolTipLabel={"Add"}
              style={{
                color: theme.palette.text.secondary,
                backgroundColor: "transparent",
                borderRadius: "50%",
                border: theme.palette.divider,
                fontSize: "20px",
                position: "absolute",
                right: 8,
              }}
            >
              <AddIcon fontSize="large" onClick={handleCreateViewSelected} />
            </MinimalIconButtonWithToolTip>
          )}
        </Box>

        {Object.entries(unsavedViewState).length === 0 && (
          <Box
            sx={{
              width: "100%",
              position: "relative",
              pb: 2,
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <TextInput
              inputValue={searchVal}
              onChangeHandler={handleSearchViews}
              textFieldProps={{
                placeholder: "search views",
                size: "small",
              }}
              styles={{
                focus: "none",
                width: "100%",
                py: "1px",
                px: 1,
              }}
            />
            <Search
              fontSize="small"
              sx={{
                color: theme.palette.text.secondary,
                position: "absolute",
                right: "20px",
                bottom: "28px",
                cursor: "pointer",
              }}
            />
          </Box>
        )}

        {Object.entries(unsavedViewState).length > 0 && (
          <UnsavedForm
            creatingNewView={creatingNewView}
            viewName={viewName}
            isCreatingView={isCreatingView}
            isCreatingViewError={isCreatingViewError}
            isUpdateViewError={isUpdateViewError}
            isUpdateViewLoading={isUpdateViewLoading}
            onChangeViewName={(e) => setViewName(e.target.value)}
            handleSaveAs={handleSaveAs}
            handleEditView={handleEditView}
            handleCancel={handleCancel}
          />
        )}

        {/* common ViewsList component */}
        <ViewsList
          space={selectedTab}
          currentSubGroupby={currentSubGroupby}
          setCurrentSubView={setCurrentSubView}
          useFetchViews={useGetViewsListQuery}
          useFetchSingleView={useGetSingleViewByIdQuery}
          handleDuplicateView={handleDuplicateView}
          handleCopyToPrivate={handleCopyToPrivate}
          handleDeleteView={handleDeleteView}
          isDuplicatingView={isDuplicatingView}
          isDeletingView={isDeletingView}
          isCopyingToPrivate={isCopyingToPrivate}
          searchTerm={searchVal}
          selectedViewData={selectedViewData}
          isSingleViewError={isSingleViewError}
          isSingleViewFetching={isSingleViewFetching}
          isSingleViewLoading={isSingleViewLoading}
          groupByAndCount={
            !selectedViewData?.view?.filters.groupBy ? null : data
          }
          groupByAndCountLoading={isLoading || isFetching}
          handleCancel={handleCancel}
        />
      </Box>
    </Box>
  );
}
