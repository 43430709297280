import React, { useEffect, useState } from "react";
import LeadsComparisonTable from "../../leadUnderwriting/LeadsComparisionTable";
import {
  getLatestCalcData,
  useGetLeadWithRelatedDataQuery,
} from "../../lib/propertyDetailsApi";
import { useParams } from "react-router-dom";
import PropDetailSkeleton from "../../leadUnderwriting/Skeleton/propDetailSkeleton";
import LeadDetails from "../../leadUnderwriting/LeadDetails";
import { useGetOwnUserAuthQuery } from "../../lib/userTableApi";
import { useGetFieldsConfigQuery } from "../../multiPropertyView/api-slices/authApiSlice";

const PropertyDetailsPage = () => {
  const { data: fieldConfigData } = useGetFieldsConfigQuery();
  const { uid } = useParams();
  const [showPropertyInfo, setShowPropertyInfo] = useState(true);
  const [showLeadComparison, setShowLeadComparison] = useState(false);
  const { data: leadDetails } = useGetLeadWithRelatedDataQuery({ id: uid });
  const {
    data: ownUser,
    isLoading: isLoadingUser,
    isError: isErrorUser,
  } = useGetOwnUserAuthQuery();

  const {
    isSuccess: isCalcDataSuccess,
    isLoading: isCalcDataLoading,
    isError: isCalcDataError,
  } = getLatestCalcData.useQuery({
    leadId: leadDetails.id,
    propertyId: leadDetails["property_id"],
  });

  const isDataPresent =
    ownUser && !isErrorUser && !isCalcDataError && isCalcDataSuccess;

  return isCalcDataLoading || isLoadingUser ? (
    <PropDetailSkeleton />
  ) : isDataPresent ? (
    <>
      {showLeadComparison ? (
        <LeadsComparisonTable
          setShowLeadComparison={setShowLeadComparison}
          setShowPropertyInfo={setShowPropertyInfo}
        />
      ) : (
        <LeadDetails leadDetails={leadDetails} ownUser={ownUser} />
      )}
    </>
  ) : null;
};

export default PropertyDetailsPage;
