import { Box, Paper, Typography, Skeleton } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const userCardSx = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  gap: "10px",
  width: "max-content",
  maxWidth: "500px",
  paddingX: "1rem",
  paddingY: "5px",
};
export function UserItemOutlineCard({ displayName, email, slotProps = {} }) {
  // These define the slots
  const { container: ctnSlotProps = {}, icon: iconSlotProps = {} } = slotProps;

  return (
    <Paper
      elevation={1}
      {...ctnSlotProps}
      sx={{ ...userCardSx, ...ctnSlotProps.sx }}
    >
      <Box>
        <PersonIcon
          {...iconSlotProps}
          sx={{
            color: "text.secondary",
            fontSize: "2rem",
            ...iconSlotProps.sx,
          }}
        />
      </Box>
      <Typography component="div">{displayName}</Typography>
      <Typography component="div" sx={{ color: "text.secondary" }}>
        {email}
      </Typography>
    </Paper>
  );
}

export function UserCardSkel() {
  return (
    <Paper sx={{ ...userCardSx }} elevation={1}>
      <Box>
        <PersonIcon sx={{ color: "text.secondary", fontSize: "2rem" }} />
      </Box>
      <Skeleton variant="rectangle" width={150} height="1rem" />
    </Paper>
  );
}

export function UserCardBase({ displayName, email, sx }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        gap: "10px",
        ...sx,
      }}
    >
      <Box>
        <PersonIcon sx={{ color: "text.secondary", fontSize: "3rem" }} />
      </Box>
      <Box>
        <Typography>{displayName}</Typography>
        <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
          {email}
        </Typography>
      </Box>
    </Box>
  );
}
