import { Outlet } from "react-router-dom";
import Header from "../component/SinglePropertyPage/Header";
import { useParams } from "react-router-dom";
import {
  getCashFlowCalcMetrics,
  useGetLeadWithRelatedDataQuery,
} from "../lib/propertyDetailsApi";
import OverviewSkeleton from "../component/SinglePropertyPage/Overview/skeleton/OverviewSkeleton";
import { Box } from "@mui/material";
import { useGetFieldsConfigQuery } from "../multiPropertyView/api-slices/authApiSlice";

const SinglePropertyPage = () => {
  const { uid } = useParams();
  const { data: fieldConfigData } = useGetFieldsConfigQuery();

  const {
    data: lead,
    isLoading,
    isFetching,
    isError,
    error,
    isSuccess,
    isUninitialized,
  } = useGetLeadWithRelatedDataQuery({
    id: uid,
  });

  getCashFlowCalcMetrics.useQuery({ leadId: uid });

  const isDataValid = isSuccess && lead?.id;
  const isRequestFinished = !(isUninitialized || isLoading || isFetching);
  if ((!isDataValid || isError) && isRequestFinished) {
    if (error) {
      throw error;
    }
    throw new Error("Error occured while trying to get lead data");
  }

  return (
    <Box
      className="main-ctn"
      sx={{ height: "100vh", display: "flex", flexDirection: "column" }}
    >
      {isLoading ? (
        <OverviewSkeleton />
      ) : isDataValid && !isError ? (
        <>
          <Header showPropertyInfo={true} lead={lead} />
          <Box sx={{ flexGrow: 1, overflow: "auto" }}>
            <Outlet />
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default SinglePropertyPage;
