import AuthLayout from "../layout/AuthLayout";
import SignIn from "../pages/login";

const LoginRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "login",
      element: <SignIn />,
    },
  ],
};

export default LoginRoutes;
