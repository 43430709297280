import { useDispatch, useSelector } from "react-redux";
// import LeadsTable from "../../../../component/leads/LeadsTable";
// import LeadsSearch from "../../../../component/leads/LeadsSearch";
import LeadsTable from "../../../../multiPropertyView/LeadsTable/LeadsTable";
import { getColsForTbl } from "../../../../component/SinglePropertyPage/common/muixDataGrid/table/mutiRecordTable/utils";
import {
  selectAllBuyBoxes,
  selectAllFilters,
  selectAllSorts,
} from "../../../../multiPropertyView/redux-slices/advancedSearchSlice";
import {
  defaultVisibleColumns,
  detectUnsavedChanges,
  getAdvancedSearchPayload,
} from "../../../../multiPropertyView/utils";
import { useEffect, useState } from "react";
import {
  updateEditingExistingView,
  updateUnSavedViewState,
} from "../../../../multiPropertyView/redux-slices/viewsSlice";
import {
  updateAppliedColumns,
  updateIsCanceled,
} from "../../../../multiPropertyView/redux-slices/leadsSlice";

export default function LeadsDisplay({
  leads,
  loading,
  allowPagination = false,
  updatePaginationModelState,
}) {
  const fieldsConfig = useSelector((state) => state.fieldsConfig.fieldsConfig);

  // const { properties, loading } = useSelector((state) => state.properties);
  // const mode = useSelector((state) => state.leadsDisplayMode.mode);

  // if (mode === "search") {
  //   return <LeadsSearch rows={properties} loadingRows={loading} />;
  // }

  const dispatch = useDispatch();
  const tblFieldsConf = fieldsConfig?.filter(
    (field) => field.table_alias === "lead"
  );
  const dataCols = getColsForTbl(tblFieldsConf);
  const currentSelectedView = useSelector(
    (state) => state.view.currentViewState
  );
  const appliedFiltersList = useSelector(selectAllFilters);
  const appliedSorts = useSelector(selectAllSorts);
  const appliedBuyBoxList = useSelector(selectAllBuyBoxes);
  const appliedGroupBy = useSelector((state) => state.view.groupBy);
  const isCanceled = useSelector((state) => state.leads.isCanceled);

  const currentViewFilters =
    currentSelectedView.filters?.filters[0]?.filters || [];
  const currentViewBuyBoxes =
    currentSelectedView.filters?.filters[0]?.buyboxes || [];
  const currentViewSorts = currentSelectedView.filters?.sort || [];
  const creatingNewView = useSelector((state) => state.view.creatingNewView);
  const currentViewColumns =
    currentSelectedView.filters?.columns || defaultVisibleColumns;
  const currentViewsGroupBy = currentSelectedView.filters?.groupBy || null;

  const initialVisibleCols = dataCols.reduce((acc, col) => {
    acc[col.field] = currentViewColumns.includes(col.field); // Set true if included, otherwise false
    return acc;
  }, {});

  const [visibleColumns, setVisibleColumns] = useState(initialVisibleCols);

  const handleUpdateColumnVisibility = (newCols) => {
    setVisibleColumns(newCols);

    if (!creatingNewView) {
      dispatch(updateEditingExistingView(true));
    }

    const appliedColumns = Object.keys(newCols).filter(
      (field) => newCols[field]
    );
    dispatch(updateAppliedColumns(appliedColumns));

    const isValueSame = detectUnsavedChanges(
      currentViewFilters,
      currentViewSorts,
      currentViewBuyBoxes,
      currentViewColumns,
      currentViewsGroupBy,
      appliedFiltersList,
      appliedSorts,
      appliedBuyBoxList,
      appliedColumns,
      appliedGroupBy
    );

    if (!isValueSame) {
      dispatch(
        updateUnSavedViewState({
          name: currentSelectedView.name,
          id: 1,
          filters: getAdvancedSearchPayload(
            appliedFiltersList,
            appliedSorts,
            appliedBuyBoxList,
            appliedColumns,
            appliedGroupBy
          ),
        })
      );
    }

    if (isValueSame) {
      dispatch(updateUnSavedViewState({}));
    }
  };

  useEffect(() => {
    setVisibleColumns(initialVisibleCols);
    dispatch(updateIsCanceled(false));
  }, [currentViewColumns, isCanceled]);

  return (
    <>
      {/* <LeadsTable
        rows={properties}
        // loading={loading}
        className="fill-grid grid-table rdg-light"
      /> */}
      <LeadsTable
        leads={leads}
        loading={loading}
        columnVisibilityModel={visibleColumns}
        onColumnVisibilityModelChange={handleUpdateColumnVisibility}
        allowPagination={allowPagination}
        updatePaginationModelState={updatePaginationModelState}
      />
    </>
  );
}
