import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTab: "team",
  creatingNewView: false,
  editingExistingView: false,
  currentViewState: {},
  unSavedViewState: {},
  groupBy: null,
  groupBySubViewSelected: false,
  paginationModelState: {
    page: 0,
    pageSize: 100,
  },
  currentViewID: null,
};

const viewsSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    updateSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    updateCreatingNewView: (state, action) => {
      state.creatingNewView = action.payload;
    },
    updateEditingExistingView: (state, action) => {
      state.editingExistingView = action.payload;
    },
    updatePaginationModelState: (state, action) => {
      state.paginationModelState = action.payload;
    },
    updateCurrentViewState: (state, action) => {
      state.currentViewState = action.payload;
    },
    updateUnSavedViewState: (state, action) => {
      state.unSavedViewState = action.payload;
    },
    updateCurrentViewID: (state, action) => {
      state.currentViewID = action.payload;
    },
    updateGroupBy: (state, action) => {
      state.groupBy = action.payload;
    },
    updateGroupBySubViewSelected: (state, action) => {
      state.groupBySubViewSelected = action.payload;
    }
  },
});

export const {
  updateSelectedTab,
  updateCreatingNewView,
  updateEditingExistingView,
  updatePaginationModelState,
  updateCurrentViewState,
  updateUnSavedViewState,
  updateCurrentViewID,
  updateGroupBy,
  updateGroupBySubViewSelected,
} = viewsSlice.actions;

export default viewsSlice.reducer;
