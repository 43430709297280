import EditSingleSelectCellRenderer from "./EditSingleSelectCellRenderer";
import EditTextCellRenderer from "./EditTextCellRenderer";
import EditDateCellRenderer from "./EditDateCellRenderer";
import EditDateTimeCellRenderer from "./EditDateTimeCellRenderer";
import EditBooleanRenderer from "./EditBooleanRenderer";
import { EditLinkArrayRenderer } from "./LinkRenderers";
import { EditRelationRenderer } from "./cellRenderer/relationCellRenderer";

function EditCellRenderer(props) {
  const { row } = props;
  const { dtype } = row;

  switch (dtype) {
    case "string":
    case "float":
    case "int":
    case "decimal":
      return <EditTextCellRenderer {...props} />;
    case "category":
      return <EditSingleSelectCellRenderer {...props} readOnly={false} />;
    case "datetime":
      return <EditDateTimeCellRenderer {...props} />;
    case "date":
      return <EditDateCellRenderer {...props} />;
    case "boolean":
      return <EditBooleanRenderer {...props} readOnly={false} />;

    case "links":
      return <EditLinkArrayRenderer {...props} />;

    case "relation":
      return <EditRelationRenderer {...props} />;
    default:
      console.error("invalid data type found while rendering table");
      return <div></div>;
  }
}

export default EditCellRenderer;
