import { Box, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import DOMPurify from "dompurify";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import MinimalIconButtonWithToolTip from "../../component/button/MinimalIconButtonWithTooltip";
import { useGenerateAttachmentMutation } from "../lib/attachmentApi";

export default function EmailTracker({ emailData }) {
  const theme = useTheme();

  const [generateDocURL, { isLoading, isError, data }] =
    useGenerateAttachmentMutation();

  const handleGenerateURL = async (s3Key) => {
    try {
      const res = await generateDocURL({
        s3Key: `email-attachments/${s3Key}`,
        expiresIn: 60,
      });

      if (res?.data?.url) {
        window.open(res.data.url, "_blank");
      } else {
        console.error("Failed to get the URL", res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column-reverse", gap: "20px" }}>
      {emailData?.map((email) => {
        const { email_body } = email;

        return (
          <Box
            key={email?.id}
            sx={{ pl: 1, borderLeft: `3px solid ${theme.palette.divider}` }}
          >
            <Box
              sx={{
                marginBottom: 1,
                borderBottom: `1px solid ${theme.palette.divider}`,
                py: "5px",
              }}
            >
              <Typography
                variant="body1"
                sx={{ mb: "2px", fontSize: "0.875rem" }}
              >
                From: {email_body?.from?.value[0]?.name} (
                {email_body?.from?.value[0]?.address})
              </Typography>
              <Typography
                variant="body1"
                sx={{ mb: "2px", fontSize: "0.875rem" }}
              >
                To: {email_body?.to?.value[0]?.name} (
                {email_body?.to?.value[0]?.address})
              </Typography>
              <Typography
                variant="body1"
                sx={{ mb: "2px", fontSize: "0.875rem" }}
              >
                Date:{" "}
                {dayjs(email_body?.date).format("dddd, MMMM D, YYYY h:mm A")}
              </Typography>
              <Typography
                variant="body1"
                sx={{ mb: "2px", fontSize: "0.875rem" }}
              >
                Subject: {email_body?.subject}
              </Typography>
            </Box>

            <Box
              sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box
                sx={{
                  fontSize: "0.875rem",
                  fontFamily: "inherit",
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(email_body?.html),
                }}
              />
              <Typography
                variant="body1"
                sx={{ fontWeight: "semibold", mt: "2px", fontSize: "0.875rem" }}
              >
                Attachments:
              </Typography>
              {email_body?.attachments?.map((file, idx) => (
                <Box
                  key={file.s3Key}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    py: "3px",
                    my: "2px",
                  }}
                >
                  <MinimalIconButtonWithToolTip
                    toolTipLabel={"Show attachment"}
                  >
                    <PictureAsPdfOutlinedIcon />
                  </MinimalIconButtonWithToolTip>
                  <Typography
                    sx={{
                      color: theme.palette.grey[800],
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "95%",
                      fontWeight: "semibold",
                      cursor: "pointer",
                      fontSize: "0.875rem",
                    }}
                    variant="body1"
                    onClick={() => handleGenerateURL(file.s3Key)}
                  >
                    {file.fileName}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
