import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api_base_url } from "../../service/constants";

const BASE_URL_SUFFIX = "/leads";

export const multiPropViewApiSlice = createApi({
  reducerPath: "multiPropViewApi",
  baseQuery: fetchBaseQuery({
    baseUrl: api_base_url(),
  }),
  tagTypes: ["View", "TeamViews", "PrivateViews"],
  endpoints: (builder) => ({
    // ----- GET Routes ------
    getSingleViewById: builder.query({
      query: ({ viewId, queryParams }) => {
        const { page, parpage, getCount = "0" } = queryParams;

        return {
          url: `${BASE_URL_SUFFIX}/views/${viewId}?getCount=${getCount}&page=${page}&parpage=${parpage}`,
        };
      },
      providesTags: (result, error, { viewId, queryParams }) => [
        { type: "View", id: viewId },
      ],
    }),

    // GetViewsList
    getViewsList: builder.query({
      query: (space) => {
        // Dynamically choose the URL based on the `space` parameter
        const url =
          space === "private"
            ? `${BASE_URL_SUFFIX}/views/private`
            : `${BASE_URL_SUFFIX}/views`;

        return { url };
      },
      // Dynamically tag based on the space
      providesTags: (result, error, space) => {
        return space === "private" ? ["PrivateViews"] : ["TeamViews"];
      },
    }),

    getAllBuyBoxes: builder.query({
      query: () => ({
        url: `${BASE_URL_SUFFIX}/buyboxes`,
      }),
    }),
    // ------------------------------

    // ----- POST routes ------
    applyAdvancedSearch: builder.mutation({
      query: ({ queryParams, data }) => ({
        url: `${BASE_URL_SUFFIX}/advanced-search`,
        method: "POST",
        params: queryParams,
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    createView: builder.mutation({
      query: ({ space, data }) => {
        return {
          url:
            space === "private"
              ? `${BASE_URL_SUFFIX}/views`
              : `${BASE_URL_SUFFIX}/views/team`,
          method: "POST",
          body: data,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: (result, error, { space, data }) => {
        if (space === undefined || space === null || space === "private") {
          return ["PrivateViews"];
        } else if (space === "team") {
          return ["TeamViews"];
        }

        return ["PrivateViews", "TeamViews"];
      },
    }),
    duplicateView: builder.mutation({
      query: ({ space, data }) => ({
        url: `${BASE_URL_SUFFIX}/views/duplicate`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: (result, error, { space, data }) => {
        if (space === undefined || space === null || space === "private") {
          return ["PrivateViews"];
        } else if (space === "team") {
          return ["TeamViews"];
        }

        return ["PrivateViews", "TeamViews"];
      },
    }),
    copyToPrivate: builder.mutation({
      query: ({ data }) => ({
        url: `${BASE_URL_SUFFIX}/views/copy-to-private`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["PrivateViews"],
    }),

    groupByAndCount: builder.query({
      query: ({ data }) => ({
        url: `${BASE_URL_SUFFIX}/views/group-by-and-count`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    // ------------------------------

    // ----- PATCH routes -----
    updateView: builder.mutation({
      query: ({ space, data }) => ({
        url:
          space === "private"
            ? `${BASE_URL_SUFFIX}/views`
            : `${BASE_URL_SUFFIX}/views/team`,
        method: "PATCH",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),

      invalidatesTags: (result, error, { space, data }) => [
        space === "private" ? "PrivateViews" : "TeamViews",
        { type: "View", id: data?.id },
      ],
    }),

    renameView: builder.mutation({
      query: ({ data }) => ({
        url: `${BASE_URL_SUFFIX}/views/rename`,
        method: "PATCH",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["PrivateViews"],
    }),
    renameViewTeam: builder.mutation({
      query: ({ data }) => ({
        url: `${BASE_URL_SUFFIX}/views/rename/team`,
        method: "PATCH",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["TeamViews"],
    }),
    // ------------------------------

    // ----- DELETE routes ------
    deleteView: builder.mutation({
      query: ({ space, viewId }) => ({
        url:
          space === "private"
            ? `${BASE_URL_SUFFIX}/views/${viewId}`
            : `${BASE_URL_SUFFIX}/views/team/${viewId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { space, data }) => {
        if (space === undefined || space === null || space === "private") {
          return ["PrivateViews"];
        } else if (space === "team") {
          return ["TeamViews"];
        }
      },
    }),
    // ------------------------------
  }),
});

export const {
  useGetSingleViewByIdQuery,
  useGetViewsListQuery,
  useGetAllBuyBoxesQuery,
  useApplyAdvancedSearchMutation,
  useCreateViewMutation,
  useDuplicateViewMutation,
  useCopyToPrivateMutation,
  useUpdateViewMutation,
  useRenameViewMutation,
  useRenameViewTeamMutation,
  useDeleteViewMutation,
  useLazyGroupByAndCountQuery,
} = multiPropViewApiSlice;
