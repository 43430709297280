import { propLeadApiSlice } from "../../lib/propertyDetailsApi";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { YMD_DATE_FORMAT } from "../../common/utils/constants";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { api_base_url } from "../../service/constants";

dayjs.extend(utc);

const BASE_URL_SUFFIX_LKUP = {
  "activity": "/activity-tracker",
  "comment": "/comment-tracker",
  "files": "/file-tracker",
}

export function createRelTblEvents(responseData) {
  const events = [];

  Object.entries(responseData).forEach(([key, value]) => {
    events.push({
      field_name: key,
      prev_val: null,
      cur_val: value,
      event_time_on: dayjs.utc().format(YMD_DATE_FORMAT),
    });
  });

  return events;
}

const activityTrackerApi = propLeadApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDynamicData: builder.query({
      queryFn: async (queryParams, api, extraOptions, baseQuery) => {
        const { resource, id, limit, propertyId, table_alias, lastTime } =
          queryParams;

        let url = "";
        let params = {};

        switch (resource) {
          case "activity":
            url = `${BASE_URL_SUFFIX_LKUP[resource]}/${id}`;
            params = {
              limit,
              propertyId,
              table_alias: table_alias === "hoa" ? "hoa_bill" : table_alias,
            };
            if (lastTime) {
              params.lastTime = lastTime;
            }
            break;
          case "comment":
            url = `${BASE_URL_SUFFIX_LKUP[resource]}/comments/${id}`;
            params = { limit };
            if (lastTime) {
              params.lastTime = lastTime;
            }
            break;
          case "files":
            url = `${BASE_URL_SUFFIX_LKUP[resource]}/${id}`;
            params = { table_alias, limit };
            if (lastTime) {
              params.lastTime = lastTime;
            }
            break;
          default:
            throw new Error("Invalid resource type");
        }

        try {
          const {data, error, _meta} = await baseQuery({url, params}, api, extraOptions);
          if (error) {
            return {error};
          }
          const transformedData = data.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {});
  
          return { data: transformedData };
        } catch (exc) {
          return { error: exc };
        }
      },

      providesTags: ["ACTIVITY_TRACKER"],

      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.id}`;
      },

      merge: (currentCacheData, newItems, { arg }) => {

        // keep forceRefresh as queryArg
        if (arg.lastTime) {
          if (Object.entries(newItems).length > 0) {
            Object.assign(currentCacheData, newItems);
          }
          return currentCacheData;
        }

        if (arg.forceRefresh) {
          return newItems;
        }

        const sortedItems = Object.values(newItems).sort(
          (a, b) => parseInt(b.event_time_on) - parseInt(a.event_time_on)
        );

        const oldestItem = sortedItems[sortedItems.length - 1];

        const isOldestItemPresent = currentCacheData.hasOwnProperty(
          oldestItem?.id
        );

        if (isOldestItemPresent) {
          // oldest item present in newItems -->
          Object.assign(currentCacheData, newItems);

          if (Object.keys(currentCacheData)?.length > 150 * 2) {
            return newItems;
          }
        } else {
          return newItems;
        }
      },

      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),

    createActivity: builder.mutation({
      query: ({ id, property_id, revData, eventType, table_alias }) => {
        return {
          url: `${BASE_URL_SUFFIX_LKUP["activity"]}`,
          method: "POST",
          body: {
            id: id,
            table_alias: table_alias,
            event_type: eventType,
            property_id: property_id,
            events: revData,
          },
        };
      },

      invalidatesTags: ["ACTIVITY_TRACKER"],
    }),

    uploadFiles: builder.mutation({
      query: (paylod) => {
        return {
          url: `${BASE_URL_SUFFIX_LKUP["files"]}`,
          method: "POST",
          body: paylod,
        };
      },

      invalidatesTags: ["ACTIVITY_TRACKER"],
    }),

    deleteFile: builder.mutation({
      query: (queryParams) => {
        const { id, ...rest } = queryParams;

        return {
          url: `${BASE_URL_SUFFIX_LKUP["files"]}/${id}`,
          params: rest,
          method: "DELETE",
        };
      },
    }),

    createComment: builder.mutation({
      query: ({ id, content }) => ({
        url: `${BASE_URL_SUFFIX_LKUP["comment"]}/comments`,
        method: "POST",
        body: { id, content },
      }),

      invalidatesTags: ["ACTIVITY_TRACKER"],
    }),
  }),
});

export const {
  useCreateActivityMutation,
  useGetDynamicDataQuery,
  useLazyGetDynamicDataQuery,
  useUploadFilesMutation,
  useDeleteFileMutation,
  useCreateCommentMutation,
} = activityTrackerApi;
