import ShowValue from "../../../ShowValue";
import { isMissingLike } from "../../../utils";


// FIXME: For date, datetime, MUI X grid expects date objects..
export { isMissingLike };
export function getColTypeForDtype(dtype) {
  switch (dtype) {
    case "int":
    case "float":
    case "decimal":
      return "number";

    case "category":
    case "boolean":
      return "singleSelect";

    // case "date":
    //   return "date";

    // case "datetime":
    //   return "datetime";

    default:
      return "text";
  }
}

export function showValue(value) {
  if (isMissingLike(value)) {
    return <ShowValue value={value} />;
  }
  return value;
}
