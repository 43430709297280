export const ISO8601_DATE_Format_DAYJS = "YYYY-MM-DDTHH:mm:ss[Z]";
export const YMD_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DEFAULT_TIMEZONE = "America/New_York";
export const D_MDY_HMS_A_DATETIME = "ddd MM-DD-YYYY, hh:mm a";
export const D_MDY_DATE = "ddd MM-DD-YYYY";

export const DEF_TOAST_ID = "global-rtk-query-error-toast";
export const DEF_TOAST_CTN_PROPS = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  theme: "light",
};

export const TBL_ALIAS = {
  prop: "prop",
  lead: "lead",
  valuation: "valuation",
  hoa: "hoa",
  tax: "tax",
  rehab: "rehab",
  zip_identity: "zip_identity",
  lead_overwrite: "lead_overwrite",
};
