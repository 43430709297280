import React from "react";
import _ from "lodash";
import H3 from "./H3";
import { useTheme } from "@mui/material";

const ShowValue = ({
  value,
  styles,
  valTransformer,
  extraLabel,
  classProp,
}) => {
  const theme = useTheme();
  const classes = {
    naText: {
      backgroundColor: `${theme.palette.grey[500]}`,
      color: `${theme.palette.common.white}`,
      padding: "2px 8px",
      borderRadius: "4px",
      fontSize: "0.8rem",
    },
  };

  const renderValue = (value) => {
    value = !valTransformer ? value : valTransformer(value);

    if (value === "") {
      return <span style={classes.naText}>Empty</span>;
    }
    if (value === undefined) {
      return <span style={classes.naText}>N/A</span>;
    }
    if (value === null) {
      return <span style={classes.naText}>Null</span>;
    }
    // NOTE: isNaN(undefined) === true, in vanilla JS, so use lodash function!
    if (_.isNaN(value)) {
      return <span style={classes.naText}>NaN</span>;
    }

    if (typeof value === "string" || typeof value === "number") {
      return (
        <H3
          title={value}
          extralabel={extraLabel}
          color={`"${theme.palette.text.secondary}"`}
        />
      );
    }
    throw new Error("can't render the value, has invalid type");
  };

  return (
    <span className={classProp} style={{ ...styles }}>
      {renderValue(value)}
    </span>
  );
};

export default ShowValue;
