import { Box, Typography, useTheme } from "@mui/material";
import MinimalIconButtonWithToolTip from "../../component/button/MinimalIconButtonWithTooltip";
import { useGenerateAttachmentMutation } from "../lib/attachmentApi";
import { Delete } from "@mui/icons-material";
import LoaderIconButton from "../../component/button/LoaderIconButton";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import TableChartIcon from "@mui/icons-material/TableChart";
import CodeIcon from "@mui/icons-material/Code";
// import {
//   useDeleteFileMutation,
//   useLazyGetDynamicDataQuery,
// } from "../lib/activityTrackerApi";

export function FileTracker({ file, id: recordId, limit, tableAlias }) {
  const theme = useTheme();
  // const [fetchFilesData] = useLazyGetDynamicDataQuery();

  const [generateAttachment, { data, isError, isLoading }] =
    useGenerateAttachmentMutation();
  // const [
  //   deleteFile,
  //   { isLoading: isDeleteFileLoading, isError: isDeleteFileError },
  // ] = useDeleteFileMutation();

  const handleGenerateURL = async (s3Key) => {
    try {
      const res = await generateAttachment({
        s3Key,
        expiresIn: 60,
      });

      if (res?.data?.url) {
        window.open(res.data.url, "_blank");
      } else {
        console.error("Failed to get the URL", res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleDeleteFile = async (id, s3Key) => {
  //   try {
  //     const res = await deleteFile({ id, s3_key: s3Key });
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   const data = await fetchFilesData({
  //     resource: "files",
  //     id: recordId,
  //     limit: limit,
  //     table_alias: tableAlias,
  //     forceRefresh: true,
  //   }).unwrap();
  // };

  return (
    <Box>
      <Box
        key={file.event_body?.s3_key}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "3px",
        }}
      >
        <MinimalIconButtonWithToolTip toolTipLabel={"Show attachment"}>
          {getFileIcon(file.event_body?.filename)}
        </MinimalIconButtonWithToolTip>
        <Typography
          sx={{
            color: theme.palette.text.primary,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "95%",
            fontWeight: "semibold",
            cursor: "pointer",
            fontSize: "0.875rem",
          }}
          variant="body1"
          onClick={() => handleGenerateURL(file.event_body?.s3_key)}
        >
          {file.event_body?.filename}
        </Typography>
        {/* <MinimalIconButtonWithToolTip toolTipLabel={"Delete attachment"}>
          <Delete
            sx={{ color: theme.palette.text.secondary }}
            onClick={() => handleDeleteFile(file.id, file.event_body?.s3_key)}
          />
        </MinimalIconButtonWithToolTip>
        <LoaderIconButton
          loading={isDeleteFileLoading}
          error={isDeleteFileError}
        /> */}
      </Box>
    </Box>
  );
}

const getFileIcon = (fileName) => {
  if (!fileName) return <InsertDriveFileIcon />;

  const extension = fileName.split(".").pop().toLowerCase();

  switch (extension) {
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
    case "bmp":
    case "img":
      return <ImageIcon />;
    case "pdf":
      return <PictureAsPdfIcon />;
    case "doc":
    case "docx":
      return <DescriptionIcon />;
    case "xls":
    case "xlsx":
      return <TableChartIcon />;
    case "json":
      return <CodeIcon />;
    default:
      return <InsertDriveFileIcon />;
  }
};
