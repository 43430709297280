import { Box, Button } from "@mui/material";
import TextInput from "../component/SinglePropertyPage/common/TextInput";
import FilterLists from "./filterLists";
import SortLists from "./sortLists/SortLists";
import { useApplyAdvancedSearchMutation } from "./api-slices/multiPropertyViewApiSlice";
import { BuyBoxLists } from "./BuyBoxLists/BuyBoxLists";
import LoaderIconButton from "../component/button/LoaderIconButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateIsModalOpen,
  updateSearchLeadsResult,
  updateSearchVal,
} from "./redux-slices/searchLeadsSlice";
import {
  updateLoading,
  updateModalLeadsLoading,
} from "./redux-slices/leadsSlice";
import GroupByLists from "./groupBy/GroupByLists";

export default function FiltersHeader() {
  const [advancedSearch, { isLoading, isError, isSuccess }] =
    useApplyAdvancedSearchMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLoading(isLoading));
  }, [isLoading, dispatch]);

  const isSearchLeadsModalOpen = useSelector(
    (state) => state.search_leads.isModalOpen
  );

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
        py: "2px",
        mb: 1,
        width: "100%",
      }}
    >
      {!isSearchLeadsModalOpen && (
        <>
          <FilterLists
            applyAdvancedSearch={advancedSearch}
            isSuccess={isSuccess}
          />

          <SortLists
            applyAdvancedSearch={advancedSearch}
            isSuccess={isSuccess}
          />

          <BuyBoxLists
            applyAdvancedSearch={advancedSearch}
            isSuccess={isSuccess}
          />

          <GroupByLists applyAdvancedSearch={advancedSearch} />
        </>
      )}

      <SearchLeads
        applyAdvancedSearch={advancedSearch}
        isLoading={isLoading}
        isError={isError}
      />

      {isLoading && (
        <LoaderIconButton
          loading={isLoading}
          error={isError}
          key={"Loader-btn"}
        />
      )}
    </Box>
  );
}

const SearchLeads = () => {
  const dispatch = useDispatch();
  const [applyAdvancedSearch, { isLoading, isError }] =
    useApplyAdvancedSearchMutation();

  const [searchVal, setSearchVal] = useState("");

  const handleSearchLeadsVal = (e) => {
    setSearchVal(e.target.value);
  };

  useEffect(() => {
    dispatch(updateModalLeadsLoading(isLoading));
  }, [isLoading, dispatch]);

  const handleApplySearch = async () => {
    try {
      dispatch(updateSearchVal(searchVal));

      const res = await applyAdvancedSearch({
        data: {
          filters: [
            {
              filterBucket: "filter",
              filters: [
                {
                  field: "title",
                  query: searchVal,
                  matchType: "includes",
                  status: true,
                  filterType: "match",
                },
              ],
            },
          ],
        },
        queryParams: {
          page: 1,
          parpage: 100,
        },
      }).unwrap();

      dispatch(updateSearchLeadsResult(res.data));
      dispatch(updateIsModalOpen(true));
      setSearchVal("");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        width: "40%",
        display: "flex",
      }}
    >
      <TextInput
        inputValue={searchVal}
        onChangeHandler={handleSearchLeadsVal}
        textFieldProps={{
          placeholder: "search leads",
          size: "small",
        }}
        styles={{
          focus: "none",
          width: "100%",
          py: "1px",
          px: 1,
        }}
      />
      <Button
        variant="outlined"
        sx={{ textTransform: "none" }}
        onClick={handleApplySearch}
        disabled={searchVal === ""}
      >
        Search
      </Button>
      {(isLoading || isError) && (
        <LoaderIconButton loading={isLoading} error={isError} />
      )}
    </Box>
  );
};
