import {
  useEffect,
  useRef,
  useState,
  useMemo,
  useLayoutEffect,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBlocker } from "react-router-dom";
import {
  parseCreateRevPayload,
  useLazyGetLeadWithRelatedDataQuery,
  useLockLeadUpdateWsMutation,
  useReviseLeadMutation,
  useUnlockLeadUpdateWsMutation,
} from "../../lib/propertyDetailsApi";
import { SingleRecTblBase } from "./SingleRecTbl";
import AlertDialog from "../../common/dialog/AlertDialog";
import {
  getTblFieldsConf as getSingleRecTblConf,
  getRowsForTbl,
} from "../../component/SinglePropertyPage/common/muixDataGrid/table/singleRecordTable/utils";
import { EditLeadToolbar } from "../tableToolbar/EditLeadToolbar";
import _ from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { TBL_ALIAS, YMD_DATE_FORMAT } from "../../common/utils/constants";
import { socket } from "../../socket/socket";
import {
  selectCFCalcInputs,
  updateShouldUpdateTableAlias,
} from "../../store/reducers/singlePropPageReducer";
import {
  getIsSimpleField,
  getSingleRecTblColsWithOwrite,
} from "../../component/SinglePropertyPage/common/utils";
import {
  selectTableById,
  tableDataResetToInit,
  tableDataUpdated,
} from "../leadUnderwritingSlice";
import { useGetOwnUserAuthQuery } from "../../lib/userTableApi";
import { useCreateActivityMutation } from "../lib/activityTrackerApi";
import { recalcCFCalcMetrics } from "../leadUnderwritingApi";
import { getCFInputsFromPartialLeadRevision } from "../../common/utils/cashflowCalculation";
import { GridCellModes } from "@mui/x-data-grid";

dayjs.extend(utc);

const TABLE_ALIAS = TBL_ALIAS.lead;
const NO_OVERWRITE_KEYS = ["id", "property_id", "created_on", "updated_on"];
export const SHARED_MUTATION_KEYS = {
  reviseLead: "lead/details/reviseLead",
  lockLead: "lead/details/lockLead",
  unlockLead: "lead/details/unlockLead",
};
// const tblFieldsConf = getSingleRecTblConf(TABLE_ALIAS, fieldsConfig);

export function EditableSingleLeadTbl({
  leadWithRelData,
  updatePatchRef,
  snapshotRef,
  ownUser,
}) {
  const fieldsConfig = useSelector((state) => state.fieldsConfig.fieldsConfig);
  const tblFieldsConf = getSingleRecTblConf(TABLE_ALIAS, fieldsConfig);

  const [reviseLead] = useReviseLeadMutation({
    fixedCacheKey: SHARED_MUTATION_KEYS.reviseLead,
  });
  const [lockLead] = useLockLeadUpdateWsMutation({
    fixedCacheKey: SHARED_MUTATION_KEYS.lockLead,
  });
  const [unlockLead] = useUnlockLeadUpdateWsMutation({
    fixedCacheKey: SHARED_MUTATION_KEYS.unlockLead,
  });
  const [createActivity] = useCreateActivityMutation();
  const [getLeadWithRel, { isFetching: isFetchingGetLead }] =
    useLazyGetLeadWithRelatedDataQuery();
  const [triggerRecalcCF] = recalcCFCalcMetrics.useMutation();

  const inputParams = useSelector(selectCFCalcInputs);
  const dispatch = useDispatch();
  const {
    isWorking: isEditing,
    localVersion,
    savedVersion,
  } = useSelector((state) => selectTableById(state, TABLE_ALIAS));

  const [isSockConnected, setIsSockConnected] = useState(socket.connected);
  const stopEditRef = useRef(null);
  const isUnsavedChanges = savedVersion < localVersion;
  // const rows = useMemo(() => {
  //   return getRowsForTbl(tblFieldsConf, getMergedRecForLead(leadWithRelData));
  // }, [leadWithRelData]);
  const initialRows = useMemo(
    () => getRowsForTbl(tblFieldsConf, getMergedRecForLead(leadWithRelData)),
    [leadWithRelData]
  );
  const isAllowAutoSave = useRef(false);
  const [rows, setRows] = useState(initialRows);
  const [cellModesModel, setCellModesModel] = useState({});

  const cols = useMemo(() => {
    const { orm__rel_lead_overwrite: leadOverwrite } =
      leadWithRelData.orm__rel_property;
    return getSingleRecTblColsWithOwrite(leadWithRelData, leadOverwrite);
  }, [leadWithRelData]);

  const { id, property_id } = leadWithRelData;

  const processRowUpdate = (newRow, oldRow) => {
    isAllowAutoSave.current = true;
    const isSimple = getIsSimpleField(newRow);
    if (isSimple && oldRow.value === newRow.value) return oldRow;

    updatePatchRef.current[TABLE_ALIAS][newRow.api_key] = {
      value: newRow.value,
      previousValue: oldRow.value || null,
      updatedOn: dayjs.utc().format(YMD_DATE_FORMAT),
      version: localVersion + 1,
    };
    updatePatchRef.current[TABLE_ALIAS]._version = localVersion + 1;
    dispatch(sendLeadTableUpdate({ localVersion: localVersion + 1 }));

    return newRow;
  };

  const handleEdit = async () => {
    try {
      if (isEditing) return;
      await lockLead({ property_id }).unwrap();
      await getLeadWithRel({ id }).unwrap();
      dispatch(sendLeadTableUpdate({ isWorking: true }));
    } catch {}
  };

  const handleSave = async (rowsToUpdate, lastSavedVer = -1, localVersion) => {
    const batch = getUpdateRowBatch(rowsToUpdate, lastSavedVer);
    if (Object.keys(batch).length <= 0) return;
    try {
      await reviseLead({
        id,
        property_id,
        revData: batch,
      }).unwrap();
      await createActivity({
        userId: ownUser.userId,
        id,
        table_alias: TABLE_ALIAS,
        eventType: "update",
        property_id,
        revData: parseCreateRevPayload(batch),
        queryArgs: {
          id,
          propertyId: property_id,
          limit: 10,
          table_alias: TABLE_ALIAS,
        },
      }).unwrap();
      dispatch(
        sendLeadTableUpdate({
          savedVersion: localVersion,
        })
      );
    } catch {}
  };
  // const handleSave = async (rowsToUpdate, lastSavedVer = -1) => {};

  const throttledSaveRef = useRef(
    _.throttle(handleSave, 6000, { leading: false, trailing: true })
  );

  const handleManualSave = async () => {
    throttledSaveRef.current.cancel();
    const patchRec = updatePatchRef.current[TABLE_ALIAS];
    await handleSave(patchRec, savedVersion, localVersion);
  };

  const handleStopEdit = async () => {
    dispatch(sendLeadTableUpdate({ isWorking: false }));
    const updateRec = updatePatchRef.current[TABLE_ALIAS];
    throttledSaveRef.current.cancel();
    try {
      await unlockLead({
        id,
        property_id,
      }).unwrap();

      const newInputParams = getCFInputsFromPartialLeadRevision(updateRec);
      if (Object.keys(newInputParams).length >= 1) {
        await triggerRecalcCF({
          leadId: id,
          propertyId: property_id,
          calcInputParams: {
            ...inputParams,
            ...newInputParams,
          },
        }).unwrap();
      }
      updatePatchRef.current[TABLE_ALIAS] = { _version: 0 };
      dispatch(tableDataResetToInit({ id: TABLE_ALIAS, changes: {} }));
    } catch {
      dispatch(sendLeadTableUpdate({ isWorking: true }));
    }
  };

  useLayoutEffect(() => {
    if (isAllowAutoSave.current) return;
    const updateRec = updatePatchRef.current[TABLE_ALIAS];
    if (Object.keys(updateRec).length < 1) return;
    setRows(
      initialRows.map((r) =>
        !updateRec[r.api_key]
          ? r
          : { ...r, value: updateRec[r.api_key]["value"] }
      )
    );
  }, [updatePatchRef, initialRows]);

  useEffect(() => {
    if (!isUnsavedChanges || !isAllowAutoSave.current) {
      return;
    }
    const patchRec = updatePatchRef.current[TABLE_ALIAS];
    throttledSaveRef.current(patchRec, savedVersion, localVersion);
  }, [savedVersion, localVersion, isUnsavedChanges, updatePatchRef]);

  /** socket related */
  useEffect(() => {
    function setSockStatus() {
      setIsSockConnected(socket.connected);
    }
    socket.on("connect", setSockStatus);
    socket.on("disconnect", setSockStatus);

    return () => {
      socket.off("connect", setSockStatus);
      socket.off("disconnect", setSockStatus);
    };
  }, []);

  useEffect(() => {
    async function onConnect() {
      if (stopEditRef.current) {
        try {
          await lockLead({ property_id }).unwrap();
        } catch {}
      }
    }

    socket.on("connect", onConnect);
    return () => {
      socket.off("connect", onConnect);
    };
  }, [property_id, lockLead]);

  /**  blocker related */
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isUnsavedChanges]);

  // useEffect(() => {
  //   if (isUnsavedChanges) {
  //     dispatch(updateShouldUpdateTableAlias(false));
  //   } else {
  //     dispatch(updateShouldUpdateTableAlias(true));
  //   }
  // }, [isUnsavedChanges, dispatch]);

  // useEffect(() => {
  //   updatePatchRef.current[TABLE_ALIAS] = { _version: 0 };
  //   dispatch(tableDataResetToInit({ id: TABLE_ALIAS, changes: {} }));
  // }, [dispatch, updatePatchRef]);

  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (
      event.target.nodeType === 1 &&
      !event.currentTarget.contains(event.target)
    ) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  return (
    <>
      <EditLeadToolbar
        {...{
          isUnsavedChanges,
          handleManualSave,
          handleEdit,
          handleStopEdit,
          stopEditRef,
          isSockConnected,
          isFetchingGetLead,
        }}
      />
      <SingleRecTblBase
        rows={rows}
        cols={cols}
        tblProps={{
          isCellEditable: (params) =>
            isEditing && params.row.table_config?.is_editable === true,
          processRowUpdate,
          onProcessRowUpdateError: handleProcessRowUpdateError,
          cellModesModel: cellModesModel,
          onCellModesModelChange: handleCellModesModelChange,
          onCellClick: handleCellClick,
        }}
      />
      <ConfirmDiscardDialog
        isUnsavedChanges={isUnsavedChanges}
        handleDialogDiscard={() => {
          updatePatchRef.current[TABLE_ALIAS] = { _version: 0 };
          dispatch(tableDataResetToInit({ id: TABLE_ALIAS, changes: {} }));
        }}
      />
    </>
  );
}

function ConfirmDiscardDialog({
  isUnsavedChanges,
  handleDialogDiscard,
  handleDialogSave,
}) {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isUnsavedChanges && currentLocation?.pathname !== nextLocation?.pathname
  );
  return (
    <AlertDialog
      open={blocker.state === "blocked"}
      handleDialogDiscard={() => {
        handleDialogDiscard();
        blocker.proceed();
      }}
      handleDialogSave={() => blocker.reset()}
      title="Discard Unsaved Changes?"
      message="Changes you made may not be saved, are you sure you want to discard changes and leave?"
    />
  );
}

function getMergedRecForLead(leadWithRelData) {
  const { orm__rel_lead_overwrite: leadOverwrite } =
    leadWithRelData.orm__rel_property;

  if (!leadOverwrite?.id) {
    return leadWithRelData;
  }
  return {
    ...leadWithRelData,
    ...leadOverwrite,
    ...Object.fromEntries(
      NO_OVERWRITE_KEYS.map((key) => [key, leadWithRelData[key]])
    ),
  };
}

function getUpdateRowBatch(rowsToUpdate, lastSavedVer = -1) {
  const batch = {};
  for (const field in rowsToUpdate) {
    const fieldBody = rowsToUpdate[field];
    if (field !== "_version" && fieldBody.version > lastSavedVer) {
      batch[field] = fieldBody;
    }
  }
  return batch;
}

function handleProcessRowUpdateError(error) {
  console.error(error.message);
}

function sendLeadTableUpdate(changes) {
  return tableDataUpdated({
    id: TABLE_ALIAS,
    changes,
  });
}
