import { useState } from "react";
import { Skeleton } from "@mui/material";

function ImgWithLoading({ src, alt, style, ...imgProps }) {
  const [isLoading, setIsLoading] = useState(true);

  const getImg = () => (
    <img
      src={src}
      alt={alt}
      style={{ ...style, ...{ display: isLoading ? "none" : "block" } }}
      onLoad={() => setIsLoading(false)}
      {...imgProps}
    />
  );

  return (
    <>
      {getImg()}
      {isLoading && (
        <Skeleton
          variant="rounded"
          width={style?.width}
          height={style?.height}
        />
      )}
    </>
  );
}

export default ImgWithLoading;
