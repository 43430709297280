import EmptyUIComponent from "./EmptyFilters";
import RangeFilter from "./RangeFilter";
import CategoryFilter from "./CategoryFilter";
import BooleanFilter from "./BooleanFIlter";
import { useSelector } from "react-redux";
import { selectAllFilters } from "../redux-slices/advancedSearchSlice";
import DateFilter from "./DateFilter/DateFilter";

function ViewSingleFilter({ filter }) {
  const appliedFilters = useSelector(selectAllFilters);

  if (!filter && appliedFilters.length === 0) {
    return (
      <EmptyUIComponent
        message={"No applied filters, select a filter to apply one!"}
      />
    );
  }

  const { multi_lead_table } = filter;

  switch (multi_lead_table.filter_type) {
    case "boolean":
      return <BooleanFilter filter={filter} />;
    case "range":
      return <RangeFilter filter={filter} />;
    case "datetime" || "relative_datetime":
      return <DateFilter filter={filter} />;
    case "category":
      return <CategoryFilter filter={filter} />;
    default:
      return (
        <EmptyUIComponent
          message={"No applied filters, select a filter to apply one!"}
        />
      );
  }
}

export default ViewSingleFilter;
