import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DifferenceIcon from "@mui/icons-material/Difference";
import MinimalIconButtonWithToolTip from "./MinimalIconButtonWithTooltip";

export function DefaultContainedBtn({ children, ...buttonProps }) {
  return (
    <Button variant="contained" disableElevation disableRipple {...buttonProps}>
      {children}
    </Button>
  );
}

export function DefaultTextBtn({ children, ...buttonProps }) {
  return (
    <Button variant="text" disableRipple {...buttonProps}>
      {children}
    </Button>
  );
}

export function DefaultOutLinedBtn({ children, ...buttonProps }) {
  return (
    <Button variant="outlined" disableRipple {...buttonProps}>
      {children}
    </Button>
  );
}

export function MinIconCloseBtn({ ...props }) {
  return (
    <MinimalIconButtonWithToolTip toolTipLabel={"Close"} {...props}>
      <CloseIcon />
    </MinimalIconButtonWithToolTip>
  );
}
export function MinIconCompareBtn({ ...props }) {
  return (
    <MinimalIconButtonWithToolTip
      toolTipLabel={"Compare with duplicate leads"}
      {...props}
    >
      <DifferenceIcon />
    </MinimalIconButtonWithToolTip>
  );
}
