import { createSlice } from "@reduxjs/toolkit";
import {
  getLatestCalcData,
  getLeadWithRelatedData,
} from "../../lib/propertyDetailsApi";
import {
  INITIAL_CALC_ASSUMPTIONS,
  fallbacks,
  getCashFlowCalcInputsFromLatestCalcApi,
  getCashFlowInputsFromLeadApi,
} from "../../common/utils/cashflowCalculation";
import _ from "lodash";

/** NOTE: we can add cash flow array and calculation like so
const INITIAL_CASH_FLOW_YEARLY = cashFlowCalculator(
  fallbacks,
  INITIAL_CALC_ASSUMPTIONS
);
const INITIAL_CALC_OUTPUT = {
  leveredIRR: calcIRR(INITIAL_CASH_FLOW_YEARLY.leveredCF),
  unleveredIRR: calcIRR(INITIAL_CASH_FLOW_YEARLY.unleveredCF),
  capRate: calcCapRate(INITIAL_CASH_FLOW_YEARLY),
  grossYield: calcGrossYield(fallbacks.rent, fallbacks.purchasePrice),
};
 */

const initialState = {
  assumptions: INITIAL_CALC_ASSUMPTIONS,
  table_alias: "lead",
  should_update_table_alias: true,

  cashFlowCalcInputs: fallbacks,
  revisionModalState: {},
};

export const singlepropertyPageSlice = createSlice({
  name: "singlepropertyPage",
  initialState,
  reducers: {
    updateAssumption: (state, action) => {
      const { key, value } = action.payload;
      state.assumptions[key] = value;
    },
    resetAssumptions: (state) => {
      state.assumptions = initialState.assumptions;
    },
    updateTableAlias: (state, action) => {
      state.table_alias = action.payload;
    },
    updateShouldUpdateTableAlias: (state, action) => {
      state.should_update_table_alias = action.payload;
    },
    updateRevisionModalState: (state, action) => {
      state.revisionModalState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      getLatestCalcData.matchFulfilled,
      (state, { payload }) => {
        const { data: calcApiData = {} } = payload;
        const newInputs = getCashFlowCalcInputsFromLatestCalcApi(calcApiData);
        return gotNewCalcInputs(state, { payload: newInputs });
      }
    );
    builder.addMatcher(
      getLeadWithRelatedData.matchFulfilled,
      (state, { payload: leadApiData }) => {
        const newInputs = getCashFlowInputsFromLeadApi(leadApiData);
        return gotNewCalcInputs(state, { payload: newInputs });
      }
    );
  },
});

export const {
  updateAssumption,
  resetAssumptions,
  updateTableAlias,
  updateRevisionModalState,
  updateShouldUpdateTableAlias,
} = singlepropertyPageSlice.actions;
export default singlepropertyPageSlice.reducer;

export const selectCFCalcInputs = (state) =>
  state[singlepropertyPageSlice.reducerPath].cashFlowCalcInputs;

export const selectCashFlowAssumptions = (state) =>
  state[singlepropertyPageSlice.reducerPath].assumptions;

export const selectShouldUpdateTableAlias = (state) =>
  state[singlepropertyPageSlice.reducerPath].should_update_table_alias;

export const selectRevisionModalState = (state) =>
  state[singlepropertyPageSlice.reducerPath].revisionModalState;

function gotNewCalcInputs(state, { payload: newInputs }) {
  const calcInputs = { ...state.cashFlowCalcInputs, ...newInputs };
  return {
    ...state,
    cashFlowCalcInputs: calcInputs,
  };
}
