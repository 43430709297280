import React from "react";
import { Button } from "@mui/material";

const buttonStyles = {
  textTransform: "none",
  padding: 0,
  minWidth: "auto", // Ensure the button doesn't have a minimum width
  lineHeight: "normal", // Match line height to content
  "&:hover": {
    backgroundColor: "transparent",
  },
};

const TextButton = ({ children, otherStyles, ...otherProps }) => {
  return (
    <Button
      variant="text"
      disableRipple
      sx={{ ...buttonStyles, ...otherStyles }}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export default TextButton;
