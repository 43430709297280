import { Box } from "@mui/material";
import RangeFilter from "./RangeFilter";
import BooleanFilter from "./BooleanFIlter";
import CategoryFilter from "./CategoryFilter";
import DateFilter from "./DateFilter/DateFilter";

function ViewAppliedFilters({ filtersList, appliedFiltersList }) {
  const appliedFiltersToShow = filtersList.filter((filter) =>
    appliedFiltersList.some(
      (appliedFilter) => filter.api_key === appliedFilter.field
    )
  );

  return (
    <Box sx={{ height: "100%", overflowY: "scroll", pb: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {appliedFiltersToShow.map((filter, index) => (
          <Box key={index}>{appliedFilterRenderer({ filter })}</Box>
        ))}
      </Box>
    </Box>
  );
}

export default ViewAppliedFilters;

function appliedFilterRenderer({ filter }) {
  const { multi_lead_table } = filter;

  switch (multi_lead_table.filter_type) {
    case "range":
      return <RangeFilter filter={filter} readonly={true} />;
    case "datetime" || "relative_datetime":
      return <DateFilter filter={filter} readonly={true} />;
    case "boolean":
      return <BooleanFilter filter={filter} readonly={true} />;
    case "category":
      return <CategoryFilter filter={filter} readonly={true} />;
    default:
      return null;
  }
}
