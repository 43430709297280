import { getColTypeForDtype, showValue } from "../../table";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  D_MDY_HMS_A_DATETIME,
  DEFAULT_TIMEZONE,
  YMD_DATE_FORMAT,
} from "../../../../../../common/utils/constants";

dayjs.extend(utc);
dayjs.extend(timezone);
const DTYPE_BLACKLIST = ["lists", "special"];

export function getTblFieldsConf(tableAlias, fieldsConfig) {
  return fieldsConfig.filter(
    (field) =>
      field.table_alias === tableAlias &&
      field.is_in_prop_lead_details === true &&
      !DTYPE_BLACKLIST.includes(field.dtype)
  );
}

/** TODO: value -> display_name and handle booolean etc.
 *  Hint: can use function in valueoptions that takes rows! (https://mui.com/x/react-data-grid/column-definition/)
 *
 *
 */
export function getColsForTbl(tblFieldsConf) {
  return tblFieldsConf.map(
    ({ api_key, display_name, dtype, category_values }, _) => {
      const options = Array.isArray(category_values)
        ? category_values.map((v) => v.value)
        : [];
      const colType = getColTypeForDtype(dtype);

      return {
        field: api_key,
        headerName: display_name,
        type: colType,
        editable: false,
        minWidth: 150,
        maxWidth: 350,
        getValueOptions: options,
        valueGetter: (value) => {
          if (!["date", "datetime"].includes(dtype)) {
            if (dtype === "boolean") {
              return value === 1
                ? "Yes"
                : value === 0
                  ? "No"
                  : showValue(value);
            }
            return value;
          }
          return dayjs(value).isValid()
            ? dayjs(value).tz(DEFAULT_TIMEZONE).format(D_MDY_HMS_A_DATETIME)
            : null;
        },
        renderCell: (params) => showValue(params.value),
      };
    }
  );
}
