import { propLeadApiSlice } from "../../lib/propertyDetailsApi";

const BASE_URL_SUFFIX = "/rightsignature";

const sendOfferAPI = propLeadApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendOffer: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL_SUFFIX}/send-doc-for-sign`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const { useSendOfferMutation } = sendOfferAPI;
