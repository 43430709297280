import { Box, useTheme } from "@mui/material";
import TextInput from "../component/SinglePropertyPage/common/TextInput";
import CloseIcon from "@mui/icons-material/Close";

export function SearchDialog({
  searchVal,
  handleSearch,
  handleRemove,
  entity,
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        py: "2px",
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
      <TextInput
        inputValue={searchVal}
        onChangeHandler={handleSearch}
        textFieldProps={{
          placeholder: `search ${entity}`,
          size: "small",
        }}
        styles={{
          focus: "none",
          width: "100%",
          py: "2px",
          px: "4px",
        }}
        inputProps={{
          style: {
            height: "15px",
          },
        }}
      />
      {searchVal && (
        <CloseIcon
          onClick={handleRemove}
          fontSize="small"
          sx={{
            color: theme.palette.text.secondary,
            position: "absolute",
            right: "20px",
            bottom: "10px",
            cursor: "pointer",
          }}
        />
      )}
    </Box>
  );
}
