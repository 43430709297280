import {
  DefaultContainedBtn,
  DefaultTextBtn,
} from "../../../../button/commonButtons";

export function AddNewBtn({ ...props }) {
  return (
    <DefaultContainedBtn size="small" {...props}>
      {"Add new +"}
    </DefaultContainedBtn>
  );
}

export function EditBtn({ otherstyles, ...props }) {
  // const theme = useTheme();
  return (
    <DefaultContainedBtn
      size="small"
      {...props}
      // sx={{
      //   // fontSize: "14px",
      //   // backgroundColor: "transparent",
      //   // color: theme.palette.text.secondary,
      //   // border: `1px solid ${theme.palette.divider}`,
      //   // textTransform: "capitalize",
      //   // padding: 0.1,
      //   // mx: 0.5,
      //   // "&:hover": {
      //   //   backgroundColor: "transparent",
      //   // },
      // }}
    >
      {"Edit"}
    </DefaultContainedBtn>
  );
}

export function SaveEditsBtn({ ...props }) {
  // const theme = useTheme();

  return (
    <DefaultContainedBtn
      size="small"
      {...props}
      // sx={{
      //   fontSize: "14px",
      //   backgroundColor: theme.palette.primary.main,
      //   color: theme.palette.common.white,
      //   textTransform: "capitalize",
      //   padding: 0.3,
      //   mx: 0.5,
      //   "&:hover": {
      //     backgroundColor: theme.palette.primary.main,
      //   },
      // }}
    >
      {"Save"}
    </DefaultContainedBtn>
  );
}

export function CancelEditsBtn({ ...props }) {
  // const theme = useTheme();

  return (
    <DefaultTextBtn
      size="small"
      {...props}
      // sx={{
      //   fontSize: "14px",
      //   backgroundColor: "transparent",
      //   color: theme.palette.text.secondary,
      //   border: `1px solid ${theme.palette.divider}`,
      //   textTransform: "capitalize",
      //   padding: 0.3,
      //   mx: 0.5,
      //   "&:hover": {
      //     backgroundColor: "transparent",
      //   },
      // }}
    >
      {"Stop Editing"}
    </DefaultTextBtn>
  );
}
