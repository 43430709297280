import { createSlice } from "@reduxjs/toolkit";
import { getFieldsConfig } from "../api-slices/authApiSlice";

const initialState = {
  fieldsConfig: [],
};

const fieldConfigSlice = createSlice({
  name: "fieldsConfig",
  initialState,
  reducers: {
    updateFieldsConfig: (state, action) => {
      state.fieldsConfig = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getFieldsConfig.matchFulfilled, (state, action) => {
      const { api_key_map } = action.payload;
      state.fieldsConfig = api_key_map;
    });
  },
});

export const { updateFieldsConfig } = fieldConfigSlice.actions;

export default fieldConfigSlice.reducer;
