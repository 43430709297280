import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import FiltersHeader from "../FiltersHeader";
import { Box, useTheme } from "@mui/material";

export default function LeadsTableToolbar() {
  const theme = useTheme();

  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderBottom: `1px solid ${theme.palette.divider}`,
        px: 1,
      }}
    >
      <Box sx={{ display: "flex", flex: 1, gap: "10px", alignItems: "center" }}>
        <GridToolbarColumnsButton
          slotProps={{
            button: {
              variant: "outlined",
              sx: {
                px: "15px",
                py: "6px",
                mb: "8px",
                textTransform: "capitalize",
              },
            },
          }}
        />
        <FiltersHeader />
      </Box>

      <GridToolbarExport
        slotProps={{
          tooltip: { title: "Export data" },
          button: { variant: "outlined" },
        }}
      />
    </GridToolbarContainer>
  );
}
