import { Box, Skeleton } from "@mui/material";

function LeadImagesSkeleton({ ctnStyle }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        height: "100%",
        alignItems: "center",
        minWidth: "380px",
        ...ctnStyle,
      }}
    >
      <Skeleton variant="rounded" height="75%" width="100%" />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          overflow: "hidden",
          flexGrow: 0,
          gap: "10px",
        }}
      >
        {[...Array(3)].map((_, i) => (
          <Skeleton key={i} variant="rounded" height="120px" width="30%" />
        ))}
      </Box>
    </Box>
  );
}

export default LeadImagesSkeleton;
