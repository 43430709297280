// Helper functions that are used primarily in single property page

import { Box } from "@mui/material";
import MinimalIconButtonWithToolTip from "../../button/MinimalIconButtonWithTooltip";
import { InfoOutlined } from "@mui/icons-material";
import { singleRecTblCols } from "./muixDataGrid/table/singleRecordTable/utils";

export function getIsLeadOverwriteExist(lead) {
  return Boolean(lead?.orm__rel_property?.orm__rel_lead_overwrite?.id);
}

export function getLeadField(leadData, key, isLeadOverwrite = true) {
  if (isLeadOverwrite === true) {
    return leadData?.orm__rel_property?.orm__rel_lead_overwrite?.[key] ===
      undefined
      ? leadData[key]
      : leadData.orm__rel_property?.orm__rel_lead_overwrite?.[key];
  }
  return leadData?.[key];
}

export function boolIntToYesNo(
  data,
  translation = { 1: "Yes", 0: "No" },
  restRep = undefined
) {
  const translate = translation[data];
  if (translate === undefined) return restRep;
  return translate;
}

export function isMissingLike(
  value,
  options = {
    exclude: [0],
  }
) {
  if (options.exclude.includes(value)) {
    return false;
  }
  return [null, NaN, undefined, ""].includes(value) || !value;
}

export function getColsForSingleRecTbl(
  isEditSingleRecTable = false,
  leadWithRelData = null
) {
  // Create a shallow copy of singleRecTblCols to avoid mutating the original array
  const columns = [...singleRecTblCols];

  const displayNameColumn = columns.find((col) => col.field === "display_name");

  if (isEditSingleRecTable && displayNameColumn) {
    displayNameColumn.renderCell = (params) => {
      return getDisplayNameWithComparison(params, leadWithRelData);
    };
  }

  return columns;
}

export function getDisplayNameWithComparison(params, leadWithRelData) {
  const { value, row } = params;
  const { api_key } = row;
  const leadValue = leadWithRelData[api_key];
  const overWriteValue =
    leadWithRelData?.orm__rel_property.orm__rel_lead_overwrite?.[api_key];

  // Skip comparison for 'links' and 'multiline' text types
  const shouldSkipComparison =
    row?.dtype === "links" || row.formatting?.type === "text_multiline";

  // Conditions to return value
  if (overWriteValue === undefined) {
    return;
  } else if (
    leadValue !== overWriteValue &&
    overWriteValue !== undefined &&
    !shouldSkipComparison
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
        {value}
        <MinimalIconButtonWithToolTip toolTipLabel={leadValue}>
          <InfoOutlined />
        </MinimalIconButtonWithToolTip>
      </Box>
    );
  } else {
    return value; // Default return for other cases
  }
}

/** Generic function, takes main `record` and an overwrite `owriteRec` record */
export function getSingleRecTblColsWithOwrite(record, owriteRec) {
  return [
    {
      ...singleRecTblCols[0],
      renderCell: (params) => {
        const { row, value: fieldName } = params;
        const { api_key } = row;
        const mainVal = record[api_key];

        if (!owriteRec) return fieldName;
        const isComparable = getIsSimpleField(row);
        if (!isComparable) return fieldName;

        const owriteVal = owriteRec[api_key];
        if (owriteVal === undefined) return fieldName;

        let tooltipLabel = mainVal;

        if (row?.dtype === "boolean") {
          tooltipLabel = mainVal === 1 ? "Yes" : mainVal === 0 ? "No" : mainVal;
        }

        if (owriteVal !== mainVal) {
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              {fieldName}
              <MinimalIconButtonWithToolTip toolTipLabel={tooltipLabel}>
                <InfoOutlined />
              </MinimalIconButtonWithToolTip>
            </Box>
          );
        }
        return fieldName;
      },
    },
    singleRecTblCols[1],
  ];
}

export function getIsSimpleField({ dtype, formatting }) {
  // NOTE: explicitly enable, so we do not accidently enable comparison for a new dtype
  switch (dtype) {
    case "float":
    case "int":
    case "decimal":
    case "category":
    case "boolean":
    case "date":
    case "datetime":
      return true;

    case "string":
      if (formatting?.type === "text_multiline") return false;
      return true;

    default:
      return false;
  }
}
