import { isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getErrorMsgTitle } from "../common/utils/miscUtils";
import { DEF_TOAST_ID } from "../common/utils/constants";

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { message } = getErrorMsgTitle(action?.payload || action?.error);

    toast.error(message, {
      toastId: DEF_TOAST_ID,
    });
  }

  return next(action);
};
