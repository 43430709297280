import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useGridApiContext } from "@mui/x-data-grid";
import { InputBase } from "@mui/material";
import { ErrorTooltip } from "../../../minorUxComponents/Tooltips";
import { useTheme } from "@mui/material/styles";
import {
  marginOffsetSx,
  zeroBorderRadius,
} from "../../../../common/muiStyles/tooltip";

const zeroOffsetSx = marginOffsetSx(0);
const errTooltipSx = {
  ...zeroOffsetSx,
  ...zeroBorderRadius,
};

const getInputMode = (dtype) => {
  switch (dtype) {
    case "float":
    case "decimal":
      return "decimal";
    case "int":
      return "number";
    default:
      return "text";
  }
};

function EditTextCellRenderer(props) {
  const { id, row, value: valueProp, field, hasFocus, error } = props;
  const [value, setValue] = useState(valueProp);
  const apiRef = useGridApiContext();
  const ref = useRef();
  const theme = useTheme();

  const { dtype = "string", formatting } = row;
  const { missing_value_rep: missingValueRep = null } = row.validation;
  const formatType = formatting?.type;

  const inputMode =
    dtype === "float" || dtype === "decimal"
      ? "decimal"
      : dtype === "int"
      ? "number"
      : "text";

  useLayoutEffect(() => {
    if (hasFocus && ref.current) {
      ref.current.focus();
    }
  }, [hasFocus]);
  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleValueChange = (event) => {
    const newVal = ["int", "float"].includes(dtype)
      ? parseFloat(event.target.value)
      : event.target.value;
    if (apiRef.current?.setEditCellValue) {
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newVal || missingValueRep,
        debounceMs: 300,
      });
    }

    setValue(newVal);
  };

  const isMultiLine = formatType === "text_multiline";

  return (
    <ErrorTooltip
      title={!!error ? error : ""}
      enterDelay={500}
      leaveDelay={10}
      open={!!error}
      placement="top-start"
      sx={errTooltipSx}
    >
      <InputBase
        value={value || ""}
        multiline={isMultiLine}
        maxRows={isMultiLine ? 6 : 1}
        minRows={isMultiLine ? 3 : 1}
        onChange={handleValueChange}
        autoFocus
        fullWidth
        ref={ref}
        error={!!error}
        slotProps={{
          input: {
            inputMode: inputMode,
            style: { color: !!error ? theme.palette.error.main : undefined },
          },
        }}
      />
    </ErrorTooltip>
  );
}

function isNumericalDtype(dtype) {
  switch (dtype) {
    case "float":
    case "decimal":
    case "int":
      return true;
    default:
      return false;
  }
}

export default EditTextCellRenderer;
