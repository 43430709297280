import { useGridApiContext } from "@mui/x-data-grid";
import { MenuItem, Select } from "@mui/material";
import { useLayoutEffect, useRef } from "react";
import { isMissingLike } from "./table/common/util";

function EditBooleanRenderer({ id, row, value, field, hasFocus, readOnly }) {
  const apiRef = useGridApiContext();
  const ref = useRef();
  const { missing_value_rep: missingValueRep = null } = row.validation;

  const options = ["Yes", "No"];

  useLayoutEffect(() => {
    if (hasFocus && ref.current) {
      ref.current.focus();
    }
  }, [hasFocus]);

  const handleValueChange = (event) => {
    const newVal = event.target.value;
    if (!readOnly && apiRef.current?.setEditCellValue) {
      apiRef.current.setEditCellValue({
        id,
        field,
        value: isMissingLike(newVal) ? missingValueRep : newVal,
      });
    }
  };

  return (
    <Select
      value={value === 1 ? 1 : value === 0 ? 0 : ""}
      onChange={handleValueChange}
      displayEmpty
      size="small"
      inputProps={{ readOnly }}
    >
      <MenuItem value="">
        <em>--</em>
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option} value={option === "Yes" ? 1 : 0}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

export default EditBooleanRenderer;
