import dayjs from "dayjs";

const timeAgo = (timestampStr) => {
  const timestamp = Number(timestampStr);
  const now = Date.now();
  const diffInSeconds = Math.floor((now - timestamp) / 1000);

  const timestampDate = new Date(timestamp);
  const currentYear = new Date(now).getFullYear();
  const timestampYear = timestampDate.getFullYear();

  // If the year is different, return the actual date
  if (currentYear !== timestampYear) {
    return dayjs(timestampDate).format("DD MMM YYYY"); // Format: MM/DD/YYYY (or based on locale)
  }

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 2592000) {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else {
    const months = Math.floor(diffInSeconds / 2592000);
    return `${months} month${months > 1 ? "s" : ""} ago`;
  }
};

export default timeAgo;
