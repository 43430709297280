import TextInput from "../../component/SinglePropertyPage/common/TextInput";
import { Box, Typography, useTheme } from "@mui/material";
import {
  removeFilter,
  selectFilterById,
  upsertFilter,
} from "../redux-slices/advancedSearchSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import MinimalIconButtonWithToolTip from "../../component/button/MinimalIconButtonWithTooltip";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ToggleSwitch } from "./CategoryFilter";

function RangeFilter({ filter, readonly = false }) {
  const filterState =
    useSelector((state) => selectFilterById(state, filter["api_key"])) || {};

  const toRange = filterState.max
    ? filterState.max
    : filterState.max === 0
      ? 0
      : null;

  const fromRange = filterState.min
    ? filterState.min
    : filterState.min === 0
      ? 0
      : null;

  const theme = useTheme();
  const dispatch = useDispatch();

  const handleFromRangeChange = (e) => {
    const value = e.target.value;
    dispatch(
      upsertFilter({
        field: filter["api_key"],
        filterType: "range",
        min: value === "" ? null : value,
        max: toRange,
        includeUnset: filterState?.includeUnset
          ? filterState.includeUnset
          : false,
        negate: filterState?.negate ? filterState.negate : false,
      })
    );
  };

  const handleToRangeChange = (e) => {
    const value = e.target.value;

    dispatch(
      upsertFilter({
        field: filter["api_key"],
        filterType: "range",
        min: fromRange,
        max: value === "" ? null : value,
        includeUnset: filterState?.includeUnset
          ? filterState.includeUnset
          : false,
        negate: filterState?.negate ? filterState.negate : false,
      })
    );
  };

  // TODO: Need to find an alternative for this
  useEffect(() => {
    if (
      fromRange === null &&
      toRange === null &&
      filterState.includeUnset === false &&
      filterState.negate === false
    ) {
      dispatch(removeFilter({ field: filter["api_key"] }));
    }
  }, [filter, fromRange, toRange, dispatch, filterState]);

  const handleBlur = (type) => {
    if (type === "min") {
      dispatch(
        upsertFilter({
          field: filter["api_key"],
          filterType: "range",
          min: fromRange === "" ? null : parseFloat(fromRange),
          max: toRange,
          includeUnset: filterState?.includeUnset
            ? filterState.includeUnset
            : false,
          negate: filterState?.negate ? filterState.negate : false,
        })
      );
    } else if (type === "max") {
      dispatch(
        upsertFilter({
          field: filter["api_key"],
          filterType: "range",
          min: fromRange,
          max: toRange === "" ? null : parseFloat(toRange),
          includeUnset: filterState?.includeUnset
            ? filterState.includeUnset
            : false,
          negate: filterState?.negate ? filterState.negate : false,
        })
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: `1px solid ${theme.palette.divider}`,
          borderBottom: `1px solid ${theme.palette.divider}`,
          px: 1,
          py: "10px",
        }}
      >
        <Typography variant="body2">
          {filter.display_name || filter.api_key}
        </Typography>

        {readonly && (
          <MinimalIconButtonWithToolTip
            toolTipLabel={"Remove filter"}
            onClick={() => dispatch(removeFilter({ field: filter["api_key"] }))}
          >
            <DeleteOutlineIcon />
          </MinimalIconButtonWithToolTip>
        )}
      </Box>

      <Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mt: "20px",
            alignItems: "center",
            px: 1,
          }}
        >
          <Box>
            <Typography variant="body2" sx={{ mb: 1 }}>
              From
            </Typography>
            <TextInput
              inputValue={fromRange ? fromRange : ""}
              onBlur={() => handleBlur("min")}
              onChangeHandler={handleFromRangeChange}
              textFieldProps={{
                placeholder: "Select a value",
                disabled: readonly,
                type: readonly ? "text" : "number",
              }}
              inputProps={{
                style: {
                  padding: "8px",
                },
              }}
            />
          </Box>
          <Box>
            <Typography variant="body2" sx={{ mb: 1 }}>
              To
            </Typography>
            <TextInput
              inputValue={toRange ? toRange : ""}
              onBlur={() => handleBlur("max")}
              onChangeHandler={handleToRangeChange}
              textFieldProps={{
                placeholder: "Select a value",
                disabled: readonly,
                type: readonly ? "text" : "number",
              }}
              inputProps={{
                style: {
                  padding: "8px",
                },
              }}
            />
          </Box>
        </Box>

        <ToggleSwitch
          dispatch={dispatch}
          filterState={filterState}
          filter={filter}
          readonly={readonly}
          valuesToApply={{ min: fromRange, max: toRange }}
        />
      </Box>
    </>
  );
}

export default RangeFilter;
