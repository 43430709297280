import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import SnglAndMltiSelectComponent from "../../component/input/SingleSelect";
import SolidRoundedButton from "../../component/SinglePropertyPage/common/SolidRoundedButton";
import FilterModalHeader from "../../component/multiPropertyPage/FilterModalHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFilter,
  selectFilterById,
  upsertFilter,
} from "../redux-slices/advancedSearchSlice";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MinimalIconButtonWithToolTip from "../../component/button/MinimalIconButtonWithTooltip";
import { useEffect } from "react";
import { handleNegateToggle, handleUnsetToggle } from "./utils";

function CategoryFilter({ filter, readonly }) {
  const theme = useTheme();

  const dispatch = useDispatch();
  const filterState =
    useSelector((state) => selectFilterById(state, filter["api_key"])) || {};

  const options = filter.category_values
    .map((category) => category.value)
    .sort((a, b) => a.localeCompare(b));

  // TODO: Removing filter if values, negate, and includeUnset is missing
  useEffect(() => {
    if (
      filterState.values?.length === 0 &&
      filterState.includeUnset === false &&
      filterState.negate === false
    ) {
      dispatch(removeFilter({ field: filter["api_key"] }));
    }
  }, [filterState, dispatch, filter]);

  const handleChange = (event) => {
    const selectedValues = event.target.value;

    if (selectedValues.length === 0) {
      dispatch(
        upsertFilter({
          field: filter["api_key"],
          negate: filterState.negate ? filterState.negate : false,
          includeUnset: filterState.includeUnset
            ? filterState.includeUnset
            : false,
          values: [],
        })
      );
    } else {
      dispatch(
        upsertFilter({
          field: filter["api_key"],
          values: selectedValues,
          filterType: "category",
          negate: filterState.negate ? filterState.negate : false,
          includeUnset: filterState.includeUnset
            ? filterState.includeUnset
            : false,
        })
      );
    }
  };

  const handleSelectAllValues = () => {
    dispatch(
      upsertFilter({
        field: filter["api_key"],
        values: options,
        filterType: "category",
        negate: filterState.negate ? filterState.negate : false,
        includeUnset: filterState.includeUnset
          ? filterState.includeUnset
          : false,
      })
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: `1px solid ${theme.palette.divider}`,
          borderBottom: `1px solid ${theme.palette.divider}`,
          px: 1,
          py: "10px",
        }}
      >
        <Typography variant="body2">
          {filter.display_name || filter.api_key}
        </Typography>

        {readonly && (
          <MinimalIconButtonWithToolTip
            toolTipLabel={"Remove filter"}
            onClick={() => dispatch(removeFilter({ field: filter["api_key"] }))}
          >
            <DeleteOutlineIcon />
          </MinimalIconButtonWithToolTip>
        )}
      </Box>

      {!readonly && (
        <FilterModalHeader
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
            p: 1,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
          components={[
            <SolidRoundedButton
              key={"select all"}
              onClick={handleSelectAllValues}
              sx={{
                backgroundColor: "transparent",
                border: `1px solid ${theme.palette.divider}`,
                p: 0.4,
                textTransform: "capitalize",
              }}
            >
              Select All
            </SolidRoundedButton>,
            <SolidRoundedButton
              key={"Remove"}
              onClick={() => {
                dispatch(
                  upsertFilter({
                    field: filter["api_key"],
                    negate: filterState.negate ? filterState.negate : false,
                    includeUnset: filterState.includeUnset
                      ? filterState.includeUnset
                      : false,
                    values: [],
                  })
                );
              }}
              sx={{
                backgroundColor: "transparent",
                border: `1px solid ${theme.palette.divider}`,
                p: 0.4,
                textTransform: "capitalize",
              }}
            >
              Remove All
            </SolidRoundedButton>,
          ]}
        />
      )}

      <Box>
        <SnglAndMltiSelectComponent
          key={filterState.display_name}
          multiple={true}
          readyonly={readonly}
          value={filterState["values"] || []}
          options={options}
          selectProps={{ onChange: handleChange, disabled: readonly }}
          sx={{ width: "96%", mt: "20px", mx: "5px" }}
        />

        <ToggleSwitch
          dispatch={dispatch}
          filterState={filterState}
          filter={filter}
          readonly={readonly}
          valuesToApply={{ values: filterState?.values || [] }}
        />
      </Box>
    </>
  );
}

export default CategoryFilter;

export const ToggleSwitch = ({
  dispatch,
  filterState,
  filter,
  readonly,
  valuesToApply,
}) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        gap: "5px",
        mt: 0,
        p: "5px",
      }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={filterState.includeUnset || false}
            onChange={(event) =>
              handleUnsetToggle(
                event,
                dispatch,
                filterState,
                filter,
                valuesToApply
              )
            }
            name="Include missing"
            color="primary"
            disabled={readonly}
          />
        }
        label="Include missing"
      />
      <FormControlLabel
        control={
          <Switch
            checked={filterState.negate || false}
            onChange={(event) =>
              handleNegateToggle(
                event,
                dispatch,
                filterState,
                filter,
                valuesToApply
              )
            }
            name="Negate"
            color="primary"
            disabled={readonly}
          />
        }
        label="Negate"
      />
    </Box>
  );
};
