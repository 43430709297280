import { Typography } from "@mui/material";
import React from "react";

const NormalText = ({ text, width, overflow, sx, ...rest }) => {
  return (
    <Typography
      variant="body1"
      sx={{
        textAlign: "left",
        fontWeight: 400,
        my: 1,
        width: width,
        overflowX: overflow,
        ...sx,
        fontSize: "0.875rem",
      }}
      {...rest}
    >
      {text}
    </Typography>
  );
};

export default NormalText;
