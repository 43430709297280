import { useGridApiContext } from "@mui/x-data-grid";
import { MenuItem, Select } from "@mui/material";
import { useLayoutEffect, useMemo, useRef } from "react";

function EditSingleSelectCellRenderer({
  id,
  row,
  value,
  field,
  hasFocus,
  readOnly,
}) {
  const apiRef = useGridApiContext();
  const ref = useRef();
  const optionMap = useMemo(() => {
    const ret = {};
    (row.category_values || []).forEach((e) => {
      ret[e.value] = e.display_name || e.value;
    });
    return ret;
  }, [row]);
  const { missing_value_rep: missingValueRep = null } = row.validation;

  useLayoutEffect(() => {
    if (hasFocus && ref.current) {
      ref.current.focus();
    }
  }, [hasFocus]);

  const handleValueChange = (event) => {
    if (!readOnly && apiRef.current?.setEditCellValue) {
      const newVal = event.target.value;
      apiRef.current.setEditCellValue({
        id,
        field,
        value: isInvalidValue(newVal) ? missingValueRep : newVal,
      });
    }
  };

  const isInvalidValue = (val) => !optionMap[val];

  return (
    <Select
      value={isInvalidValue(value) ? "" : value}
      onChange={handleValueChange}
      displayEmpty
      size="small"
      inputProps={{ readOnly }} // This makes the select readonly if true
    >
      <MenuItem value="">
        <em>--</em>
      </MenuItem>
      {Object.entries(optionMap).map(([val, displayName]) => (
        <MenuItem key={val} value={val}>
          {displayName}
        </MenuItem>
      ))}
    </Select>
  );
}

export default EditSingleSelectCellRenderer;
