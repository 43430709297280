import React from "react";
import { Box } from "@mui/material";
import LoaderButton from "../../component/button/LoaderButton";
import { DefaultContainedBtn } from "../../component/button/commonButtons";
import TextInput from "../../component/SinglePropertyPage/common/TextInput";

const UnsavedForm = ({
  creatingNewView,
  viewName,
  onChangeViewName,
  handleSaveAs,
  handleEditView,
  handleCancel,
  isCreatingView,
  isUpdateViewLoading,
  isCreatingViewError,
  isUpdateViewError,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        pb: 2,
        borderBottom: `1px solid #ddd`,
      }}
    >
      <TextInput
        inputValue={viewName}
        onChangeHandler={onChangeViewName}
        textFieldProps={{
          placeholder: "unsaved view",
          size: "small",
        }}
        styles={{
          focus: "none",
          width: "100%",
          py: "1px",
          px: 1,
          mb: 1,
        }}
      />
      {creatingNewView ? (
        <LoaderButton
          onClick={handleSaveAs}
          loading={isCreatingView}
          error={isCreatingViewError}
        >
          Save as
        </LoaderButton>
      ) : (
        <>
          {!isCreatingView && (
            <LoaderButton
              onClick={handleEditView}
              loading={isUpdateViewLoading}
              error={isUpdateViewError}
            >
              Save
            </LoaderButton>
          )}
        </>
      )}
      <DefaultContainedBtn
        size="small"
        variant="outlined"
        sx={{
          fontSize: "14px",
          textTransform: "capitalize",
          mx: 0.5,
        }}
        onClick={handleCancel}
        disabled={isCreatingView || isUpdateViewLoading}
      >
        Cancel
      </DefaultContainedBtn>
    </Box>
  );
};

export default UnsavedForm;
