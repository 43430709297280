import React from "react";
import SolidRoundedButton from "../../component/SinglePropertyPage/common/SolidRoundedButton";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultVisibleColumns,
  detectUnsavedChanges,
  getAdvancedSearchPayload,
} from "../utils";
import {
  selectAllBuyBoxes,
  selectAllFilters,
  selectAllSorts,
} from "../redux-slices/advancedSearchSlice";
import {
  updateEditingExistingView,
  updatePaginationModelState,
  updateUnSavedViewState,
} from "../redux-slices/viewsSlice";

const AdvancedSrchActionBtn = ({
  applyAdvancedSearchMutation,
  children,
  ...rest
}) => {
  const dispatch = useDispatch();

  const appliedBuyBoxList = useSelector(selectAllBuyBoxes);
  const appliedSortsList = useSelector(selectAllSorts);
  const appliedFiltersList = useSelector(selectAllFilters);
  const currentSelectedView = useSelector(
    (state) => state.view.currentViewState
  );
  const appliedColumns = useSelector((state) => state.leads.appliedColumns);
  const appliedGroupBy = useSelector((state) => state.view.groupBy);

  const currentViewFilters =
    currentSelectedView.filters?.filters[0]?.filters || [];
  const currentViewBuyBoxes =
    currentSelectedView.filters?.filters[1]?.buyboxes || [];
  const currentViewSorts = currentSelectedView.filters?.sort || [];
  const currentViewColumns =
    currentSelectedView.filters?.columns || defaultVisibleColumns;
  const currentViewGroupBy = currentSelectedView.filters?.groupBy || null;
  const creatingNewView = useSelector((state) => state.view.creatingNewView);

  const handleApplyAdvancedSearch = async () => {
    if (!creatingNewView) {
      dispatch(updateEditingExistingView(true));
    }
    const isValuesSame = detectUnsavedChanges(
      currentViewFilters,
      currentViewSorts,
      currentViewBuyBoxes,
      currentViewColumns,
      currentViewGroupBy,
      appliedFiltersList,
      appliedSortsList,
      appliedBuyBoxList,
      appliedColumns,
      appliedGroupBy
    );
    if (!isValuesSame) {
      dispatch(
        updateUnSavedViewState({
          name: currentSelectedView.name,
          id: 1,
          filters: getAdvancedSearchPayload(
            appliedFiltersList,
            appliedSortsList,
            appliedBuyBoxList,
            appliedColumns,
            appliedGroupBy
          ),
        })
      );
    }

    dispatch(
      updatePaginationModelState({
        page: 0,
        pageSize: 100,
      })
    );
  };

  return (
    <SolidRoundedButton onClick={handleApplyAdvancedSearch} {...rest}>
      {children}
    </SolidRoundedButton>
  );
};

export default AdvancedSrchActionBtn;
