import { MinIconCompareBtn } from "../../component/button/commonButtons";
import { TableToolbar } from "../../component/SinglePropertyPage/common/muixDataGrid/tableToolbar/Toolbar";
import {
  useLockLeadUpdateWsMutation,
  useReviseLeadMutation,
  useUnlockLeadUpdateWsMutation,
} from "../../lib/propertyDetailsApi";
import { SHARED_MUTATION_KEYS } from "../table/EditableSingleLeadTbl";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import { forwardRef } from "react";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { useSelector } from "react-redux";
import { selectTableById } from "../leadUnderwritingSlice";

export function EditLeadToolbar({
  // isEditing,
  isUnsavedChanges,
  handleEdit,
  handleManualSave,
  handleStopEdit,
  stopEditRef,
  isSockConnected,
  isFetchingGetLead,
}) {
  const [, { isLoading: isLoadingLockUpdate, isError: isLockLeadErr }] =
    useLockLeadUpdateWsMutation({
      fixedCacheKey: SHARED_MUTATION_KEYS.lockLead,
    });
  const [, { isLoading: isLoadingReviseLead, isError: isReviseLeadErr }] =
    useReviseLeadMutation({
      fixedCacheKey: SHARED_MUTATION_KEYS.reviseLead,
    });
  const [, { isLoading: isLoadingUnlockLead, isError: isUnlockLeadErr }] =
    useUnlockLeadUpdateWsMutation({
      fixedCacheKey: SHARED_MUTATION_KEYS.unlockLead,
    });
  const { isWorking: isEditing } = useSelector((state) =>
    selectTableById(state, "lead")
  );

  const isLoadingAny =
    isLoadingLockUpdate ||
    isLoadingReviseLead ||
    isLoadingUnlockLead ||
    isFetchingGetLead;

  const rightButtons = [
    !isEditing ? (
      <PrimaryLoadingBtn
        key="edit button"
        onClick={handleEdit}
        disabled={isLoadingAny}
        loading={isLoadingLockUpdate || isFetchingGetLead}
        color={isLockLeadErr ? "error" : undefined}
      >
        Edit
      </PrimaryLoadingBtn>
    ) : (
      <PrimaryLoadingBtn
        ref={stopEditRef}
        key="cancel button"
        onClick={handleStopEdit}
        disabled={isLoadingAny}
        loading={isLoadingUnlockLead}
        color={isUnlockLeadErr ? "error" : "secondary"}
        startIcon={<ClearIcon />}
      >
        Stop
      </PrimaryLoadingBtn>
    ),
    isUnsavedChanges && (
      <PrimaryLoadingBtn
        key="save button"
        onClick={handleManualSave}
        disabled={isLoadingAny}
        loading={isLoadingReviseLead}
        color={isReviseLeadErr ? "error" : undefined}
        startIcon={<SaveIcon />}
      >
        Save
      </PrimaryLoadingBtn>
    ),
    <MinIconCompareBtn key="right_compare" />,
  ];

  const leftButtons = [
    !isSockConnected && <SockStatusIcon key="socket_conn" />,
  ];

  return <TableToolbar leftButtons={leftButtons} rightButtons={rightButtons} />;
}

export const PrimaryLoadingBtn = forwardRef(({ children, ...props }, ref) => {
  return (
    <LoadingButton
      ref={ref}
      size="small"
      variant="contained"
      loadingPosition="start"
      startIcon={<EditIcon />}
      disableElevation
      {...props}
    >
      {children}
    </LoadingButton>
  );
});

function SockStatusIcon() {
  return (
    <div>
      <WifiOffIcon color="warning" />
    </div>
  );
}
