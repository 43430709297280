import React from "react";
import H3 from "./H3";
import { useTheme } from "@mui/material";

const formatValue = (value, prefix) => {
  // Convert the number to string with commas
  const addCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Handle value formatting based on prefix
  switch (prefix) {
    case "amount":
      return `$${addCommas(value)}`;
    case "percent":
      return `${value}%`;
    case "years":
      return `${value} yrs`;
    case "months":
      return `${value} mon`;
    default:
      return value; // Return the value as is if no specific prefix is provided
  }
};

const SliderValue = ({ value, prefix }) => {
  const theme = useTheme();
  const formattedValue = formatValue(value, prefix);

  return <H3 color={theme.palette.text.secondary} title={formattedValue} />;
};

export default SliderValue;
