import React from "react";
import { CircularProgress } from "@mui/material";
import { DefaultContainedBtn } from "./commonButtons";

const LoaderButton = ({
  loading = false,
  error = false,
  loadingText = "Loading...",
  children,
  onClick,
  variant = "outlined",
  disabled = loading,
  sx,
}) => {
  return (
    <DefaultContainedBtn
      size="small"
      variant={variant}
      color={error ? "error" : "primary"}
      onClick={onClick}
      disabled={disabled}
      sx={{
        fontSize: "14px",
        textTransform: "capitalize",
        mx: 0.5,
        ...sx,
      }}
      startIcon={loading && <CircularProgress size={14} />}
    >
      {loading ? loadingText : children}
    </DefaultContainedBtn>
  );
};

export default LoaderButton;
