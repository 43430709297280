import React, { useState } from "react";
import { Box, Card, useTheme } from "@mui/material";
import { CashFlowCalc } from "./ListPriceRefactor";
import AssumptionsModal from "./AssumptionsModal";
import ShowValue from "./common/ShowValue";
import SectionTitle from "./common/SectionTitle";
import NormalText from "./common/NormalText";
import LeadImagesContainer from "./Overview/LeadImagesContainer";
import {
  boolIntToYesNo,
  getIsLeadOverwriteExist,
  getLeadField,
} from "./common/utils";
import { isMissingLike } from "./common/utils";
import { composeFuncs } from "../../common/utils/miscUtils";
import { dtypeToFormatters } from "../../common/utils/tableFormatters";

function PropertyDetails({ lead }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();

  return (
    <Box sx={{ position: "relative" }}>
      <Card
        sx={{
          p: 4,
          display: "flex",
          gap: "20px",
          alignItems: "start",
          position: "relative",
          width: "100%",
          boxShadow: 0,
        }}
      >
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <LeadImagesContainer {...{ lead }} />
          <Box
            sx={{
              mt: 1,
              pt: 2,
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <SectionTitle sectionTitle={"Property Details:"} />
            <LeadQuickDetailsCtn {...{ lead }} />
          </Box>
        </Box>
        <CashFlowCalc setIsModalOpen={setIsModalOpen} leadWithRelData={lead} />
      </Card>
      {isModalOpen && <AssumptionsModal setIsModalOpen={setIsModalOpen} />}
    </Box>
  );
}

function LeadQuickDetailsCtn({ lead }) {
  const isLeadOverwrite = getIsLeadOverwriteExist(lead);

  const leadQuickDetailsData = {
    onLeft: [
      {
        label: "Year built:",
        value: getLeadField(lead, "year_built", isLeadOverwrite),
      },
      {
        label: "Source:",
        value: lead?.["source"],
      },
      {
        label: "Occupancy:",
        value: boolIntToYesNo(lead?.["is_occupied"]),
      },
      {
        label: "Pool:",
        value: boolIntToYesNo(lead?.["is_pool"]),
      },
    ],
    onRight: [
      {
        label: "Review Status:",
        value: lead?.review_status,
      },
      {
        label: "Created On:",
        value: lead?.created_on,
        formatType: "date",
      },
      {
        label: "Updated On:",
        value: lead?.updated_on,
        formatType: "date",
      },
    ],
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        marginBottom: "10px",
      }}
    >
      {Object.entries(leadQuickDetailsData).map(([key, fields]) => (
        <LeadQuickDetails key={key} {...{ fields }} />
      ))}
    </Box>
  );
}

function LeadQuickDetails({ fields }) {
  return (
    <Box sx={{ flex: 1 }}>
      {fields.map(({ label, value, formatType }) => {
        const transformedValue = isMissingLike(value)
          ? value
          : composeFuncs(value, dtypeToFormatters[formatType] || []);
        return (
          <Card
            key={label}
            sx={{
              display: "flex",
              gap: 5,
              alignItems: "center",
              boxShadow: 0,
            }}
          >
            <NormalText text={label} width={"160px"} />
            <ShowValue value={transformedValue} />
          </Card>
        );
      })}
    </Box>
  );
}

export default PropertyDetails;
