import { upsertFilter } from "../redux-slices/advancedSearchSlice";

export const handleUnsetToggle = (
  event,
  dispatch,
  filterState,
  filter,
  valuesToApply
) => {
  dispatch(
    upsertFilter({
      field: filter["api_key"],
      includeUnset: event.target.checked,
      negate: filterState.negate ? filterState.negate : false,
      ...valuesToApply,
    })
  );
};

export const handleNegateToggle = (
  event,
  dispatch,
  filterState,
  filter,
  valuesToApply
) => {
  dispatch(
    upsertFilter({
      field: filter["api_key"],
      negate: event.target.checked,
      includeUnset: filterState.includeUnset ? filterState.includeUnset : false,
      ...valuesToApply,
    })
  );
};

export const stringStartsWith = (string) => {
  const match = string.match(/^(Last|Next)/);

  return match ? match[0] : null;
};

export const getRange = (minDateString, maxDateString) => {
  const {
    period: minPeriod,
    operator: minOperator,
    nValue: minNValue,
    roundOff: minRoundOff,
  } = parseRelativeDate(minDateString);
  const {
    period: maxPeriod,
    operator: maxOperator,
    nValue: maxNValue,
    roundOff: maxRoundOff,
  } = parseRelativeDate(maxDateString);

  if (!maxRoundOff) {
    // The only case with no roundOff value is `${period} to date`
    return 2;
  } else if (minOperator === "-" && maxOperator === "-") {
    if (minNValue > 1) {
      return 3;
    } else {
      return 0;
    }
  } else if (minOperator === "+" && maxOperator === "+") {
    if (minNValue > 0) {
      return 4;
    } else {
      return 1;
    }
  }
};

export const parseRelativeDate = (relativeDateString) => {
  const regex = /^([+-])?(\d+)([dwmy])?([re])?$/;
  const match = relativeDateString.match(regex);

  if (!match) {
    throw new Error("Invalid format");
  }

  const [_unused, operator, nValue, period, roundOff] = match;

  return {
    operator,
    nValue: parseInt(nValue, 10),
    period,
    roundOff: roundOff ? roundOff : null,
  };
};
