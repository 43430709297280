import { Box, Skeleton } from "@mui/material";

function ActivityGroupSkeleton() {
  return (
    <Box
      sx={{ width: "100%", display: "flex", alignItems: "flex-start", gap: 1 }}
    >
      {/* Avatar Skeleton */}
      <Box sx={{ width: "30px" }}>
        <Skeleton variant="rounded" width={30} height={30} />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        {/* Name and Timestamp Skeleton */}
        <Skeleton width="60%" height={20} />

        {/* Activity Group Skeleton */}
        <Box
          sx={{
            maxHeight: "100px",
            height: "100%",
            overflowY: "hidden",
            pl: 1,
            borderLeft: (theme) => `2px solid ${theme.palette.divider}`,
            mt: 1,
          }}
        >
          {[...Array(3)].map((_, idx) => (
            <Skeleton key={idx} width="90%" height={18} sx={{ mb: 0.5 }} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default ActivityGroupSkeleton;
