import Home from "../pages/home";
import MultiPropLayout from "../pages/MultipropertyPage/MultiPropLayout";

const MainRoutes = {
  path: "/prop-lead",
  element: <MultiPropLayout />,
  children: [
    {
      path: "explore",
      element: <Home />,
    },
  ],
};

export default MainRoutes;
