import { Box } from "@mui/material";

function FilterModalHeader({ components, sx }) {
  return (
    <Box sx={{ ...sx }}>
      {components.map((component) => {
        return component;
      })}
    </Box>
  );
}

export default FilterModalHeader;
