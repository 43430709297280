import React, { useState } from "react";
import Logo from "../../assets/image/fa-logo.png";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  useTheme,
  FormControl,
  Input,
  FormHelperText,
} from "@mui/material";
import { toast } from "react-toastify";
import { useLoginMutation } from "../../multiPropertyView/api-slices/authApiSlice";
import LoaderIconButton from "../../component/button/LoaderIconButton";

export default function SignIn() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [login, { isLoading, isError }] = useLoginMutation();

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  // Validation functions
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    if (name === "email") {
      setErrors({
        ...errors,
        email: validateEmail(value) ? "" : "Invalid email address",
      });
    } else if (name === "password") {
      setErrors({
        ...errors,
        password: validatePassword(value)
          ? ""
          : "Password must be at least 6 characters",
      });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailError = validateEmail(formValues.email)
      ? ""
      : "Invalid email address";
    const passwordError = validatePassword(formValues.password)
      ? ""
      : "Password must be at least 6 characters";

    if (emailError || passwordError) {
      setErrors({ email: emailError, password: passwordError });
      return;
    }

    try {
      const res = await login(formValues).unwrap();
      toast.success("Login successful");
      localStorage.setItem("fb_user", JSON.stringify(res));
      navigate("/prop-lead/explore");
    } catch (error) {
      toast.error("Login failed. Please try again.");
    }
  };

  return (
    <section className="login-page">
      <div className="login-inner">
        <div className="logo-wrap">
          <img src={Logo} alt="" />
        </div>
        <div className="form-wrap">
          <Box className="tab-box">
            <div className="form login-form">
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  fontWeight: "semibold",
                  color: theme.palette.main,
                  mb: "20px",
                }}
              >
                Login in with your existing account
              </Typography>
              <form onSubmit={handleSubmit}>
                {/* Email Field */}
                <FormControl fullWidth error={!!errors.email} sx={{ mb: 2 }}>
                  <Typography sx={{ mb: "10px" }} variant="body1">
                    Email
                  </Typography>

                  <Input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    sx={{
                      border: `1px solid ${theme.palette.grey[500]}`,
                      p: "10px",
                      borderRadius: "4px",
                    }}
                    value={formValues.email}
                    onChange={handleInputChange}
                    disableUnderline
                    onBlur={() => {
                      if (!formValues.email) {
                        setErrors({ ...errors, email: "Email is required" });
                      }
                    }}
                  />
                  {errors.email && (
                    <FormHelperText>{errors.email}</FormHelperText>
                  )}
                </FormControl>

                {/* Password Field */}
                <FormControl fullWidth error={!!errors.password} sx={{ mb: 2 }}>
                  <Typography variant="body1" sx={{ mb: "10px" }}>
                    Password
                  </Typography>

                  <Input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    sx={{
                      border: `1px solid ${theme.palette.grey[500]}`,
                      p: "10px",
                      borderRadius: "4px",
                      "::placeholder": {
                        fontSize: "0.875rem",
                      },
                    }}
                    disableUnderline
                    value={formValues.password}
                    onChange={handleInputChange}
                    onBlur={() => {
                      if (!formValues.password) {
                        setErrors({
                          ...errors,
                          password: "Password is required",
                        });
                      }
                    }}
                  />
                  {errors.password && (
                    <FormHelperText>{errors.password}</FormHelperText>
                  )}
                </FormControl>

                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    mt: "20px",
                  }}
                >
                  <Button
                    disabled={isLoading}
                    type="submit"
                    className="submit-btn"
                    variant="contained"
                  >
                    Login
                  </Button>

                  {(isError || isLoading) && (
                    <LoaderIconButton loading={isLoading} error={isError} />
                  )}
                </Box>
              </form>
            </div>
          </Box>
        </div>
      </div>
    </section>
  );
}
