import SinglePropertyPage from "../pages/SinglePropertyPage";
import PropertyDetailsPage from "../pages/propertyPage/PropertyDetailsPage";
import PropertyOverview from "../pages/propertyPage/PropertyOverview";

const PageRoutes = {
  path: "/prop-lead/:uid",
  element: <SinglePropertyPage />,
  children: [
    {
      path: "overview",
      element: <PropertyOverview />,
    },
    {
      path: "details",
      element: <PropertyDetailsPage />,
    },
  ],
};

export default PageRoutes;
