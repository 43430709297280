import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  useTheme,
} from "@mui/material";
import { useEffect, useState, useRef, useMemo } from "react";
import {
  useGetUserAuthByIdQuery,
  useLazyGetUserSearchNameQuery,
} from "../../../../../lib/userTableApi";
import _ from "lodash";
import {
  UserCardBase,
  UserCardSkel,
  UserItemOutlineCard,
} from "../../../../UserTable/UserCard";
import { useGridApiContext } from "@mui/x-data-grid";
import ShowValue from "../../ShowValue";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import DeleteIcon from "@mui/icons-material/Delete";
import MinimalIconButtonWithToolTip from "../../../../button/MinimalIconButtonWithTooltip";

const relationConfig = {
  user: {
    InputAdapter: UserSearch,
    DisplayAdapter: UserEditCardLoader,
  },
};
/**
 * API details:
 * - renderer uses an input adapter, to get `value` from user
 * - input adapter:
 *   takes `value`, `handleValueChange`
 *   job is basically to set `value` from user - here we've only used an auto-complete based input
 *
 * - second component renderer uses is a display card
 * - takes value display, and some event handlers
 *
 * TODO: later with more relations we can abstract this out more
 */
export function EditRelationRenderer(props) {
  const { id, value: relId, field, row } = props;
  const apiRef = useGridApiContext();
  const ref = useRef();
  const [searchVal, setSearchVal] = useState(null);
  const { missing_value_rep: missingValueRep = null } = row.validation;
  const { InputAdapter, DisplayAdapter } = useMemo(
    () => relationConfig.user,
    []
  );

  const handleValueChange = (newValue) => {
    const newId = newValue?.userId;
    if (apiRef.current?.setEditCellValue) {
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newId || missingValueRep,
      });
    }
    setSearchVal(newValue);
  };

  const handleDelete = (id) => {
    handleValueChange(null);
  };

  const showItem = Boolean(relId && relId >= 1);
  return (
    <Box
      sx={{
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {showItem && <DisplayAdapter value={relId} handleDelete={handleDelete} />}
      <InputAdapter value={searchVal} handleValueChange={handleValueChange} />
    </Box>
  );
}

export function ReadRelationRenderer(props) {
  const { value: relId, row } = props;
  const { missing_value_rep: missingValueRep = null } = row.validation;

  const showItemCard = relId && relId >= 1;

  return !showItemCard ? (
    <ShowValue value={missingValueRep} />
  ) : (
    <UserCardLoader id={relId} />
  );
}

function UserCardLoader({ id }) {
  const theme = useTheme();
  const { data, isLoading, isError } = useGetUserAuthByIdQuery(id);
  const displayName =
    data?.displayName || (isLoading ? "Loading User..." : "Error Loading");
  const email = data?.email || "";
  return isLoading ? (
    <UserCardSkel />
  ) : (
    <UserItemOutlineCard
      {...{ displayName, email }}
      slotProps={{
        container: { sx: { backgroundColor: theme.palette.grey["50"] } },
        icon: {
          sx: { color: isError ? theme.palette.error.main : "text.secondary" },
        },
      }}
    />
  );
}

function UserEditCardLoader({ value, handleDelete }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <UserCardLoader id={value} />
      <MinimalIconButtonWithToolTip
        toolTipLabel="delete"
        onClick={() => handleDelete(value)}
      >
        <DeleteIcon />
      </MinimalIconButtonWithToolTip>
    </Box>
  );
}

export function UserSearch({ value, handleValueChange }) {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [
    searchUserName,
    {
      data: userResult,
      isFetching: isFetchingUserSearch,
      isError: isErrorUserSearch,
    },
  ] = useLazyGetUserSearchNameQuery();

  const debounceSearch = useRef(
    _.debounce((query) => {
      searchUserName({ query }, true);
    }, 500)
  );

  useEffect(() => {
    if (!isFetchingUserSearch && inputValue && inputValue.length >= 3) {
      debounceSearch.current(inputValue);
    }
  }, [inputValue, isFetchingUserSearch]);

  const options = userResult || [];

  return (
    <Autocomplete
      id="search-for-users"
      value={value}
      options={options}
      filterOptions={(x) => x}
      sx={{ width: "300px" }}
      noOptionsText="No results"
      onChange={(event, newValue) => {
        handleValueChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <TextField
            {...params}
            placeholder="search for users"
            fullWidth
            size="small"
          />
          {isFetchingUserSearch ? (
            <CircularProgress size="1rem" sx={{ color: "text.primary" }} />
          ) : isErrorUserSearch ? (
            <ReportProblemIcon
              sx={{ color: theme.palette.error.main, fontSize: "1rem" }}
            />
          ) : (
            <></>
          )}
        </Box>
      )}
      getOptionLabel={(option) => option?.displayName}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <UserCardBase
              displayName={option.displayName}
              email={option.email}
            />
          </li>
        );
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableClearable
    />
  );
}
