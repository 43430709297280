import { multiPropViewApiSlice } from "./multiPropertyViewApiSlice";

const BASE_URL_SUFFIX = "/auth";

const auth = multiPropViewApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (param) => ({
        url: `${BASE_URL_SUFFIX}/log-in`,
        method: "POST",
        body: param,
      }),
      //   invalidatesTags: ["AuthStatus"]
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${BASE_URL_SUFFIX}/log-out`,
        method: "POST",
      }),
      //   invalidatesTags: ["AuthStatus"]
    }),
    loginCheck: builder.query({
      query: () => ({
        url: `${BASE_URL_SUFFIX}/am-i-auth`,
        method: "GET",
      }),
    }),

    getFieldsConfig: builder.query({
      query: () => `/field-config`,
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useGetFieldsConfigQuery,
  useLazyLoginCheckQuery,
  useLazyGetFieldsConfigQuery,
} = auth;

export const { getFieldsConfig } = auth.endpoints;
