/** Functions used to transform values for MUIX data grid
 *  usually used in column def
 */
import _ from "lodash";

export function dtypeValidationPreProcessor(params) {
  const { row } = params;

  switch (row?.dtype) {
    case "int":
    case "float":
    case "decimal":
      return numValidationPreProcessor(params);

    default:
      return missingValuePreProcessor(params);
  }
}

export function missingValuePreProcessor(params) {
  return {
    ...params.props,
    error: missingValueValidator(params),
  }
}

export function missingValueValidator(params) {
  const { row, props } = params;
  const { validation } = row;
  if (!validation) {
    return props;
  }
  const isMissing =
    [NaN, undefined, null, ""].includes(props?.value) || !props?.value;
  const hasError = !validation.is_allow_missing && isMissing;

  if (!!hasError) {
    return "can't be missing";
  }
  return false;
}

export function numValidationPreProcessor(params) {
  const priorErr = missingValueValidator(params);
  if (priorErr) {
    return {
      ...params.props,
      error: priorErr,
    };
  }
  const { row } = params;
  const isINt = row.formatting?.type === "int";
  const value = _.isString(params.props?.value) ? params.props.value : "";

  const VALID_DECIMAL_INP = /^(-[0-9.])?[0-9]*(.[0-9])?[0-9]*$/;
  const VALID_INT_INP = /^(-[0-9])?[0-9]*$/;

  const isValid = isINt
    ? VALID_INT_INP.test(value)
    : VALID_DECIMAL_INP.test(value);

  return {
    ...params.props,
    error: isValid ? false : isINt ? "must be integer" : "must be numeric",
  };
}
