import { useSelector } from "react-redux";
import {
  addSort,
  selectAllSorts,
  setSorts,
  removeSort,
} from "../redux-slices/advancedSearchSlice";
import { Box, useTheme } from "@mui/material";
import NormalText from "../../component/SinglePropertyPage/common/NormalText";
import MinimalIconButtonWithToolTip from "../../component/button/MinimalIconButtonWithTooltip";
import { useDispatch } from "react-redux";
import EmptyUIComponent from "../filterLists/EmptyFilters";
import SortIcon from "@mui/icons-material/Sort";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

function SelectedSortList({ sortsList }) {
  const selectedSortList = useSelector(selectAllSorts);
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleSortOrderToggle = (sort) => {
    const newOrder = sort.order === 1 ? 2 : 1;
    dispatch(addSort({ field: sort.field, order: newOrder }));
  };

  const handleMove = (index, direction) => {
    const newSortList = [...selectedSortList];
    const targetIndex = index + direction;

    if (targetIndex >= 0 && targetIndex < newSortList.length) {
      // Swaping items in the array with this we have the ability to change the order of sortLists
      [newSortList[index], newSortList[targetIndex]] = [
        newSortList[targetIndex],
        newSortList[index],
      ];
      dispatch(setSorts(newSortList));
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        p: 1,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        overflowY: "auto",
      }}
    >
      {selectedSortList.length > 0 ? (
        selectedSortList.map((sort, index) => {
          const currentSort = sortsList.filter(
            (sortItem) => sortItem["api_key"] === sort["field"]
          );

          return (
            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
              key={sort["field"]}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 1,
                  border: `1px solid ${theme.palette.divider}`,
                  transition: "transform 0.3s ease-in-out",
                  flex: 1,
                  borderRadius: "5px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.2 }}>
                  <MinimalIconButtonWithToolTip
                    toolTipLabel="Move Up"
                    onClick={() => handleMove(index, -1)}
                    disabled={index === 0} // Disable for the first item
                  >
                    <ArrowDropUp />
                  </MinimalIconButtonWithToolTip>
                  <MinimalIconButtonWithToolTip
                    toolTipLabel="Move Down"
                    onClick={() => handleMove(index, 1)}
                    disabled={index === selectedSortList.length - 1} // Disable for the last item
                  >
                    <ArrowDropDown />
                  </MinimalIconButtonWithToolTip>
                  <NormalText
                    text={
                      currentSort[0]["display_name"] ||
                      currentSort[0]["api_key"]
                    }
                  />
                </Box>

                <MinimalIconButtonWithToolTip
                  toolTipLabel={
                    sort.order === 1
                      ? "Switch to descending order"
                      : "Switch to ascending order"
                  }
                  onClick={() => handleSortOrderToggle(sort)}
                >
                  <SortIcon
                    sx={{
                      transform: sort.order === 1 ? "rotate(180deg)" : "none",
                      transition: "transform 0.3s ease-in-out", // Optional smooth rotation
                    }}
                  />
                </MinimalIconButtonWithToolTip>
              </Box>
              <Box>
                <MinimalIconButtonWithToolTip
                  toolTipLabel={"Remove Sort"}
                  onClick={() => dispatch(removeSort({ field: sort["field"] }))}
                >
                  <CloseIcon />
                </MinimalIconButtonWithToolTip>
              </Box>
            </Box>
          );
        })
      ) : (
        <EmptyUIComponent message={"No sort applied!"} />
      )}
    </Box>
  );
}

export default SelectedSortList;
