import { Skeleton, Box, Container, Grid } from "@mui/material";

function PropDetailSkeleton() {
  return (
    <>
      {/* Header Skeleton */}
      <Skeleton variant="rectangular" height={80} sx={{ mb: 2 }} />

      {/* Property Info Skeleton */}
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          p: 2,
          boxShadow: 0,
        }}>
        <Box sx={{ flexGrow: 1 }}>
          <Skeleton variant="text" width="60%" height={40} />
          <Skeleton variant="text" width="40%" height={30} sx={{ mt: 1 }} />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Skeleton variant="rectangular" width={100} height={40} />
          <Skeleton variant="rectangular" width={100} height={40} />
          <Skeleton variant="rectangular" width={100} height={40} />
        </Box>
      </Container>

      {/* Property Details Skeleton */}
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
        </Grid>
      </Container>

      {/* Property History Skeleton */}
      <Container sx={{ mt: 4 }}>
        <Skeleton variant="rectangular" height={300} />
      </Container>
    </>
  );
}

export default PropDetailSkeleton;
