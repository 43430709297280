import { Box, Typography, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  DEFAULT_TIMEZONE,
  YMD_DATE_FORMAT,
} from "../../../common/utils/constants";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFilterById,
  upsertFilter,
} from "../../redux-slices/advancedSearchSlice";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(quarterOfYear);

function CustomDateTimeFilter({ filter, readonly = false }) {
  const filterState =
    useSelector((state) => selectFilterById(state, filter["api_key"])) || {};

  const dispatch = useDispatch();

  const handleFromDateChange = (newDateTime) => {
    dispatch(
      upsertFilter({
        field: filter["api_key"],
        filterType: "datetime",
        min: newDateTime.format(YMD_DATE_FORMAT),
        max:
          filterState.max !== undefined && filterState.max !== null
            ? filterState.max
            : null,
      })
    );
  };

  const handleToDateChange = (newDateTime) => {
    dispatch(
      upsertFilter({
        field: filter["api_key"],
        filterType: "datetime",
        min:
          filterState.min !== undefined && filterState.min !== null
            ? filterState.min
            : null,
        max: newDateTime.format(YMD_DATE_FORMAT),
      })
    );
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          gap: 3,
          mt: "20px",
          mx: "5px",
          alignItems: "flex-start",
          px: 1,
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
          }}
        >
          <Box>
            <Typography variant="body2" sx={{ mb: 1 }}>
              From
            </Typography>
            <DatePicker
              ampm="false"
              value={
                filterState.min !== undefined && filterState.min !== null
                  ? dayjs.utc(filterState.min)
                  : null
              }
              disabled={readonly}
              onChange={handleFromDateChange}
              timezone={DEFAULT_TIMEZONE}
              sx={{
                width: "200px",
                "& .MuiInputBase-input": {
                  padding: "8px",
                },
              }}
            />
          </Box>
          <Box>
            <Typography variant="body2" sx={{ mb: 1 }}>
              To
            </Typography>
            <DatePicker
              ampm="false"
              value={
                filterState.max !== undefined && filterState.max !== null
                  ? dayjs.utc(filterState.max)
                  : null
              }
              disabled={readonly}
              onChange={handleToDateChange}
              timezone={DEFAULT_TIMEZONE}
              sx={{
                width: "200px",
                "& .MuiInputBase-input": {
                  padding: "8px",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CustomDateTimeFilter;
