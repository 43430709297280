import React from "react";
import { Button, useTheme } from "@mui/material";

const SolidFlatButton = ({ onClickHandler, children, ...buttonProps }) => {
  const theme = useTheme();

  const buttonStyles = {
    textTransform: "none",
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.secondary,
    boxShadow: 0,
    "&.hover": {
      backgroundColor: "transparent",
    },
  };
  return (
    <Button
      disableRipple
      onClick={onClickHandler}
      sx={buttonStyles}
      {...buttonProps}>
      {children}
    </Button>
  );
};

export default SolidFlatButton;
