import { Avatar, Box, Tab, Tabs, useTheme } from "@mui/material";
import LoaderIconButton from "../../component/button/LoaderIconButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import MinimalIconButtonWithToolTip from "../../component/button/MinimalIconButtonWithTooltip";
import RefreshIcon from "@mui/icons-material/Refresh";

export function ActivityTrackerHeader({
  selectedTab,
  handleTabChange,
  loading,
  handleRefresh,
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "sticky",
        paddingX: "1rem",
        height: "2.5rem",
        top: 0,
        zIndex: 1000,
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0px 2px 4px -1px ${theme.palette.grey[300]}`,
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        centered
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& .MuiTab-root.Mui-selected": {
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontSize: "0.875rem",
            textTransform: "none",
            p: 0,
          },
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.primary.light,
            display: "none",
          },
        }}
      >
        <Tab
          sx={{
            width: "auto",
            fontSize: "0.875rem",
            textTransform: "none",
            p: 0,
          }}
          label="Activity"
          disableRipple
          value={"activity"}
        />
        <Tab
          sx={{ width: "auto", fontSize: "0.875rem", textTransform: "none" }}
          label="Comment"
          value={"comment"}
          disableRipple
        />
        <Tab
          sx={{ width: "auto", fontSize: "0.875rem", textTransform: "none" }}
          label="Email"
          value={"email"}
          disableRipple
        />
        <Tab
          sx={{ width: "auto", fontSize: "0.875rem", textTransform: "none" }}
          label="Files"
          value={"files"}
          disableRipple
        />
      </Tabs>

      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        {loading && <LoaderIconButton loading={loading} />}
        <MinimalIconButtonWithToolTip
          toolTipLabel={"Refresh Activity"}
          onClick={handleRefresh}
        >
          <RefreshIcon fontSize="large" />
        </MinimalIconButtonWithToolTip>
        <Avatar
          sx={{
            backgroundColor: theme.palette.grey[400],
            height: "30px",
            width: "30px",
          }}
        >
          <PersonOutlineIcon />
        </Avatar>
      </Box>
    </Box>
  );
}
