import { useEffect, useState } from "react";
import { Tabs, Tab, Box, useTheme, Toolbar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import {
  selectShouldUpdateTableAlias,
  updateShouldUpdateTableAlias,
  updateTableAlias,
} from "../../store/reducers/singlePropPageReducer";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../common/dialog/AlertDialog";
import { UserAvatarWithSettings } from "../common/UserAvatar";
import {
  leadUwFullReset,
  activeTableAliasUpdated,
} from "../../leadUnderwriting/leadUnderwritingSlice";
import { selectActiveTable } from "../../leadUnderwriting/leadUnderwritingSlice";
import { TBL_ALIAS } from "../../common/utils/constants";

// Define Main and Nested Navigation as a Configurable Structure
const navigationItems = [
  {
    label: "Overview",
    nestedItems: [],
    route: (id) => `/prop-lead/${id}/overview`,
    disable: false,
  },
  {
    label: "Details",
    nestedItems: [
      { label: "Lead Details", table_alias: "lead" },
      { label: "Valuation", table_alias: "valuation" },
      { label: "HOA", table_alias: "hoa" },
      { label: "Tax", table_alias: "tax" },
      { label: "Rehab Inspection", table_alias: TBL_ALIAS.rehab },
    ],
    route: (id) => `/prop-lead/${id}/details`,
    disable: false,
  },
  {
    label: "Offers",
    nestedItems: [],
    route: (id) => `/prop-lead/${id}/create-offer`,
    disable: true,
  },
];

const ReUsableTabs = ({
  activeTab,
  handleTabChange,
  tabItems,
  sx,
  otherProps,
}) => {
  return (
    <Tabs value={activeTab} onChange={handleTabChange} sx={{ ...sx }}>
      {tabItems.map((item, index) => (
        <Tab
          key={index}
          label={item.label}
          sx={{ ...otherProps }}
          disabled={item.disable}
          disableRipple
        />
      ))}
    </Tabs>
  );
};

const NestedNavigation = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    getTabFromPathName(location.pathname)
  );
  const [activeNestedTab, setActiveNestedTab] = useState(0);
  const [pendingNestedTab, setPendingNestedTab] = useState(null);
  const id = location.pathname.split("/")[2];
  const [showDialog, setShowDialog] = useState(false);
  const shouldUpdateTableAlias = useSelector(selectShouldUpdateTableAlias); // FIXME: move this out to leadUnderwritingSlice as well

  const dispatch = useDispatch();

  const handleBack = () => {
    navigate("/prop-lead/explore");
  };

  const handleTabChange = (event, newValue) => {
    const pathAfterId = location.pathname.split("/")[3];
    if (pathAfterId === "overview" && newValue === 0) {
      setActiveTab(newValue);
    } else if (pathAfterId === "details" && newValue === 1) {
      dispatch(leadUwFullReset({}));
      setActiveTab(newValue);
    }
    setActiveNestedTab(0);
    dispatch(activeTableAliasUpdated(TBL_ALIAS.lead));
    navigate(navigationItems[newValue].route(id));
  };

  const handleNestedTabChange = (event, newValue) => {
    if (!shouldUpdateTableAlias) {
      setPendingNestedTab(newValue);
      setShowDialog(true);
    } else {
      changeNestedTab(newValue);
    }
  };

  // synchronise active tab state with location URL change:

  useEffect(() => {
    setActiveTab(getTabFromPathName(location.pathname));
  }, [location.pathname]);

  const changeNestedTab = (newValue) => {
    dispatch(updateShouldUpdateTableAlias(true));
    setActiveNestedTab(newValue);
    switch (newValue) {
      case 0:
        dispatch(activeTableAliasUpdated("lead"));
        break;
      case 1:
        dispatch(activeTableAliasUpdated("valuation"));
        break;
      case 2:
        dispatch(activeTableAliasUpdated("hoa"));
        break;
      case 3:
        dispatch(activeTableAliasUpdated("tax"));
        break;
      case 4:
        dispatch(activeTableAliasUpdated("rehab"));
        break;
      default:
        dispatch(activeTableAliasUpdated("lead"));
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Toolbar variant="dense" sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", gap: 0.1 }}>
            <ArrowBack
              sx={{ padding: 1, marginRight: 1, cursor: "pointer" }}
              onClick={handleBack}
            />

            {/* Render Main Tabs */}
            <ReUsableTabs
              activeTab={activeTab}
              handleTabChange={handleTabChange}
              tabItems={navigationItems}
              sx={{
                "& .MuiTab-root.Mui-selected": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  textTransform: "capitalize",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: theme.palette.primary.light,
                  display: "none",
                },
              }}
              otherProps={{
                textTransform: "capitalize",
                py: 0,
                px: 6,
                color: theme.palette.text.primary,
              }}
            />
          </Box>

          <Box sx={{ pt: 1 }}>
            <UserAvatarWithSettings />
          </Box>
        </Box>
      </Toolbar>

      {/* Render Nested Tabs if available */}
      {navigationItems[activeTab].nestedItems &&
      navigationItems[activeTab].nestedItems.length > 0 ? (
        <ReUsableTabs
          activeTab={activeNestedTab}
          handleTabChange={handleNestedTabChange}
          tabItems={navigationItems[activeTab].nestedItems}
          sx={{
            backgroundColor: theme.palette.primary.main,
            "& .MuiTab-root.Mui-selected": {
              color: theme.palette.primary.contrastText,
              textTransform: "capitalize",
              backgroundColor: theme.palette.primary.dark,
            },
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.grey[300],
            },
          }}
          otherProps={{
            color: theme.palette.primary.contrastText,
            textTransform: "capitalize",
          }}
        />
      ) : null}
      <AlertDialog
        setActiveTab={setActiveTab}
        open={showDialog}
        title={"Discard Unsaved Changes?"}
        message={
          "Changes you made may not be saved, are you sure you want to discard and leave?"
        }
        handleDialogDiscard={() => {
          changeNestedTab(pendingNestedTab); // Navigate to the pending tab or the tables related to that tab
          setShowDialog(false);
        }}
        handleDialogSave={() => {
          setShowDialog(false);
        }}
      />
    </Box>
  );
};

export default NestedNavigation;

function getTabFromPathName(pathName) {
  if (pathName.endsWith("overview")) {
    return 0;
  }
  if (pathName.endsWith("details")) {
    return 1;
  }
  if (pathName.endsWith("offers")) {
    return 2;
  }
  return 0;
}
