import React from "react";
import errorPersonSvg from "../../../assets/svg/error-img.svg";
import { Box, useTheme } from "@mui/material";
import MainTitle from "./MainTitle";
import SolidRoundedButton from "./SolidRoundedButton";
import { useRouteError, useNavigate } from "react-router-dom";
import { getErrorMsgTitle } from "../../../common/utils/miscUtils";

const classes = {
  errorBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5rem",
    maxHeight: "100vh",
  },
};
const ShowErrorComponent = ({ providedErrMsg }) => {
  const navigate = useNavigate();
  const handleReset = () => {
    // window.location.href = "/";
    navigate(0);
  };

  const theme = useTheme();

  const errorBtnStyles = {
    borderRadius: "5px",
    textTransform: "none",
    backgroundColor: theme.palette.error.main,
    color: "white",
    padding: "1px 10px",
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  };

  const error = useRouteError();
  const { message: errMessage, title: errTitle } = getErrorMsgTitle(error);

  return (
    <Box sx={classes.errorBox}>
      <img
        src={errorPersonSvg}
        alt="error"
        style={{ width: "40%", height: "300px", marginBottom: "1rem" }}
      />
      <MainTitle title={errTitle} sx={{ fontSize: "32px" }} />
      <pre>{errMessage}</pre>
      <SolidRoundedButton onClickHandler={handleReset} styles={errorBtnStyles}>
        Try Again
      </SolidRoundedButton>
    </Box>
  );
};

export default ShowErrorComponent;
