import React from "react";

const Notification = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 32">
        <path
          id="Vector"
          d="M36.4998 31.2556L36.122 30.9222C35.0503 29.9673 34.1122 28.8723 33.3331 27.6667C32.4823 26.0028 31.9723 24.1858 31.8331 22.3222V16.8333C31.8286 16.1666 31.7691 15.5014 31.6553 14.8445C29.7732 14.4576 28.0827 13.4323 26.8698 11.942C25.6569 10.4517 24.9963 8.58811 24.9998 6.66667V5.96667C23.8397 5.39577 22.5933 5.02035 21.3109 4.85556V3.45556C21.3109 3.06216 21.1546 2.68487 20.8764 2.40669C20.5983 2.12851 20.221 1.97223 19.8276 1.97223C19.4342 1.97223 19.0569 2.12851 18.7787 2.40669C18.5005 2.68487 18.3442 3.06216 18.3442 3.45556V4.91112C15.4728 5.31618 12.8449 6.74639 10.9456 8.93771C9.0463 11.129 8.00393 13.9335 8.01089 16.8333V22.3222C7.87173 24.1858 7.36176 26.0028 6.51089 27.6667C5.74521 28.8693 4.82222 29.9642 3.76645 30.9222L3.38867 31.2556V34.3889H36.4998V31.2556Z"
          fill="#092C4C"
        />
        <path
          id="Vector_2"
          d="M33.3334 12.2222C36.4016 12.2222 38.8889 9.73492 38.8889 6.66667C38.8889 3.59842 36.4016 1.11111 33.3334 1.11111C30.2651 1.11111 27.7778 3.59842 27.7778 6.66667C27.7778 9.73492 30.2651 12.2222 33.3334 12.2222Z"
          fill="#D50000"
        />
      </g>
    </svg>
  );
};

export default Notification;
