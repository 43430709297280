import { dtypeValidationPreProcessor } from "../../gridValueTransformers";
import EditCellRenderer from "../../EditCellRenderer";
import ReadCellRenderer from "../../ReadCellRenderer";

const LINKS_DELIM = ";";

export const singleRecTblCols = [
  {
    field: "display_name",
    headerName: "",
    type: "string",
    width: 250,
    editable: false,
    cellClassName: "key-cell",
  },
  {
    field: "value",
    headerName: "",
    width: 550,
    flex: 1,
    editable: true,
    type: "custom",
    preProcessEditCellProps: (params) => {
      return dtypeValidationPreProcessor(params);
    },
    renderEditCell: (params) => {
      return <EditCellRenderer {...params} />;
    },
    renderCell: (params) => {
      return <ReadCellRenderer {...params} />;
    },
  },
];

export function getTblFieldsConf(tableAlias, fieldsConfig) {
  return fieldsConfig.filter(
    (field) =>
      field.table_alias === tableAlias && field.is_in_prop_lead_details === true
  );
}

export function getRowsForTbl(tblFieldsConf, recordData) {
  return tblFieldsConf.map((field, _) => ({
    ...field,
    value: recordData[field.api_key],
    data: recordData,
  }));
}
