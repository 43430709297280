import ShowValue from "../ShowValue";
import GMapsStreetViewRenderer from "./GMapsStreetViewRenderer";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import {
  D_MDY_DATE,
  D_MDY_HMS_A_DATETIME,
  DEFAULT_TIMEZONE,
} from "../../../../common/utils/constants";
import { ReadLinkArrayRenderer } from "./LinkRenderers";
import { isMissingLike } from "./table/common/util";
import { ReadRelationRenderer } from "./cellRenderer/relationCellRenderer";
import SanitizeAndScrollText from "../../../../multiPropertyView/SanitizeAndScrollText";

dayjs.extend(timezone);
dayjs.extend(utc);

export default function ReadCellRenderer(props) {
  const { row, value } = props;
  const { api_key, dtype, validation, category_values, formatting } = row;
  const { missing_value_rep: missingValueRep = null } = validation;
  if (validation && isMissingLike(value) && !validation?.is_allow_missing) {
    console.warn(
      `${row.field_id} is missing, but validation declares it as required`
    );
  }

  // TODO: support displayName for categorical fields in render as well
  switch (dtype) {
    case "string":
    case "float":
    case "int":
    case "decimal":
    case "category":
      if (isMissingLike(value)) {
        return <ShowValue value={missingValueRep} />;
      }
      return formatting?.type === "text_multiline" &&
        api_key === "listing_description" ? (
        <SanitizeAndScrollText text={value} />
      ) : (
        value
      );
    case "boolean":
      if ([null, NaN, undefined, ""].includes(value)) {
        return <ShowValue value={missingValueRep} />;
      }
      return value === 1 ? "Yes" : "No";

    case "datetime":
      if (isMissingLike(value)) {
        return <ShowValue value={missingValueRep} />;
      }
      return dayjs.utc(value).tz(DEFAULT_TIMEZONE).format(D_MDY_HMS_A_DATETIME);
    case "date":
      if (isMissingLike(value)) {
        return <ShowValue value={missingValueRep} />;
      }
      return dayjs.utc(value).tz(DEFAULT_TIMEZONE).format(D_MDY_DATE);

    case "links":
      return <ReadLinkArrayRenderer {...props} />;

    case "relation":
      return <ReadRelationRenderer {...props} />;
    case "special":
      if (row.formatting?.type === "gmaps_api_street_view") {
        return (
          <GMapsStreetViewRenderer
            longitude={row.leadDetails?.longitude}
            lattitude={row.leadDetails?.latitude}
          />
        );
      }
      return null;
    default:
      return null;
  }
}
