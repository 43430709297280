import React from "react";
import { Button } from "@mui/material";

const SolidRoundedButton = ({ children, styles, ...otherProps }) => {
  return (
    <Button sx={styles} disableRipple {...otherProps}>
      {children}
    </Button>
  );
};

export default SolidRoundedButton;
