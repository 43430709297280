import { Box, Button, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateGroupBySubViewSelected,
  updatePaginationModelState,
} from "../redux-slices/viewsSlice";
import { upsertFilter } from "../redux-slices/advancedSearchSlice";
import NormalText from "../../component/SinglePropertyPage/common/NormalText";
import CountIcon from "../countIcon";
import { ViewListSkeleton } from "./ViewsList";

export default function GroupByDropdown({
  currentSubGroupby,
  setCurrentSubView,
  loading,
  data,
  singleViewLoading,
}) {
  const [itemsToShow, setItemsToShow] = useState(5);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { groupByCol, results } = data || {};

  const handleSelectGroupBy = async (field) => {
    setCurrentSubView(field);
    dispatch(updateGroupBySubViewSelected(true));
    dispatch(
      upsertFilter({
        field: groupByCol,
        values: [field[groupByCol]],
        filterType: "category",
      })
    );

    dispatch(
      updatePaginationModelState({
        page: 0,
        pageSize: 100,
      })
    );
  };

  return (
    <Box sx={{ mt: "5px" }}>
      {loading ? (
        <ViewListSkeleton />
      ) : (
        <Box sx={{ mx: "10px" }}>
          {!singleViewLoading &&
            results?.slice(0, itemsToShow).map((field) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  position: "relative",
                }}
                key={field[groupByCol]}
              >
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    cursor: "pointer",
                    mb: "4px",
                    color:
                      currentSubGroupby[groupByCol] === field[groupByCol]
                        ? theme.palette.primary.main
                        : theme.palette.text.secondary,
                    fontWeight:
                      currentSubGroupby[groupByCol] === field[groupByCol]
                        ? "bold"
                        : "normal",
                  }}
                  onClick={
                    field[groupByCol] !== currentSubGroupby[groupByCol]
                      ? () => handleSelectGroupBy(field)
                      : () => {}
                  }
                  text={field[groupByCol]}
                >
                  {field[groupByCol]}
                </Typography>

                <CountIcon
                  extraStyles={{
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                    top: "8px",
                    right: "5px",
                  }}
                >
                  {field?.count}
                </CountIcon>
              </Box>
            ))}
          {itemsToShow < results?.length && (
            <Button
              onClick={() => setItemsToShow((prev) => prev + 5)} // Show 5 more items
              sx={{
                p: 0,
                textTransform: "capitalize",
                fontSize: "0.875rem",
                textAlign: "left",
              }}
              variant="text"
              color="primary"
            >
              Show More
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}
