import { useMemo } from "react";
import {
  getTblFieldsConf as getMultiRecTblConf,
  getColsForTbl,
} from "../../component/SinglePropertyPage/common/muixDataGrid/table/mutiRecordTable/utils";
import { Box, useTheme } from "@mui/material";
import { TableToolbar } from "../../component/SinglePropertyPage/common/muixDataGrid/tableToolbar/Toolbar";
import { MinimalTbl } from "../../component/SinglePropertyPage/common/muixDataGrid/table";
import { AddNewBtn } from "../../component/SinglePropertyPage/common/muixDataGrid/actionButton/commonButtons";
import { GridActionsCellItem } from "@mui/x-data-grid";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { SingleRelDataTbl } from "./SingleRecTbl";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTableById,
  tableDataResetToInit,
  TBL_SCOPE,
} from "../leadUnderwritingSlice";
import { getPropRelRecord } from "../leadUnderwritingApi";
import { TBL_ALIAS } from "../../common/utils/constants";
import { SingleRehabTable } from "./SingleRehabTable";

export function getMultiTblWithSingleRec(tableAlias, fieldsConfig) {
  const tblFieldsConf = getMultiRecTblConf(tableAlias, fieldsConfig);
  const dataCols = getColsForTbl(tblFieldsConf);

  return function MultiTblWithSingleRec({
    id,
    ownUser,
    property_id,
    updatePatchRef,
    ctnRef,
  }) {
    const dispatch = useDispatch();
    const isSingleRecVisible = useSelector(
      (state) =>
        selectTableById(state, tableAlias)["tableScope"] === TBL_SCOPE.singleRec
    );
    const theme = useTheme();
    const {
      data: tableRecords,
      isLoading: isTableRecLoading,
      isFetching: isTableRecFetching,
    } = getPropRelRecord.useQueryState({
      leadId: id,
      propertyId: property_id,
      tableAlias,
    });

    const handleCloseSingleRec = async () => {
      updatePatchRef.current[tableAlias] = {};
      dispatch(tableDataResetToInit({ id: tableAlias, changes: {} }));
    };

    const handleAddNewSingleRec = () => {
      dispatch(
        tableDataResetToInit({
          id: tableAlias,
          changes: {
            tableScope: TBL_SCOPE.singleRec,
            workingEntityId: null,
          },
        })
      );
    };

    const cols = useMemo(() => {
      const openSingleRecCol = {
        field: "actions",
        type: "actions",
        width: 8,
        maxWidth: 8,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<OpenInFullIcon fontSize="small" />}
            label="Open Record Details"
            onClick={() => {
              dispatch(
                tableDataResetToInit({
                  id: tableAlias,
                  changes: {
                    tableScope: TBL_SCOPE.singleRec,
                    workingEntityId: params.row.id,
                  },
                })
              );
            }}
          />,
        ],
      };
      return [openSingleRecCol, ...dataCols];
    }, [dispatch]);

    const tblSx = useMemo(
      () => ({
        "& .MuiDataGrid-cell": {
          pb: 2,
        },
        "& .key-cell": {
          borderRight: "1px solid",
          borderColor: theme.palette.divider,
        },
      }),
      [theme]
    );

    return (
      <Box>
        {!isSingleRecVisible ? (
          <>
            <TableToolbar
              leftButtons={[
                <AddNewBtn
                  key="left_add_new"
                  onClick={() => handleAddNewSingleRec()}
                />,
              ]}
            />
            <MultiRecTbl
              records={tableRecords?.data}
              cols={cols}
              loading={isTableRecLoading || isTableRecFetching}
              slotProps={{
                loadingOverlay: {
                  variant: "linear-progress",
                  noRowsVariant: "skeleton",
                },
              }}
              getRowHeight={() => "50px"}
              sx={tblSx}
            />
          </>
        ) : (
          <SingleRecTblSwitch
            {...{
              tableAlias,
              ownUser,
              handleClose: handleCloseSingleRec,
              id,
              property_id,
              updatePatchRef,
            }}
          />
        )}
      </Box>
    );
  };
}

function SingleRecTblSwitch(props) {
  const { tableAlias } = props;
  switch (tableAlias) {
    case TBL_ALIAS.rehab:
      return <SingleRehabTable {...props} />;

    default:
      return <SingleRelDataTbl {...props} />;
  }
}

export function MultiRecTbl({
  records,
  cols,
  allowPagination = false,
  ...restProps
}) {
  const theme = useTheme();
  const tblSx = useMemo(
    () => ({
      "& .MuiDataGrid-cell": {
        py: 2,
      },
      "& .key-cell": {
        borderRight: "1px solid",
        borderColor: theme.palette.divider,
      },
    }),
    [theme]
  );

  return (
    <MinimalTbl
      rows={Array.isArray(records) ? records : []}
      columns={cols}
      sx={tblSx}
      allowPagination={allowPagination}
      {...restProps}
    />
  );
}
