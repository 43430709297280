import useResizeObserver from "../../../common/utils/customHooks/imageGallery/useResizeObserever";
import React, { useCallback, useEffect, useRef } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { FixedSizeList } from "react-window";
import ImgWithFallback from "../../img/ImgWithFallback";
import FallbackImgBrokenIcon from "../../../assets/SinglePropertyPage/fallbackImgBrokenIcon.svg";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import MinimalIconButtonWithToolTip from "../../button/MinimalIconButtonWithTooltip";

function LeadImages({
  images,
  mainImgIndex,
  setMainImgIndex, // To update main image index
  firstIndex,
  handleThumbImgClick,
  ctnStyle,
  imgListRef,
  listProps,
}) {
  const containerRef = useRef(null);
  const { width } = useResizeObserver(containerRef);
  const theme = useTheme();

  const scrollToIndex = useCallback(
    (index, align = "auto") => {
      if (imgListRef?.current && index >= 0) {
        imgListRef.current.scrollToItem(index, align);
      }
    },
    [imgListRef]
  );

  const highlightColor = theme.palette.primary.light;

  const Thumbnail = ({ index, style }) => (
    <div style={style}>
      <div style={{ margin: "0 2px", height: "100%", display: "relative" }}>
        <ImgWithFallback
          src={images[index]}
          alt={`Thumbnail ${index}`}
          onClick={() => handleThumbImgClick(index)}
          style={{
            display: "block",
            maxWidth: "100%",
            height: "100%",
            margin: "auto",
            cursor: "pointer",
            objectFit: "cover",
            border:
              mainImgIndex === index
                ? `3px solid ${highlightColor}`
                : "1px solid lightgrey",
          }}
          fallback={
            <img
              src={FallbackImgBrokenIcon}
              alt="not available"
              style={{
                display: "block",
                maxWidth: "100%",
                maxHeight: "100%",
                width: "100%",
                margin: "auto",
                objectFit: "cover",
              }}
            ></img>
          }
        />
        <Typography
          style={{
            position: "absolute",
            bottom: 2,
            left: 4,
            color: "white",
            backgroundColor: theme.palette.text.primary,
            fontSize: "0.75rem",
            padding: "2px",
          }}
        >
          {index + 1}
        </Typography>
      </div>
    </div>
  );

  const isVisible = width > 0;
  useEffect(() => {
    if (
      imgListRef?.current &&
      isVisible &&
      firstIndex &&
      firstIndex?.current !== null
    ) {
      scrollToIndex(firstIndex.current, "auto");
    }
  }, [imgListRef, firstIndex, isVisible, scrollToIndex]);

  const handleNextImage = () => {
    if (mainImgIndex < images.length - 1) {
      setMainImgIndex(mainImgIndex + 1);
      scrollToIndex(mainImgIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (mainImgIndex > 0) {
      setMainImgIndex(mainImgIndex - 1);
      scrollToIndex(mainImgIndex - 1);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        handleNextImage();
      } else if (event.key === "ArrowLeft") {
        handlePrevImage();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [mainImgIndex, handleNextImage, handlePrevImage]);

  return (
    <Box
      ref={containerRef}
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        gap: "10px",
        justifyContent: "space-evenly",
        minWidth: "380px",
        ...ctnStyle,
      }}
    >
      {/* Main Image Section with Navigation Arrows */}
      <Box sx={{ position: "relative", width: "100%", height: "75%" }}>
        <MinimalIconButtonWithToolTip
          sx={{
            position: "absolute",
            top: "50%",
            left: "10px",
            zIndex: 2,
            "&:disabled": {
              cursor: "not-allowed",
              pointerEvents: "all !important",
            },
          }}
          onClick={handlePrevImage}
          disabled={mainImgIndex === 0}
          toolTipLabel={"Prev"}
        >
          <ArrowBack
            fontSize="small"
            sx={{
              padding: "10px",
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.grey[800],
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
            }}
          />
        </MinimalIconButtonWithToolTip>
        <ImgWithFallback
          src={images[mainImgIndex]}
          alt="Main property"
          style={{
            display: "block",
            margin: "auto",
            maxWidth: "100%",
            maxHeight: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          key={`${mainImgIndex}/${images[mainImgIndex]}`}
          onDoubleClick={() => scrollToIndex(mainImgIndex, "auto")}
          fallback={
            <img
              src={FallbackImgBrokenIcon}
              alt="not available"
              style={{
                display: "block",
                margin: "auto",
                maxWidth: "100%",
                maxHeight: "100%",
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          }
        />
        <MinimalIconButtonWithToolTip
          sx={{
            position: "absolute",
            top: "50%",
            right: "10px",
            zIndex: 2,
            "&:disabled": {
              cursor: "not-allowed",
              pointerEvents: "all !important",
            },
          }}
          toolTipLabel={"Next"}
          onClick={handleNextImage}
          disabled={mainImgIndex === images.length - 1}
        >
          <ArrowForward
            fontSize="small"
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.grey[800],
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          />
        </MinimalIconButtonWithToolTip>
      </Box>

      {/* Thumbnail List */}
      <FixedSizeList
        ref={imgListRef}
        itemData={images}
        itemKey={(index, data) =>
          `${index}/${
            Array.isArray(data) && data.length > index ? data[index] : ""
          }`
        }
        height={120}
        itemCount={images.length}
        itemSize={120}
        layout="horizontal"
        width={width}
        {...listProps}
      >
        {Thumbnail}
      </FixedSizeList>
    </Box>
  );
}

export default LeadImages;
