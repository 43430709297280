import { propLeadApiSlice } from "./propertyDetailsApi";

const BASE_URL_SUFFIX = "/users";

const userTableApi = propLeadApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserSearchName: builder.query({
      query: ({ query, limit = 20 }) => ({
        url: `${BASE_URL_SUFFIX}/search-name`,
        params: { query, limit },
      }),
    }),

    getUserAuthById: builder.query({
      query: (id) => `${BASE_URL_SUFFIX}/${id}`,
      keepUnusedDataFor: 1800,
    }),

    getOwnUserAuth: builder.query({
      query: () => `${BASE_URL_SUFFIX}/my-user`,
      keepUnusedDataFor: 1800,
    }),
  }),
});

export const {
  useGetUserSearchNameQuery,
  useLazyGetUserSearchNameQuery,
  useGetUserAuthByIdQuery,
  useGetOwnUserAuthQuery,
} = userTableApi;
