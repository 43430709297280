import _ from "lodash";

const dateTimeOptions = {
  timeZone: "America/New_York",
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: false,
};

const dateOptions = {
  timeZone: "America/New_York",
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

const R2G_DIV_COLOR_SCHEME = [
  "#ebebeb",
  "#ffd6c9",
  "#ffe7d1",
  "#feffd6",
  "#fff8b8",
  "#e0ffcc",
  "#caf2c2",
  "#d8edec",
];

const dateTimeFormat = Intl.DateTimeFormat("en-US", dateTimeOptions);
const dateFormat = Intl.DateTimeFormat("en-US", dateOptions);

export const defaultFormatters = [noOpFormatter];

export const dtypeToFormatters = {
  datetime: [...defaultFormatters, dateTimeFormatter],
  date: [...defaultFormatters, dateFormatter],
  tag: [SpanCtnFormatter],
  percent: [percentFormatter],
  decimal: [decimalFormatter],
  moneyUsd: [moneyUsdFormatter],
};

export function noOpFormatter(input, extraArgs) {
  if (!extraArgs) {
    return input;
  }
  const { row, key } = extraArgs;
  if (!(row && key && row[key])) {
    return input;
  }
  let res = row;
  for (const k of key.split(".")) {
    res = res[k];
    if (res === null) {
      break;
    }
  }
  return res;
}
export function missingValueFormatter(input) {
  let text;
  if (input === "") {
    text = "EMPTY";
  } else if (input === undefined) {
    text = "NA";
  } else if (input === null) {
    text = "NULL";
  } else if (_.isNaN(input)) {
    text = "NaN";
  } else if (typeof input === "string" || typeof input === "number") {
    text = input;
  } else {
    throw new TypeError("invalid input type, failed to format");
  }
  return text;
}

/** Datetime */
export function dateTimeFormatter(input, includeTime = false) {
  if (!input) return null;
  return dateTimeFormat.format(new Date(input));
}

export function dateFormatter(input) {
  if (!input) return null;
  return dateFormat.format(new Date(input));
}

/** Numeric */
export function percentFormatter(input) {
  if (!input) return null;
  return (parseFloat(input) * 100).toFixed(2);
}
export function decimalFormatter(input) {
  if (!input) return null;
  return parseFloat(input).toFixed(2);
}

/** Money */
export function moneyUsdFormatter(input) {
  const floatVal = parseFloat(input);
  if (!_.isFinite(floatVal)) return null;
  return floatVal.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

/** Cosmetic */
export function SpanCtnFormatter(input, { field_id, categoryValues }) {
  let colorPaletteIndex = undefined;
  colorPaletteIndex = categoryValues?.find(
    (cat) => cat.categoryValues === input
  )?.colorPaletteIndex;
  const spanStyle = {
    backgroundColor:
      colorPaletteIndex === undefined
        ? undefined
        : R2G_DIV_COLOR_SCHEME[colorPaletteIndex + 1],
    padding: "2px 3px",
    border: "1px solid var(--rdg-border-color)",
  };
  return !input || <span style={spanStyle}>{input}</span>;
}

export const tableFormatters = [dateTimeFormatter];
