import { Box, Typography, useTheme } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import {
  selectFilterById,
  removeFilter,
} from "../../redux-slices/advancedSearchSlice";
import CustomDateTimeFilter from "./CustomDateTimeFilter";
import RelativeDateTimeFilter from "./RelativeDateTimeFilter";
import SnglAndMltiSelectComponent from "../../../component/input/SingleSelect";
import MinimalIconButtonWithToolTip from "../../../component/button/MinimalIconButtonWithTooltip";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const dateModeOptions = ["custom", "relative"];

const DateFilter = ({ filter, readonly = false }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const filterState =
    useSelector((state) => selectFilterById(state, filter["api_key"])) || {};

  const [dateMode, setDateMode] = useState(
    filterState.filterType && filterState.filterType === "relative_datetime"
      ? "relative"
      : "custom"
  );

  const handleChangeDateMode = (event) => {
    setDateMode(event.target.value);

    dispatch(removeFilter({ field: filter["api_key"] }));
  };

  useEffect(() => {
    setDateMode(
      filterState.filterType && filterState.filterType === "relative_datetime"
        ? "relative"
        : "custom"
    );
  }, [filter]);

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderTop: `1px solid ${theme.palette.divider}`,
          borderBottom: `1px solid ${theme.palette.divider}`,
          px: 1,
          py: "5px",
        }}
      >
        <Box>
          <Typography variant="body2">
            {filter.display_name || filter.api_key}
          </Typography>
        </Box>
        <Box
          sx={{
            "& .MuiSelect-select": {
              padding: "5px",
            },
            display: "flex",
          }}
        >
          <SnglAndMltiSelectComponent
            key={filter["api_key"]}
            value={dateMode}
            multiple={false}
            options={dateModeOptions}
            readyonly={readonly}
            displayEmpty={false}
            selectProps={{
              variant: "outlined",
              onChange: (event) => handleChangeDateMode(event),
              disabled: readonly,
            }}
            sx={{
              mr: readonly ? "1rem" : "0",
            }}
          />
          {readonly && (
            <MinimalIconButtonWithToolTip
              toolTipLabel={"Remove filter"}
              onClick={() =>
                dispatch(removeFilter({ field: filter["api_key"] }))
              }
            >
              <DeleteOutlineIcon />
            </MinimalIconButtonWithToolTip>
          )}
        </Box>
      </Box>

      {dateMode === "relative" ? (
        <RelativeDateTimeFilter filter={filter} readonly={readonly} />
      ) : (
        <CustomDateTimeFilter filter={filter} readonly={readonly} />
      )}
    </Box>
  );
};

export default DateFilter;
