import { useState } from "react";
import ImgWithLoading from "./ImgWithLoading";

function ImgWithFallback({ src, alt, style, fallback, ...imgProps }) {
  const [hasError, setHasError] = useState(false);

  return hasError ? (
    fallback
  ) : (
    <ImgWithLoading
      src={src}
      alt={alt}
      style={style}
      {...imgProps}
      onError={() => setHasError(true)}
    />
  );
}

export default ImgWithFallback;
