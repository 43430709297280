import { propLeadApiSlice } from "../../lib/propertyDetailsApi";

const BASE_URL_SUFFIX = "/aws-s3";

const attachmentApi = propLeadApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generateAttachment: builder.mutation({
      query: (payload) => ({
        url: `${BASE_URL_SUFFIX}/generate-signed-url`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useGenerateAttachmentMutation } = attachmentApi;
