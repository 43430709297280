import { Box } from "@mui/material";
import { useState } from "react";
import SliderLabel from "./SliderLabel";
import EditableTextField from "./EditableTextField";
import SliderValue from "./SliderValue";
import SliderInput from "./Slider";
import TextButton from "./TextButton";

const SliderComponent = ({
  label,
  value,
  setValue,
  min,
  max,
  step,
  format,
  setShowSaveButton,
  prefix,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleSliderValueClick = () => {
    setIsEditing(true);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SliderLabel label={label} />
        {isEditing ? (
          <EditableTextField
            inputValue={inputValue}
            setInputValue={setInputValue}
            setValue={setValue}
            setIsEditing={setIsEditing}
            setShowSaveButton={setShowSaveButton}
          />
        ) : (
          <TextButton onClick={handleSliderValueClick}>
            <SliderValue
              value={format ? format(value) : value}
              prefix={prefix}
            />
          </TextButton>
        )}
      </Box>
      <SliderInput
        value={value}
        setValue={setValue}
        setInputValue={setInputValue}
        min={min}
        max={max}
        step={step}
        setShowSaveButton={setShowSaveButton}
      />
    </Box>
  );
};

export default SliderComponent;
