import React, { useEffect, useRef } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useGridApiContext } from "@mui/x-data-grid";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  DEFAULT_TIMEZONE,
  YMD_DATE_FORMAT,
} from "../../../../common/utils/constants";
import { isMissingLike } from "./table/common/util";

dayjs.extend(utc);

export default function EditDateTimeCellRenderer(props) {
  const { id, value, row, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const inputRef = useRef(null);
  const { missing_value_rep: missingValueRep = null } = row.validation;

  useEffect(() => {
    if (hasFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [hasFocus]);

  const handleDateChange = (newDateTime) => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: isMissingLike(newDateTime)
        ? missingValueRep
        : newDateTime.format(YMD_DATE_FORMAT),
    });
  };

  const dateTime = value ? dayjs.utc(value) : null;

  return (
    <DateTimePicker
      value={dateTime}
      onChange={handleDateChange}
      timezone={DEFAULT_TIMEZONE}
    />
  );
}
