import { Skeleton } from "@mui/material";


export const FetchingRevisionSkeleton = () => {
	return <>
		<Skeleton
			animation={ 'wave' }
			width={ '98%' }
			height={ '30px' }
			sx={{
				mt: '10px'
			}}
		/>
	</>;
};


export const FetchingAllRevisionsSkeleton = (props) => {
	const { count } = props;

	return <>
		{Array.from({ length: count }).map((_, index) => (
			<Skeleton
				key={ index }
				animation={ 'wave' }
				variant={ "rounded" } 
				width={ "98%" } 
				height={ "30px" }
				sx={{
					mt: '10px'
				}}
			/>
		))}
	</>
};