import { Avatar } from "@mui/material";

export function UserAvatarDefault({ displayName, avatarProps={} }) {
  const isDisplayName = displayName && displayName.length >= 2;
  const initial = isDisplayName ? getInitials(displayName) : "AA";
  const color = isDisplayName ? stringToColor(displayName) : "#000000";

  const { sx={}, ...restProps } = avatarProps;
  return (
    <Avatar sx={{ bgcolor: color, width: "32px", height: "32px", fontSize: "0.875rem", ...sx }} {...restProps}>
      {initial}
    </Avatar>
  );
}



// REF: https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += value.toString(16).padStart(2, "0");
  }
  /* eslint-enable no-bitwise */

  return color;
}

function getInitials(string) {
  const arr = string.split(" ", 2);
  if (arr.length < 2) return arr.slice(0, 2);
  return arr[0][0] + arr[1][0];
}
