import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

// default filter properties to add in the filter object
const defaultFilterProperties = {
  includeUnset: false,
  negate: false,
  status: true,
};

const filtersAdapter = createEntityAdapter({
  selectId: (filter) => filter.field,
});

const sortsAdapter = createEntityAdapter({
  selectId: (sort) => sort.field,
});

const buyboxAdapter = createEntityAdapter({
  selectId: (buybox) => buybox,
});

const initialState = {
  filtersEntity: filtersAdapter.getInitialState(),
  sortsEntity: sortsAdapter.getInitialState(),
  buyboxEntity: buyboxAdapter.getInitialState(),
};

const advancedSearchSlice = createSlice({
  name: "advancedSearch",
  initialState,
  reducers: {
    // Using Upsert instead of AddOne it will add a new filter and update the existing filter values
    upsertFilter: (state, action) => {
      const { ...rest } = action.payload;
      // Merge with default properties
      const newFilter = { ...defaultFilterProperties, ...rest };
      filtersAdapter.upsertOne(state.filtersEntity, newFilter);
    },

    removeFilter: (state, action) => {
      const { field } = action.payload;
      filtersAdapter.removeOne(state.filtersEntity, field);
    },

    setFilters: (state, action) => {
      const newFilters = action.payload;
      filtersAdapter.setAll(state.filtersEntity, newFilters);
    },

    addSort: (state, action) => {
      const { field, order } = action.payload;
      sortsAdapter.upsertOne(state.sortsEntity, { field, order });
    },

    removeSort: (state, action) => {
      const { field } = action.payload;
      sortsAdapter.removeOne(state.sortsEntity, field);
    },

    setSorts: (state, action) => {
      const newSorts = action.payload;
      sortsAdapter.setAll(state.sortsEntity, newSorts);
    },

    setBuyBoxes: (state, action) => {
      buyboxAdapter.setAll(state.buyboxEntity, action.payload);
    },

    upSertBuyBox: (state, action) => {
      buyboxAdapter.upsertOne(state.buyboxEntity, action.payload);
    },

    removeBuybox: (state, action) => {
      sortsAdapter.removeOne(state.buyboxEntity, action.payload);
    },
  },
});

export const { selectAll: selectAllFilters, selectById: selectFilterById } =
  filtersAdapter.getSelectors((state) => state.advancedSearch.filtersEntity);

export const { selectAll: selectAllSorts, selectById: selectSortById } =
  sortsAdapter.getSelectors((state) => state.advancedSearch.sortsEntity);

export const { selectAll: selectAllBuyBoxes, selectById: selectBuyBoxById } =
  buyboxAdapter.getSelectors((state) => state.advancedSearch.buyboxEntity);

export const {
  upsertFilter,
  removeFilter,
  addSort,
  removeSort,
  clearFilters,
  setFilters,
  setSorts,
  upSertBuyBox,
  setBuyBoxes,
  removeBuybox,
} = advancedSearchSlice.actions;

export default advancedSearchSlice.reducer;
