import React from "react";
import PropertyDetails from "../../component/SinglePropertyPage/PropertyDetails";
import PropertyHistoryTable from "../../component/SinglePropertyPage/HistoryTable";
import {
  getLatestCalcData,
  useGetLeadWithRelatedDataQuery,
} from "../../lib/propertyDetailsApi";
import { useParams } from "react-router-dom";
import OverviewSkeleton from "../../component/SinglePropertyPage/Overview/skeleton/OverviewSkeleton";

const PropertyOverview = () => {
  const { uid } = useParams();
  const { data: leadDetails } = useGetLeadWithRelatedDataQuery({ id: uid });
  // const {
  //   data: lead,
  //   isLoading,
  //   isFetching,
  //   isError,
  //   error,
  //   isSuccess,
  //   isUninitialized,
  // } = useGetLeadWithRelatedDataQuery({
  //   id: uid,
  // });

  // const {
  //   isSuccess: isCalcDataSuccess,
  //   isLoading: isCalcDataLoading,
  //   isError: isCalcDataError,
  // } = getLatestCalcData.useQuery({
  //   leadId: leadDetails.id,
  //   propertyId: leadDetails["property_id"],
  // });

  // const isDataPresent = isCalcDataSuccess && !isCalcDataError;

  // fulfilledTimeStamp is only there if a request succeeded without error else undefined
  // const isRequestFinished = !(isUninitialized || isLoading || isFetching);

  // if ((!isDataPresent || isCalcDataError) && isRequestFinished) {
  //   if (isCalcError) {
  //     throw isCalcError;
  //   }
  //   throw new Error("Error occured while trying to get lead data");
  // }

  // return (
  //   <div>
  //     {isCalcDataLoading ? (
  //       <OverviewSkeleton />
  //     ) : isDataPresent ? (
  //       <>
  //         <PropertyDetails lead={leadDetails} />
  //         <PropertyHistoryTable propLeadID={uid} />
  //       </>
  //     ) : null}
  //   </div>
  // );

  return (
    <div>
      <PropertyDetails lead={leadDetails} />
      <PropertyHistoryTable propLeadID={uid} />
    </div>
  );
};

export default PropertyOverview;
