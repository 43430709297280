import React, { useState } from "react";
import { Box, Grid, Card, Tooltip, useTheme } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import SliderComponent from "./common/SliderComponent";
import H3 from "./common/H3";
import SectionTitle from "./common/SectionTitle";
import TextButton from "./common/TextButton";
import SolidRoundedButton from "./common/SolidRoundedButton";
import {
  resetAssumptions,
  selectCashFlowAssumptions,
  updateAssumption,
} from "../../store/reducers/singlePropPageReducer";
import NormalText from "./common/NormalText";

const classes = {
  paper: {
    width: "80%",
    margin: 2,
    padding: "5px",
    borderRadius: "8px",
    position: "absolute",
    bottom: "100px",
    left: 100,
    boxShadow: 2,
  },
  section: {
    padding: "10px",
  },
};

const sections = [
  {
    title: "Investment",
    items: [
      {
        key: "rentalAppreciation",
        label: " Rent Appreciation Rate",
        prefix: "percent",
        min: 0,
        max: 10,
        step: 0.1,
        Tooltip:
          "Rent appreciation refers to the percentage increase in rental income over time, influenced by market demand, property improvements, and economic factors.",
      },
      {
        key: "valueAppreciation",
        label: " Value Appreciation Rate",
        prefix: "percent",
        min: 0,
        max: 10,
        step: 0.1,
        Tooltip:
          "Value appreciation rate is the percentage increase in the property’s value over time, reflecting market trends and the property's potential for investment growth.",
      },
      {
        key: "purchaseDiscount",
        label: " Purchase Discount",
        prefix: "percent",
        min: 0,
        max: 100,
        step: 0.1,
        Tooltip: "",
      },
      {
        key: "closingCost",
        label: " Closing Cost (% Purchase)",
        prefix: "percent",
        min: 0,
        max: 5,
        step: 0.1,
        Tooltip:
          "Closing costs are fees associated with the purchase of a property, expressed as a percentage of the purchase price, covering expenses such as appraisal fees, title insurance, and attorney fees.",
      },
      {
        key: "costsaleexit",
        label: " Exit Cost (% Sale)",
        prefix: "percent",
        min: 0,
        max: 10,
        step: 0.1,
        Tooltip:
          "Exit cost refers to the expenses incurred when selling a property, expressed as a percentage of the sale price, including agent commissions, closing costs, and other related fees.",
      },
    ],
  },
  {
    title: "Debt",
    items: [
      {
        key: "debtPercentage",
        label: "Loan To Value",
        prefix: "percent",
        min: 0,
        max: 100,
        step: 1,
        Tooltip:
          "Loan to Value (LTV) is a financial term used by lenders to express the ratio of a loan to the value of the property purchased, indicating the level of risk associated with the loan.",
      },
      {
        key: "mortgageInterest",
        label: "Mortgage Rate",
        prefix: "percent",
        min: 0,
        max: 10,
        step: 0.1,
        Tooltip:
          "The mortgage rate is the interest rate charged on a mortgage loan, expressed as a percentage, which determines the cost of borrowing and affects monthly payments.",
      },
      {
        key: "mortgagePeriod",
        label: " Amortization Period",
        prefix: "years",
        min: 15,
        max: 50,
        step: 1,
        Tooltip:
          "The amortization period is the total length of time over which the loan will be repaid, typically expressed in years.",
      },
      {
        key: "mortgagefees",
        label: "Mortgage Origination Cost",
        prefix: "percent",
        min: 0,
        max: 5,
        step: 0.1,
        Tooltip:
          "Origination points are fees charged by lenders for processing a new loan application, typically expressed as a percentage of the total loan amount.",
      },
    ],
  },
  {
    title: "Tenant",
    items: [
      {
        key: "tenantStay",
        label: " Average Tenant Stay (yrs)",
        prefix: "years",
        min: 0,
        max: 10,
        step: 0.5,
        Tooltip:
          "Average tenant stay refers to the typical duration, in years, that tenants stays in the rental property before moving out.",
      },
      {
        key: "turnTime",
        label: " Average Turn Time (Months)",
        prefix: "months",
        min: 0,
        max: 6,
        step: 0.5,
        Tooltip:
          "Average turn time is the typical number of months it takes to prepare a rental unit between tenants and finding a new tenant.",
      },
      {
        key: "delinquencyrate",
        label: " Delinquency Rate",
        prefix: "percent",
        min: 0,
        max: 10,
        step: 0.1,
        Tooltip:
          "Delinquency rate is the percentage of rental income loss due to tenants who fail to pay rent on time.",
      },
    ],
  },
  {
    title: "Expenses",
    items: [
      {
        key: "annualmaintainence",
        label: " Annual Maintenance",
        prefix: "amount",
        min: 0,
        max: 2000,
        step: 50,
        Tooltip:
          "Annual maintenance refers to the yearly cost of routine upkeep for the property, including repairs, landscaping, and general property care to ensure it remains in good condition.",
      },
      {
        key: "turnCost",
        label: " Per Sq.Ft Turn Cost",
        prefix: "amount",
        min: 0,
        max: 10,
        step: 0.1,
        Tooltip:
          "Per square foot turn cost is the expense associated with preparing a rental unit for a new tenant, including cleaning, repairs, and renovations, calculated based on the property's square footage.",
      },
      {
        key: "mgmtfeesrate",
        label: " Property Mgmt(% of rent)",
        prefix: "percent",
        min: 0,
        max: 15,
        step: 0.5,
        Tooltip:
          "Property management fees are a percentage of the monthly rent paid to the property manager for overseeing the rental property, handling tenant issues, and ensuring property upkeep.",
      },
      {
        key: "leasecommision",
        label: " Leasing Commission(% of rent)",
        prefix: "percent",
        min: 0,
        max: 200,
        step: 10,
        Tooltip:
          "Leasing commission is a percentage of the rent paid to a leasing agent or broker for finding and securing a tenant for the property.",
      },
      {
        key: "insurance",
        label: " Insurance Rate",
        prefix: "amount",
        min: 0,
        max: 100,
        step: 0.01,
        Tooltip:
          "Insurance rate refers to the cost of insuring the property, covering potential risks such as damage, liability, and loss of rental income.",
      },
    ],
  },
];

const AssumptionsModal = ({ setIsModalOpen }) => {
  const dispatch = useDispatch();
  const assumptions = useSelector(selectCashFlowAssumptions);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const theme = useTheme();

  const savebtnStyles = {
    borderRadius: "5px",
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "4px 10px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  };

  const handleChange = (key, newValue) => {
    dispatch(updateAssumption({ key, value: newValue }));
    setShowSaveButton(true);
  };

  const handleReset = () => {
    dispatch(resetAssumptions());
    setShowSaveButton(false);
  };

  const handleSave = () => {
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box
      sx={{
        ...classes.paper,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
      }}
    >
      <Card
        sx={{
          boxShadow: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.divider} `,
          py: 1,
          px: 2,
        }}
      >
        <SectionTitle sectionTitle={"Underwriting Assumptions"} />
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <TextButton onClick={handleReset}>
            <H3
              color={
                showSaveButton
                  ? theme.palette.text.secondary
                  : theme.palette.text.disabled
              }
              title={"Reset to Default"}
            />
          </TextButton>
          {showSaveButton && (
            <SolidRoundedButton onClick={handleSave} styles={savebtnStyles}>
              <NormalText
                sx={{ marginTop: 0, marginBottom: 0 }}
                text={"Save"}
              />
            </SolidRoundedButton>
          )}
          <CloseOutlined
            fontSize="medium"
            onClick={handleCloseModal}
            sx={{ cursor: "pointer", marginBottom: "8px" }}
          />
        </Box>
      </Card>

      <Grid container spacing={2}>
        {/* Reduced spacing */}
        {sections.map((section) => (
          <Grid item xs={12} sm={6} md={3} key={section.title}>
            <Box sx={classes.section}>
              <H3 title={section.title} color={theme.palette.text.primary} />
              <Grid container spacing={1}>
                <Grid item xs={12} sx={{ marginTop: "5px" }}>
                  {section.items.map((item) => (
                    <Tooltip
                      title={item.Tooltip ? item.Tooltip : ""}
                      placement="top" // Tooltip placement can be customized
                      arrow
                      key={item.key}
                    >
                      <div>
                        <SliderComponent
                          key={item.key}
                          label={item.label}
                          value={assumptions[item.key]}
                          setValue={(newValue) =>
                            handleChange(item.key, newValue)
                          }
                          setShowSaveButton={setShowSaveButton}
                          min={item.min}
                          max={item.max}
                          step={item.step}
                          prefix={item.prefix}
                        />
                      </div>
                    </Tooltip>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AssumptionsModal;
