import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  Menu,
  MenuItem,
  IconButton,
  useTheme,
  Box,
  Skeleton,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NormalText from "../../component/SinglePropertyPage/common/NormalText";
import LoaderIconButton from "../../component/button/LoaderIconButton";
import { useDispatch, useSelector } from "react-redux";
import {
  setBuyBoxes,
  setFilters,
  setSorts,
} from "../redux-slices/advancedSearchSlice";
import {
  updateCurrentViewState,
  updateUnSavedViewState,
  updateEditingExistingView,
  updateCurrentViewID,
  updatePaginationModelState,
  updateGroupBy,
  updateGroupBySubViewSelected,
} from "../redux-slices/viewsSlice";
import {
  updateAppliedColumns,
  updateLeads,
  updateLoading,
} from "../redux-slices/leadsSlice";
import { defaultVisibleColumns } from "../utils";
import GroupByDropdown from "./GroupByDropdown";

export default function ViewsList({
  space,
  searchTerm = "",
  currentSubGroupby,
  setCurrentSubView,
  useFetchViews,
  selectedViewData,
  isSingleViewLoading,
  isSingleViewFetching,
  isSingleViewError,
  handleDuplicateView,
  handleDeleteView,
  handleCopyToPrivate,
  isDuplicatingView,
  isDeletingView,
  isCopyingToPrivate,
  groupByAndCount,
  groupByAndCountLoading,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [currentView, setCurrentView] = useState({});
  const [anchorEls, setAnchorEls] = useState({});
  const unSavedViewState = useSelector((state) => state.view.unSavedViewState);
  const groubBySubViewSelected = useSelector(
    (state) => state.view.groupBySubViewSelected
  );

  const {
    data: viewsList,
    isLoading,
    isFetching,
    isSuccess,
  } = useFetchViews(space);

  // Load filters and sort into Redux when single view is fetched

  useEffect(() => {
    dispatch(updateLoading(isSingleViewLoading || isSingleViewFetching));
    if (selectedViewData) {
      const filters = selectedViewData.view.filters?.filters[0]?.filters || [];
      const buyBoxes =
        selectedViewData.view.filters?.filters[1]?.buyboxes || [];
      const sort = selectedViewData.view.filters?.sort || [];
      const columns =
        selectedViewData.view?.filters.columns || defaultVisibleColumns;
      const groupBy = selectedViewData?.view?.filters?.groupBy || null;

      dispatch(updateCurrentViewState(selectedViewData.view));
      dispatch(updateLeads(selectedViewData.data));
      dispatch(updateUnSavedViewState({}));
      dispatch(setFilters(filters));
      dispatch(setSorts(sort));
      dispatch(setBuyBoxes(buyBoxes));
      dispatch(updateAppliedColumns(columns));
      dispatch(updateGroupBy(groupBy));
    }
  }, [selectedViewData, dispatch, isSingleViewFetching, isSingleViewLoading]);

  // Select first view if the current one no longer exists
  useEffect(() => {
    if (isSuccess && viewsList?.length > 0) {
      const firstView = viewsList[0];
      setCurrentView(firstView || {});
      dispatch(updateCurrentViewID(firstView?.id || null));
    }
  }, [isSuccess, viewsList, dispatch]);

  const handleMenuOpen = (event, view) => {
    setAnchorEls((prev) => ({ ...prev, [view.id]: event.currentTarget }));
  };

  const handleMenuClose = (viewId) => {
    setAnchorEls((prev) => ({ ...prev, [viewId]: null }));
  };

  const handleDeleteViewEvent = async (event, view) => {
    event.stopPropagation();
    handleMenuClose(view.id);
    await handleDeleteView();
    setCurrentView({});
    dispatch(updateCurrentViewID(null));
  };

  const handleMenuAction = (action, view) => {
    switch (action) {
      case "edit":
        dispatch(updateEditingExistingView(true));
        dispatch(updateUnSavedViewState(selectedViewData.view));
        break;
      default:
        return null;
    }
  };

  const handleSelectCurrentView = async (view) => {
    if (groubBySubViewSelected) {
      handleCancel();
    }

    setCurrentView(view);
    dispatch(updateCurrentViewID(view.id));
    dispatch(updateLoading(isSingleViewFetching || isSingleViewLoading));
    dispatch(updateGroupBySubViewSelected(false));
    dispatch(
      updatePaginationModelState({
        page: 0,
        pageSize: 100,
      })
    );
    setCurrentSubView({});
  };
  const filteredViewsList = viewsList
    ? viewsList.filter((view) =>
        view.name.toLowerCase().startsWith(searchTerm.toLowerCase())
      )
    : [];

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      {/*  Overlay */}
      {Object.entries(unSavedViewState).length > 0 && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.grey[50],
            opacity: 0.7,
            zIndex: 10, // Ensure it appears above the content
          }}
        />
      )}

      {/* Main Content */}
      <List
        sx={{
          width: "95%",
          height: "100%",
          mx: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {isLoading || isFetching ? (
          Array.from(new Array(5)).map((_, index) => (
            <ViewListSkeleton key={index} />
          ))
        ) : filteredViewsList?.length > 0 ? (
          filteredViewsList.map((view) => (
            <Box key={view.id}>
              <ListItem
                onClick={
                  view.id !== currentView.id || groubBySubViewSelected
                    ? () => handleSelectCurrentView(view)
                    : () => {}
                }
                sx={{
                  width: "100%",
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: "5px",
                  py: 1,
                  px: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor:
                    currentView.id === view.id
                      ? theme.palette.primary.main
                      : "transparent",
                  color:
                    currentView.id === view.id
                      ? theme.palette.primary.contrastText
                      : theme.palette.text.secondary,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor:
                      currentView.id === view.id
                        ? theme.palette.primary.main
                        : theme.palette.action.hover,
                  },
                }}
              >
                <NormalText text={view.name} sx={{ flexGrow: 1, pr: 1 }} />

                {currentView.id === view.id &&
                  !(
                    isSingleViewLoading ||
                    isSingleViewFetching ||
                    isDuplicatingView ||
                    isDeletingView ||
                    isCopyingToPrivate
                  ) && (
                    <IconButton
                      onClick={(e) => handleMenuOpen(e, view)}
                      size="small"
                      sx={{
                        ml: 1,
                        color:
                          currentView.id === view.id
                            ? theme.palette.primary.contrastText
                            : theme.palette.text.secondary,
                      }}
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                  )}

                {currentView.id === view.id &&
                  (isSingleViewLoading ||
                    isSingleViewFetching ||
                    isDuplicatingView ||
                    isDeletingView ||
                    isCopyingToPrivate) && (
                    <LoaderIconButton
                      loading={
                        isSingleViewLoading ||
                        isSingleViewFetching ||
                        isDuplicatingView ||
                        isDeletingView ||
                        isCopyingToPrivate
                      }
                      error={isSingleViewError}
                      key={view.id}
                      color="white"
                    />
                  )}

                <Menu
                  anchorEl={anchorEls[view.id]}
                  open={Boolean(anchorEls[view.id])}
                  onClose={() => handleMenuClose(view.id)}
                >
                  <MenuItem
                    onClick={() => {
                      handleMenuClose(view.id);
                      handleMenuAction("edit", view);
                    }}
                  >
                    Edit
                  </MenuItem>
                  {handleCopyToPrivate && space === "team" && (
                    <MenuItem
                      onClick={() => {
                        handleMenuClose(view.id);
                        handleCopyToPrivate();
                      }}
                    >
                      Copy to Private
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      handleMenuClose(view.id);
                      handleDuplicateView();
                    }}
                  >
                    Duplicate
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => handleDeleteViewEvent(event, view)}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </ListItem>

              {currentView.id === view.id && (
                <GroupByDropdown
                  currentSubGroupby={currentSubGroupby}
                  setCurrentSubView={setCurrentSubView}
                  loading={groupByAndCountLoading}
                  data={groupByAndCount}
                  singleViewLoading={
                    isSingleViewFetching || isSingleViewLoading
                  }
                />
              )}
            </Box>
          ))
        ) : (
          <NormalText
            text={"No views found"}
            sx={{ mt: "40px", textAlign: "center" }}
          />
        )}
      </List>
    </Box>
  );
}

export function ViewListSkeleton() {
  const theme = useTheme();
  return (
    <ListItem
      sx={{
        width: "100%",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "5px",
        py: 1,
        px: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Skeleton variant="text" width="70%" height={20} sx={{ flexGrow: 1 }} />
      <Skeleton variant="circular" width={24} height={24} sx={{ ml: 1 }} />
    </ListItem>
  );
}
