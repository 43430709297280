import { Box, Skeleton } from "@mui/material";

export function CashFlowCalcSkel({}) {
  return (
    <>
      <Box>
        <Skeleton
          variant="rectangular"
          height={100}
          width="100%"
          sx={{ mt: 2 }}
        />
      </Box>
      <Box sx={{ borderRadius: "5px", mb: "5px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            height: "300px",
            marginTop: 2,
          }}
        >
          <Skeleton
            variant="rectangular"
            sx={{ mb: 2, flexGrow: 1, height: "100%" }}
          />
          <Skeleton
            variant="rectangular"
            sx={{ mb: 2, flexGrow: 1, height: "100%" }}
          />
        </Box>
      </Box>
    </>
  );
}
