import { Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import NormalText from "../../component/SinglePropertyPage/common/NormalText";
import { FetchingAllRevisionsSkeleton } from "../Skeleton/activityTrackerSkeleton";
import ActivityGroup from "../../component/UserTable/ActivityGroup";
import { useSelector } from "react-redux";
import {
  useGetDynamicDataQuery,
  useLazyGetDynamicDataQuery,
} from "../lib/activityTrackerApi";
import { selectActiveTable, selectTableById } from "../leadUnderwritingSlice";

import { DefaultOutLinedBtn } from "../../component/button/commonButtons";
import { throttle } from "lodash";
import { ActivityTrackerHeader } from "./ActivityHeader";
import { ActivityComment } from "./ActivityComment";
import EmailTracker from "../EmailTracker/EmailTracker";
import {
  useGetEmailsQuery,
  useLazyGetEmailsQuery,
} from "../lib/emailTrackerApi";

// TODO: Refactor Activity Tracker make it a resource specific component which will render any type of activity base on resource name

function ActivityTracker({ leadDetailsProp }) {
  const [selectedTab, setSelectedTab] = useState("activity");
  const limit = 101;
  const [lastTimeState, setLastTimeState] = useState(null);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const { id, property_id } = leadDetailsProp;
  const tableAlias = useSelector(selectActiveTable);
  const theme = useTheme();
  const recordId = useSelector(
    (state) => selectTableById(state, tableAlias)["workingEntityId"]
  );

  const {
    data: dynamicData,
    isLoading,
    isError,
    isFetching,
    error,
  } = useGetDynamicDataQuery(
    {
      resource: selectedTab,
      id: recordId || id,
      propertyId: property_id,
      limit: limit,
      table_alias: tableAlias,
    },
    {
      skip: selectedTab === "email",
    }
  );

  const {
    data: emailsData,
    isLoading: isEmailLoading,
    isFetching: isEmailfetching,
  } = useGetEmailsQuery(
    { id, limit: limit },
    {
      skip: selectedTab !== "email",
    }
  );

  const [dynamicActivity, { isFetching: dynamicDataFetching }] =
    useLazyGetDynamicDataQuery();
  const [fetchEmails] = useLazyGetEmailsQuery();

  const throttledAutoRefetch = throttle(
    () => {
      if (selectedTab !== "email") {
        dynamicActivity({
          id: recordId || id,
          limit: limit,
          propertyId: property_id,
          table_alias: tableAlias,
        }).unwrap();
      } else {
        fetchEmails({
          id,
          limit: limit,
        }).unwrap();
      }
    },
    10000,
    { leading: false, trailing: true }
  );

  // Throttled loadMore function (leading edge)
  const throttledLoadMore = throttle(
    (lastTime) => {
      if (selectedTab !== "email") {
        dynamicActivity({
          resource: selectedTab,
          id,
          propertyId: property_id,
          limit: limit,
          lastTime: lastTime,
          table_alias: tableAlias,
        });
      } else {
        fetchEmails({ id, limit: limit, lastTime: lastTime });
      }
    },
    10000,
    { leading: true, trailing: false } // get response immidiately within given timeout
  );

  useEffect(() => {
    const interval = setInterval(() => {
      throttledAutoRefetch();
    }, 300000);

    return () => clearInterval(interval);
  }, [throttledAutoRefetch]);

  const currActivityData = selectedTab === "email" ? emailsData : dynamicData;

  const data = [
    ...(currActivityData ? Object.values(currActivityData) : []),
  ].sort((a, b) => parseInt(b.event_time_on) - parseInt(a.event_time_on));

  const handleLoadMore = () => {
    try {
      const lastTime = data[data.length - 1]?.event_time_on;

      setLastTimeState(data[data.length - 1]?.event_time_on);

      if (lastTime === lastTimeState) {
        return;
      }

      throttledLoadMore(lastTime);
    } catch (error) {
      console.error("Failed to load more comments", error);
    }
  };

  const handleRefresh = async () => {
    if (selectedTab !== "email") {
      const res = await dynamicActivity({
        resource: selectedTab,
        id: recordId || id,
        propertyId: property_id,
        limit: limit,
        table_alias: tableAlias,
      }).unwrap();
    } else {
      const res = await fetchEmails({ id, limit: limit }).unwrap();
    }
  };

  const isDataPresent = data && data.length > 0;

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: theme.palette.background,
        borderLeft: `2px solid ${theme.palette.divider}`,
        pl: 0.2,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ActivityTrackerHeader
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        handleRefresh={handleRefresh}
        loading={isLoading || isFetching}
      />

      {data?.length >= 100 && (
        <DefaultOutLinedBtn
          sx={{
            mt: 0.5,
            textTransform: "capitalize",
            px: 1,
            borderRadius: "20px",
            backgroundColor: "white",
            position: "absolute",
            top: "40px",
            right: "15px",
          }}
          onClick={handleLoadMore}
        >
          Load More...
        </DefaultOutLinedBtn>
      )}
      <Box
        sx={{
          paddingX: 0.3,
          gap: "10px",
          display: "flex",
          flexDirection: "column-reverse",
          flexGrow: 1,
          maxHeight: "100vh",
          overflowY: "hidden",
          width: "100%",
          maxWidth: "100%",
          scrollbarWidth: "thin",
          scrollbarGutter: "stable",
          transition: "overflow 0.3s ease-in-out",
          "&:hover": {
            overflowY: "scroll",
          },
        }}
      >
        {isLoading || isEmailLoading ? (
          <FetchingAllRevisionsSkeleton count={20} />
        ) : isDataPresent ? (
          selectedTab !== "email" ? (
            data?.map((activity, index) => (
              <ActivityGroup
                isComment={selectedTab === "comment"}
                isActivity={selectedTab === "activity"}
                key={index}
                activityGroup={activity}
                id={recordId || id}
                tableAlias={tableAlias}
                limit={limit}
              />
            ))
          ) : (
            <EmailTracker emailData={data} />
          )
        ) : (
          <NormalText
            text={
              error ? "Error Occurred while showing data" : "Nothing to Show"
            }
            sx={{
              color: theme.palette.text.secondary,
              fontSize: "0.875rem",
              padding: 10,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              height: "100%",
              maxHeight: "100vh",
            }}
          />
        )}
      </Box>
      <ActivityComment
        leadDetailsProp={leadDetailsProp}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        tableAlias={tableAlias}
        id={recordId || id}
      />
    </Box>
  );
}

export default ActivityTracker;
