export function getAdvancedSearchPayload(
  filtersArray,
  sortsArray,
  buyboxes,
  columns,
  groupBy = null
) {
  const payload = {
    filters: [
      {
        filterBucket: "filter",
        filters: filtersArray,
      },
    ],
    sort: sortsArray.length === 0 ? null : sortsArray,
    columns: columns.length === 0 ? null : columns,
  };

  if (buyboxes && buyboxes.length > 0) {
    payload.filters.push({
      filterBucket: "buybox",
      combineWith: "OR",
      buyboxes: buyboxes,
    });
  }

  if (groupBy) {
    payload.groupBy = groupBy;
  }

  return payload;
}

export function detectUnsavedChanges(
  viewsFilter,
  viewsSort,
  viewsBuyBox,
  viewsCols,
  viewGroupBy,
  filtersState,
  sortsState,
  buyBoxState,
  colsState,
  groupByState
) {
  return (
    JSON.stringify(viewsFilter) === JSON.stringify(filtersState) &&
    JSON.stringify(viewsSort) === JSON.stringify(sortsState) &&
    JSON.stringify(viewsBuyBox) === JSON.stringify(buyBoxState) &&
    JSON.stringify(viewsCols) === JSON.stringify(colsState) &&
    JSON.stringify(viewGroupBy) === JSON.stringify(groupByState)
  );
}

export const defaultVisibleColumns = [
  "title",
  "address",
  "source",
  "market",
  "review_status",
  "property_type",
];
