import { Box, Skeleton, useTheme } from "@mui/material";
import { useState } from "react";
import CustomPopOverModal from "../CustomPopOverModal";
import NormalText from "../../component/SinglePropertyPage/common/NormalText";
import { useGetAllBuyBoxesQuery } from "../api-slices/multiPropertyViewApiSlice";
import {
  removeBuybox,
  selectAllBuyBoxes,
  upSertBuyBox,
} from "../redux-slices/advancedSearchSlice";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import MinimalIconButtonWithToolTip from "../../component/button/MinimalIconButtonWithTooltip";
import { DefaultOutLinedBtn } from "../../component/button/commonButtons";
import AdvancedSrchActionBtn from "../common/AdvanceSrchActionBtn";

export function BuyBoxLists({ applyAdvancedSearch }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const appliedBuyBoxList = useSelector(selectAllBuyBoxes);

  const theme = useTheme();

  const {
    data: buyBoxesList,
    isLoading,
    isFetching,
    isError,
    isSuccess: isGetAllBuyboxesSuccess,
  } = useGetAllBuyBoxesQuery();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectCurrentBuyBox = (buyBox) => {
    dispatch(upSertBuyBox(buyBox.id));
  };

  return (
    <>
      <Box>
        <DefaultOutLinedBtn
          variant="outlined"
          sx={{ textTransform: "none", position: "relative" }}
          onClick={handleClick}
        >
          Buybox
        </DefaultOutLinedBtn>

        <CustomPopOverModal
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPopover-paper": {
              width: "300px",
              height: "500px",
              marginTop: "10px",
              boxShadow: 0,
              overflowY: "hidden",
            },
          }}
        >
          <Box
            sx={{
              maxHeight: "500px",
              maxWidth: "300px",
              height: "calc(100% - 50px)",
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              backgroundColor: theme.palette.grey[200],
            }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.grey[100],
                borderRight: `1px solid ${theme.palette.divider}`,
                height: "100%",
                width: "100%",
                overflowY: "auto",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  background: theme.palette.primary.light,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: 1,
                }}
              >
                <NormalText
                  text={"Buybox"}
                  sx={{
                    color: theme.palette.primary.contrastText,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    p: 1,
                    my: 0,
                    cursor: "pointer",
                    fontSize: "0.875rem",
                  }}
                />
              </Box>
              <Box sx={{ mt: 1 }}>
                {isFetching || isLoading ? (
                  <BuyBoxSkeleton />
                ) : (
                  buyBoxesList?.map((buyBox) => {
                    const isSelected = appliedBuyBoxList.some(
                      (buybox) => buybox === buyBox.id
                    );

                    return (
                      <Box
                        key={buyBox.id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          px: "5px",
                        }}
                      >
                        <NormalText
                          text={buyBox.name}
                          sx={{
                            border: isSelected
                              ? `1px solid ${theme.palette.primary.contrastText}`
                              : `1px solid ${theme.palette.divider}`,
                            borderRadius: "5px",
                            p: 1,
                            my: 0,
                            width: "100%",
                            cursor: "pointer",
                            fontSize: "0.875rem",
                            color: isSelected
                              ? theme.palette.text.primary
                              : theme.palette.text,
                            backgroundColor: isSelected
                              ? theme.palette.grey[400]
                              : "transparent",
                          }}
                          onClick={() => {
                            !isSelected && handleSelectCurrentBuyBox(buyBox);
                          }}
                        />

                        {isSelected && (
                          <MinimalIconButtonWithToolTip
                            toolTipLabel={"Remove Buybox"}
                            style={{ mr: 1 }}
                            onClick={() => dispatch(removeBuybox(buyBox.id))}
                          >
                            <CloseIcon />
                          </MinimalIconButtonWithToolTip>
                        )}
                      </Box>
                    );
                  })
                )}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "50px",
              backgroundColor: theme.palette.grey[200],
              borderTop: `1px solid ${theme.palette.divider}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 1,
            }}
          >
            <AdvancedSrchActionBtn
              applyAdvancedSearchMutation={applyAdvancedSearch}
              sx={{
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                textTransform: "capitalize",
              }}
              key={"select buybox"}
            >
              Apply buybox
            </AdvancedSrchActionBtn>
          </Box>
        </CustomPopOverModal>
      </Box>
    </>
  );
}

const BuyBoxSkeleton = () => {
  return (
    <Box>
      <Skeleton
        variant="circular"
        width={20}
        height={20}
        sx={{ mx: "auto", mb: 2 }}
        animation="wave"
      />
      {Array.from({ length: 5 }).map((_, idx) => (
        <Skeleton
          key={idx}
          variant="rectangular"
          height={30}
          sx={{ mx: 1, my: 1 }}
          animation="wave"
        />
      ))}
    </Box>
  );
};
